import React from 'react';
import ProjectsListRow from './ProjectsListRow';
import { IProjectDTO } from 'interfaces/project';

interface IProjectsList {
  projects?: IProjectDTO[];
  refetch: () => void;
}

const ProjectsList: React.FC<IProjectsList> = ({ projects, refetch }) => (
  <>
    {projects?.map((project) => (
      <ProjectsListRow key={project.id} {...project} handleChange={refetch} />
    ))}
  </>
);

export default ProjectsList;
