import React from 'react';
import { Form } from 'react-final-form';
import { fieldRequiredRule } from 'validation/rules';
import Input from 'components/Input';
import useProjectsForm from '../../hooks/useProjectsForm';

type IRenameProjectModalProps = {
  id: number;
  name: string;
  onSuccess: () => void;
};

const RenameProjectModal: React.FC<IRenameProjectModalProps> = ({ id, name, onSuccess }) => {
  const { renameProject, isLoading } = useProjectsForm({ onUpdateSuccess: onSuccess });

  return (
    <Form
      onSubmit={renameProject}
      initialValues={{
        id,
        name
      }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Input name="name" label="" placeholder="Project name" validate={fieldRequiredRule} />
            <button
              className="w-full mt-36 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:opacity-50"
              type="submit"
              disabled={isLoading}
            >
              Save
            </button>
          </form>
        );
      }}
    />
  );
};

export default RenameProjectModal;
