import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

function useLogin() {
  const location = useLocation<{ [key: string]: unknown }>();
  const currentLocationState = location.state || {
    from: { pathname: '/' }
  };

  const { keycloak } = useKeycloak();
  const notAuthenticated: boolean = !keycloak?.authenticated;
  const isAuthenticated: boolean = !!keycloak?.authenticated && !!keycloak?.token;

  useEffect(() => {
    if (notAuthenticated) keycloak?.login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notAuthenticated]);

  return {
    isAuthenticated,
    currentLocationState
  };
}
export default useLogin;
