import React, { useMemo } from 'react';
import { FormattedNumber } from 'react-intl';
import { isNumber, isNull, isUndefined } from 'utils/checkers';

export type AmountFieldProps = {
  amount?: number | string | null;
  currencyName?: string;
};

const AmountField: React.FC<AmountFieldProps> = ({ amount, currencyName = 'EUR' }) => {
  const toShowAmount = useMemo(() => {
    if (isNumber(amount)) return amount;
    if (isNumber(Number(amount))) return Number(amount);

    return 0;
  }, [amount]);

  if (isNull(amount) || isUndefined(amount)) return <>-</>;

  return (
    // eslint-disable-next-line react/style-prop-object
    <FormattedNumber value={toShowAmount} style="currency" currency={currencyName} />
  );
};

export default AmountField;
