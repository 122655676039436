import React from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Select from 'components/Select';
import { fieldRequiredRule } from 'validation/rules';
import { IUserAppsResponse } from 'interfaces/user';

import usePermissionsForm from '../../hooks/usePermissionsForm';

interface IPermissionsFormProps {}

const OPTIONS = [
  {
    id: 'no_access',
    name: 'No access'
  },
  {
    id: 'view',
    name: 'View'
  },
  {
    id: 'submit_withdrawal',
    name: 'Withdrawal'
  }
];

const PermissionsForm: React.FC<IPermissionsFormProps> = () => {
  const { userPermissions, updatePermissionsLoading, changePermissions } = usePermissionsForm();

  const UsersRows = () => (
    <FieldArray name="apps">
      {({ fields }) =>
        fields?.map((name: string, index: number) => {
          const app = (userPermissions as IUserAppsResponse[])[index] as IUserAppsResponse;

          return (
            <div
              className="flex items-center justify-between w-full py-6"
              key={`user-row-${index}`}
            >
              <div className="text-16 text-gray-500 ">{app.name}</div>
              <div className="flex items-center w-192 justify-end">
                <Select
                  name={`${name}.accessLevel`}
                  label=""
                  options={OPTIONS}
                  validate={fieldRequiredRule}
                />
              </div>
            </div>
          );
        })
      }
    </FieldArray>
  );

  return (
    <div className="flex flex-col w-full ">
      <Form
        onSubmit={(values: { apps: IUserAppsResponse[] }) => changePermissions(values)}
        initialValues={{
          apps: userPermissions?.map((app) => ({
            ...app,
            accessLevel: !app.accessLevel ? OPTIONS[0].id : app.accessLevel
          }))
        }}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <UsersRows />
            <button
              className="w-full mt-128 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:opacity-50"
              type="submit"
              disabled={updatePermissionsLoading}
            >
              Save permissions
            </button>
          </form>
        )}
      />
    </div>
  );
};

export default PermissionsForm;
