import { useState, useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useInfiniteQuery, InfiniteData } from 'react-query';
import { useAuth } from 'hooks/useAuth';
import { getPaginatedApplications } from 'api/applications';
import { IApplicationDTO } from 'interfaces/application';
import { isValidArray } from 'utils/checkers';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';

const formatData = (result: InfiniteData<any> | undefined): IApplicationDTO[] => {
  if (!isValidArray(result?.pages)) return [];

  return result?.pages?.reduce((accumulator, value: any) => {
    return accumulator.concat(value?.results);
  }, []);
};

const infinitepageParam: number = 50;

function useHome(): {
  setSearch: DebouncedState<(val?: string | undefined) => void>;
  showInfiniteAppList: boolean;
  infiniteData?: IApplicationDTO[];
  showMoreButton: boolean;
  disableLoadMore: boolean;
  isLoading: boolean;
  fetchNextPage: () => void;
} {
  const { client, user } = useAuth();

  const [search, setSearch] = useState<string>('');

  const setSearchProperty = useDebouncedCallback((val?: string) => setSearch(val || ''), 500);

  const params = search ? { search } : {};

  const getInfiniteApplications = async ({
    pageParam = { limit: infinitepageParam, offset: 0 }
  }) => {
    const response = await getPaginatedApplications({ ...pageParam, ...params });
    return response;
  };

  const enabled: boolean = Boolean(user?.email && client?.id);

  const {
    data: infiniteDataResults,
    isLoading: infiniteLoading,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery(['getInfiniteApplications', search], getInfiniteApplications, {
    getNextPageParam: (lastPage: any, allPages: any) => {
      const allCount = lastPage?.count || 0;
      const loadedPages = allPages?.length || 0;
      const callLoadMore = allCount > loadedPages * infinitepageParam;
      if (!callLoadMore) return false;

      const params = { limit: infinitepageParam, offset: loadedPages * infinitepageParam };
      return params;
    },
    enabled: enabled
  });

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const infiniteData = useMemo(() => formatData(infiniteDataResults), [infiniteDataResults]);
  const showInfiniteAppList: boolean = isValidArray(infiniteData);
  const isLoading: boolean = infiniteLoading;

  const showMoreButton: boolean = Boolean(showInfiniteAppList && hasNextPage);

  const disableLoadMore: boolean = isFetchingNextPage || isFetching;

  return {
    setSearch: setSearchProperty,
    showInfiniteAppList,
    infiniteData,
    showMoreButton,
    disableLoadMore,
    isLoading,
    fetchNextPage: loadMore
  };
}

export default useHome;
