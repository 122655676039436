import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Skeleton from 'react-loading-skeleton';
import { isValidArray } from 'utils/checkers';
import Select from 'components/Select';
import FileInput from 'components/FileInput';
import AmountField from 'components/AmountField';
import CurrencyField from 'components/CurrencyField';
import ErrorLabel from 'components/ErrorLabel';
import TextArea from 'components/TextArea';
import { fieldRequiredRule } from 'validation/rules';
import { IWithdrawalChildDTO } from 'interfaces/withdrawal';
import useRequestWithdrawal from '../../hooks/useRequestWithdrawal';

const RequestWithdrawalForm = () => {
  const {
    isLoading,
    paymentMethods,
    requestWithdrawal,
    withdrawalAccess,
    isOwner,
    publishers,
    getAppsByPublisher
  } = useRequestWithdrawal();

  const hasAccess: boolean = withdrawalAccess || isOwner;

  if (isLoading) return <Skeleton count={5} />;

  if (!isLoading && !hasAccess)
    return (
      <Redirect
        to={{
          pathname: '/withdrawals'
        }}
      />
    );

  const PublisherSelector = () => {
    const { values } = useFormState();
    const { change } = useForm();

    useEffect(() => {
      const getApps = async () => {
        const apps = await getAppsByPublisher(values?.publisher);
        change('children', !!apps?.length ? apps : []);
      };

      if (!!values?.publisher) {
        getApps();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.publisher]);

    return (
      <div className="-mt-16 mb-32 w-320">
        <Select name="publisher" label="Select the publisher" options={publishers} />
      </div>
    );
  };

  const AppsListRows = () => {
    const { values } = useFormState();

    return (
      <div className="flex flex-col">
        <FieldArray name="children">
          {({ fields }) =>
            fields?.map((name: string, index: number) => {
              const app = (values?.children as any[])[index];
              const validateAmount = (amount: string) => {
                if (app?.balance > 0) {
                  if (!amount) return 'Field is required';
                  if (parseFloat(amount as string) > app.balance)
                    return 'Amount could be less than app balance';
                } else {
                  if (parseFloat(amount as string) > 0) return 'Amount could be 0 for this app';
                }
              };

              return (
                <div
                  className="flex items-center justify-between w-full px-16 py-24 border-b border-gray-200"
                  key={`app-row-${index}`}
                >
                  <div className="text-16 text-gray-500 ">{app?.name}</div>
                  <div className="flex items-center w-320 justify-end">
                    <AmountField amount={app?.balance as number} currencyName={app?.currencyName} />
                    <div className="w-1/2 ml-16">
                      <CurrencyField
                        name={`${name}.amount`}
                        label=""
                        placeholder="€"
                        prefix="€"
                        validate={validateAmount}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          }
        </FieldArray>
      </div>
    );
  };

  const TotalBlock = (totalRequestAmount: number, totalAppBalance: number) => {
    return (
      <div className="flex items-center justify-between w-full mb-64 px-16 py-24 border-b border-gray-200 font-bold text-gray-500 text-16">
        <div>Total</div>
        <div className="flex items-center w-320 justify-end">
          <AmountField amount={totalAppBalance} currencyName="EUR" />
          <div className="w-1/2 ml-16">
            <AmountField amount={totalRequestAmount} currencyName="EUR" />
          </div>
        </div>
      </div>
    );
  };

  const RequestWithdrawalFormComponent = () => (
    <>
      <Form
        onSubmit={requestWithdrawal}
        mutators={{
          ...arrayMutators
        }}
        initialValues={{ publisher: '', children: [], paymentMethod: '' }}
        initialValuesEqual={() => true}
        render={({ handleSubmit, submitting, values, submitError }) => {
          const totalRequestAmount = isValidArray(values?.children)
            ? values.children.reduce(
                (a: number, b: IWithdrawalChildDTO) => a + (parseFloat(b?.amount as string) || 0),
                0
              )
            : 0;
          const totalAppBalance = isValidArray(values?.children)
            ? values.children.reduce(
                (a: number, b: IWithdrawalChildDTO) => a + ((b?.balance as number) || 0),
                0
              )
            : 0;

          return (
            <form onSubmit={handleSubmit}>
              <PublisherSelector />
              {values?.publisher && (
                <>
                  {!!values?.children?.length && <AppsListRows />}
                  {!!values?.children?.length && (
                    <>{TotalBlock(totalRequestAmount, totalAppBalance)}</>
                  )}
                  {paymentMethods && (
                    <div className="w-full mb-16 ">
                      <Select
                        name="paymentMethod"
                        label="Select the payment method"
                        options={paymentMethods}
                        validate={fieldRequiredRule}
                      />
                    </div>
                  )}
                  <div className="w-full mb-16">
                    <FileInput
                      accept="application/pdf, image/*"
                      name="file"
                      label="Invoice"
                      validate={fieldRequiredRule}
                    />
                  </div>
                  <div className="w-full mb-16">
                    <TextArea name="clientComment" label="Comment" />
                  </div>
                  <button
                    className="w-full mt-36 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:cursor-default disabled:opacity-50"
                    type="submit"
                    disabled={submitting || totalRequestAmount === 0}
                  >
                    Request
                  </button>
                  {submitError && <ErrorLabel error={submitError as any} />}
                </>
              )}
            </form>
          );
        }}
      />
    </>
  );

  return <>{!isLoading && <RequestWithdrawalFormComponent />}</>;
};

export default RequestWithdrawalForm;
