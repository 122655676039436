import constate from 'constate';

const str = (1500.99).toLocaleString().replace(/[0-9]/g, '');

const [LocaleProvider, useLocale] = constate(() => {
  const decimaSeparator = str?.length > 1 ? str[1] : str[0];
  const groupSeparator = str?.length > 1 ? str[0] : ',';

  return { decimaSeparator, groupSeparator };
});

export { LocaleProvider, useLocale };
