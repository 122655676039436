export const DEFAULT_LOCALE_OPTIONS = {
  weekStart: 1,

  monthsShort: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
    '_'
  ),
  months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
    '_'
  ),
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
  weekdaysShort: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_')
};
