import { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { DEFAULT_LOCALE_OPTIONS } from 'components/DatePicker/locale';
import { formatDate, convertTimeToNull, FORMATS } from 'utils/formatters';

type PickerQuery = { start: string | null; end: string | null; period: string | null };

function useHistoryLogSearch() {
  const [search, setSearch] = useState<string | undefined>('');
  const [selectedType, setType] = useState<null | string>(null);

  const [selectedPeriod, setPeriod] = useState<null | string>(null);
  const [picker, showPicker] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [pickerQuery, setPickerQuery] = useState<PickerQuery | null>(null);

  const onChangePicker = (dates: Date | [Date, Date] | null) => {
    setPeriod(null);

    const [start, end] = dates as [Date, Date];

    setStartDate(start);
    setEndDate(end);
  };

  const typeOptions = ['Withdrawals', 'Projects', 'Apps'];

  const periodOptions = ['Today', 'Yesterday', 'This week', 'This month'];

  const filterTypes = selectedType
    ? typeOptions.filter((item) => item !== selectedType)
    : typeOptions;

  const clearDateFilter = () => {
    setPeriod(null);
    setStartDate(null);
    setEndDate(null);
    setPickerQuery(null);
  };

  const handlePeriodFilter = (option: string) => {
    let start: Date | undefined = undefined;
    let end: Date | undefined = undefined;

    if (option === 'Today') {
      const today = dayjs().toDate();
      start = today;
      end = today;
    }
    if (option === 'Yesterday') {
      const yesterday = dayjs().subtract(1, 'day').toDate();
      start = yesterday;
      end = yesterday;
    }
    if (option === 'This week') {
      start = dayjs().locale('en', DEFAULT_LOCALE_OPTIONS).startOf('week').toDate();
      end = dayjs().locale('en', DEFAULT_LOCALE_OPTIONS).endOf('week').toDate();
    }
    if (option === 'This month') {
      start = dayjs().startOf('month').toDate();
      end = dayjs().endOf('month').toDate();
    }

    setPeriod(option);

    if (!!start && !!end) {
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handlePickerDoneButton = useCallback(() => {
    showPicker(false);

    if (startDate && endDate)
      setPickerQuery({
        start: formatDate(convertTimeToNull(startDate), FORMATS.queryWithTime, {
          returnNull: true,
          toUTC: true
        }),
        end: formatDate(
          !!endDate ? convertTimeToNull(dayjs(endDate).add(1, 'day').toDate()) : null,
          FORMATS.queryWithTime,
          {
            returnNull: true,
            toUTC: true
          }
        ),
        period: selectedPeriod
      });
  }, [startDate, endDate, selectedPeriod]);

  const getModelToQuery = useCallback(() => {
    if (!selectedType) return null;

    const modelType = selectedType?.toLowerCase();

    if (modelType === 'withdrawals') return 'withdrawal';
    if (modelType === 'apps') return 'application';
    if (modelType === 'projects') return 'project';

    return modelType;
  }, [selectedType]);

  const showPickerLabels: boolean = Boolean(pickerQuery?.start && pickerQuery?.end);
  const showLabels: boolean = Boolean(selectedType || showPickerLabels);

  return {
    selectedType,
    setType,
    selectedPeriod,
    setPeriod,
    picker,
    showPicker,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onChangePicker,
    periodOptions,
    filterTypes,
    clearDateFilter,
    handlePeriodFilter,
    handlePickerDoneButton,
    filterQuery: {
      model: getModelToQuery(),
      search,
      start: pickerQuery?.start || null,
      end: pickerQuery?.end || null,
      period: pickerQuery?.period
    },
    setSearch,
    showLabels,
    showPickerLabels
  };
}

export default useHistoryLogSearch;
