import React, { useState, useRef, useEffect, useMemo } from 'react';
import { default as CurrencyField } from 'react-currency-input-field';
import cn from 'classnames';
import { Field } from 'react-final-form';
import { useLocale } from 'hooks/useLocale';
import { ReactComponent as Check } from 'assets/redesign/check.svg';

import { isString } from 'utils/checkers';

import { formatCurrencyString } from 'components/CurrencyField/format';

export type CurrencyInputProps = {
  name: string;
  label: string;
  validate?: any;
  placeholder?: string;
  initialValue?: string | number;
  prefix?: string;
  maxValue?: number | string;
  disabled?: boolean;
  autofocus?: boolean;
};

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input:
    'text-black text-50 block w-full h-52 rounded px-20 focus:outline-none disabled:bg-transparent disabled:cursor-default',
  inputError: 'border-red'
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  name,
  label,
  initialValue = '',
  validate,
  placeholder = '',
  prefix,
  maxValue,
  disabled = false,
  autofocus = false,
  ...props
}) => {
  const { decimaSeparator, groupSeparator } = useLocale();

  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : '');

  const ref = useRef();

  const [val, setVal] = useState<string | number>(initialValue);
  const [focused, setFocused] = useState<boolean>(false);

  const currencyPrefix = useMemo(() => {
    if (prefix === 'USD') return '$';
    if (prefix === 'EUR' || !prefix) return '€';
  }, [prefix]);

  const handleChange = (value?: string, onChange?: any) => {
    if (isString(value)) {
      value = value.replace(',', '.');
    }
    if (maxValue && value && parseFloat(value) > parseFloat(maxValue as any)) return;
    setVal(value as string);
    onChange(value);
  };

  const handlePaste = (event: ClipboardEvent, onChange?: any) => {
    const { clipboardData } = event;
    const pastedText = clipboardData?.getData('text');
    const newValue = formatCurrencyString(pastedText, decimaSeparator) as string;
    if (val !== newValue) handleChange(newValue, onChange);
  };

  useEffect(() => {
    if (autofocus && !!ref?.current) (ref?.current as any)?.focus();
  }, [autofocus]);

  return (
    <Field
      {...props}
      name={name}
      validate={validate}
      initialValue={initialValue}
      format={(value) => (!!value ? String(value).replace('.', decimaSeparator) : undefined)}
      parse={(value) => (!!value ? String(value)?.replace(decimaSeparator, '.') : '')}
    >
      {({ input, meta }) => {
        const isError = meta.error && (meta.dirty || meta.touched);
        return (
          <div
            className={cn('flex items-center w-full', {
              'justify-between': (meta.dirty || meta.dirtySinceLastSubmit) && focused
            })}
          >
            <span className="text-black text-50 block w-28 h-52">{currencyPrefix}</span>
            <CurrencyField
              ref={ref as any}
              disabled={disabled}
              placeholder={placeholder}
              // prefix={prefix}
              maxLength={11}
              decimalsLimit={2}
              decimalSeparator={decimaSeparator}
              groupSeparator={groupSeparator}
              value={input.value}
              className={inputClasses(isError)}
              onValueChange={(value) => handleChange(value, input.onChange)}
              onPaste={(e) => handlePaste(e as any, input.onChange)}
              onFocus={() => {
                input?.onFocus();
                setFocused(true);
              }}
              onBlur={() => {
                input?.onBlur();
                setFocused(false);
              }}
            />
            <span
              onClick={() => {
                input?.onBlur();
                setFocused(false);
              }}
              className={cn('cursor-pointer transition-all', {
                'opacity-0': !meta.valid || !focused,
                'opacity-100': meta.valid && focused
              })}
            >
              <Check />
            </span>
          </div>
        );
      }}
    </Field>
  );
};

export default CurrencyInput;
