import { useMutation } from 'react-query';
import { useAuth } from 'hooks/useAuth';
import { createProject, deleteProject, updateProject } from 'api/projects';

type IUseProjectsForm = {
  onAddSuccess?: () => void;
  onDeleteSuccess?: () => void;
  onUpdateSuccess?: () => void;
};

function useProjectsForm({ onAddSuccess, onDeleteSuccess, onUpdateSuccess }: IUseProjectsForm) {
  const { client } = useAuth();

  const { mutate: addProject, isLoading: addProjectLoading } = useMutation(
    (name: string) => createProject({ name, client: client?.id as number }),
    {
      onSuccess: onAddSuccess
    }
  );

  const addProjectCallback = ({ name }: { name: string }) => addProject(name);

  const { mutate: deleteProjectMutation, isLoading: deleteProjectLoading } = useMutation(
    (id: number) => deleteProject(id),
    {
      onSuccess: onDeleteSuccess
    }
  );

  const { mutate: renameProject, isLoading: renameProjectLoading } = useMutation(
    ({ id, name }: { id: number; name: string }) => updateProject(id, { name }),
    {
      onSuccess: onUpdateSuccess
    }
  );

  const renameProjectCallback = ({ id, name }: { id: number; name: string }) =>
    renameProject({ id, name });

  const isLoading = addProjectLoading || renameProjectLoading || deleteProjectLoading;

  return {
    deleteProjectMutation,
    renameProject: renameProjectCallback,
    addProject: addProjectCallback,
    isLoading
  };
}

export default useProjectsForm;
