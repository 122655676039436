import { useMemo, useCallback } from 'react';
import { useQuery, useInfiniteQuery, InfiniteData } from 'react-query';
import { isValidArray } from 'utils/checkers';
import { getPaginatedWithdrawals } from 'api/withdrawals';
import { getClientBalance } from 'api/client';
import { IWithdrawalDTO } from 'interfaces/withdrawal';

const formatData = (result: InfiniteData<any> | undefined): IWithdrawalDTO[] => {
  if (!isValidArray(result?.pages)) return [];

  return result?.pages?.reduce((accumulator, value: any) => {
    return accumulator.concat(value?.results);
  }, []);
};

function useWithdrawalPage() {
  const infinitepageParam: number = 5;

  const { isLoading: balanceLoading, data: balance } = useQuery('getBalance', getClientBalance);

  const getInfiniteWithdrawals = async ({
    pageParam = { limit: infinitepageParam, offset: 0 }
  }) => {
    const response = await getPaginatedWithdrawals(pageParam);
    return response;
  };

  const {
    data: infiniteDataResults,
    isLoading: infiniteLoading,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery('getInfiniteWithdrawals', getInfiniteWithdrawals, {
    getNextPageParam: (lastPage: any, allPages: any) => {
      const allCount = lastPage?.count || 0;
      const loadedPages = allPages?.length || 0;
      const calLoadMore = allCount > loadedPages * infinitepageParam;
      if (!calLoadMore) return false;

      const params = { limit: infinitepageParam, offset: loadedPages * infinitepageParam };
      return params;
    }
  });

  const infiniteData = useMemo(() => formatData(infiniteDataResults), [infiniteDataResults]);

  const showInfiniteWithdrawals: boolean = isValidArray(infiniteData);

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const showMoreButton: boolean = Boolean(showInfiniteWithdrawals && hasNextPage);

  const disableLoadMore: boolean = isFetchingNextPage || isFetching;

  const isLoading = infiniteLoading || balanceLoading;

  return {
    balance,
    infiniteData,
    showInfiniteWithdrawals,
    showMoreButton,
    disableLoadMore,
    isLoading,
    fetchNextPage: loadMore
  };
}

export default useWithdrawalPage;
