import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

interface ISearch {
  onChange: (event: string) => void;
}

const Search: React.FC<ISearch> = ({ onChange }) => {
  return (
    <div className="flex items-center w-full mb-40 px-24 py-20 text-24 rounded-12 bg-gray-100">
      <SearchIcon />
      <input
        className="w-full ml-20 bg-transparent focus:outline-none"
        type="text"
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default Search;
