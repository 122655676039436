import React from 'react';
import AmountField from 'components/AmountField';
import { formatDate, FORMATS } from 'utils/formatters';
import ModalInnerWrapper from './components/ModalInnerWrapper';
import ModalItemList from './components/ModalItemList';

export interface IServiceFeeModalProps {
  amount: number;
  datetime: string;
  feePercent: number;
  currency: string;
  type?: string;
}

export const ServiceFeeModal: React.FC<IServiceFeeModalProps> = ({
  amount,
  datetime,
  feePercent,
  currency,
  type
}) => {
  const serviceFeeFields = [
    {
      name: 'Date',
      value: formatDate(datetime, FORMATS.global)
    },
    {
      name: 'Service Fee (%)',
      value: feePercent || 0
    }
  ];

  return (
    <ModalInnerWrapper
      AmountField={<AmountField amount={amount} currencyName={currency} />}
      type={type}
    >
      {serviceFeeFields.map(({ name, value }, index) => (
        <ModalItemList key={`service-fee-${index}`} name={name} value={value} index={index} />
      ))}
    </ModalInnerWrapper>
  );
};
