import React from 'react';

const SubmitButton = ({ ...props }) => (
  <button
    className="w-full mt-36 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:opacity-50 disabled:cursor-default"
    type="submit"
    {...props}
  >
    Submit
  </button>
);

export default SubmitButton;
