import React from 'react';
import { Link } from 'react-router-dom';
import AmountField from 'components/AmountField';
import { appStatuses } from 'const/appStatuses';
import { isValidArray, isNull } from 'utils/checkers';
import { pathWithState } from 'utils/router';
import { ILogDTO, IChangedField, ILogApplication } from 'interfaces/logs';
import { formatActionWord } from '../../utils/format';
import {
  filterChangedFields,
  filterFieldsByModel,
  isFieldEmpty,
  getFieldByKey
} from '../../utils/filter';
import { models } from '../../const/models';

interface IActionPhraseProps {
  row: ILogDTO;
}

const ActionPhrase: React.FC<IActionPhraseProps> = ({ row }) => {
  let changedFields;
  changedFields = filterFieldsByModel(row.changes, row.model);
  changedFields = filterChangedFields(changedFields);

  const actionWord = formatActionWord(row?.action?.toLowerCase());

  const CommonPhraseComponent = () => (
    <>
      {actionWord} {row?.model?.toLowerCase()}
    </>
  );

  if (!isValidArray(changedFields)) return <CommonPhraseComponent />;

  const isWithdrawal = row.model === models.withdrawal;
  const isProject = row.model === models.project;
  const isApplication = row.model === models.application;
  const appStatusField: IChangedField | undefined = getFieldByKey(row.changes, 'status');
  const appSubmittedStatusField: IChangedField | undefined = getFieldByKey(
    row.changes,
    'submitted_status'
  );

  // FIXME: Fix logic for app statuses
  const appStatus: string =
    appSubmittedStatusField?.after?.split(' ')[0] ||
    appStatusField?.after ||
    row?.application?.status;

  const isApplicationOnRequest: boolean = isApplication && appStatus !== appStatuses.approved;

  return (
    <div className="flex flex-col">
      {changedFields?.map((field: IChangedField, i: number) => {
        const isApplicationOnRegister: boolean =
          isApplication &&
          field.field === 'request_submitted' &&
          field.after === 'True' &&
          isApplicationOnRequest;

        const showCommonMessage: boolean =
          isApplication &&
          !isApplicationOnRegister &&
          field.field !== 'project' &&
          field.field !== 'rc';

        if (showCommonMessage) return <CommonPhraseComponent key={`auditlog-field-${i}`} />;

        return (
          <div className="mb-8" key={`auditlog-field-${i}`}>
            {isWithdrawal && (
              <>
                {!isNull(row.application) ? (
                  <>
                    Created Withdrawal request in{' '}
                    <Link
                      to={pathWithState(
                        `/applications/${(row.application as ILogApplication).id}`,
                        '/history'
                      )}
                      className="text-violet"
                    >
                      @{(row.application as ILogApplication).name as string}
                    </Link>
                  </>
                ) : (
                  <>Created Common withdrawal request</>
                )}{' '}
                for <AmountField amount={field.after} />
              </>
            )}
            {isProject && (
              <>
                {isFieldEmpty(field.after) && <>Deleted project {field.before}</>}
                {isFieldEmpty(field.before) && !isFieldEmpty(field.after) && (
                  <>Created project {field.after}</>
                )}
                {!isFieldEmpty(field.before) && !isFieldEmpty(field.after) && (
                  <>Edited project {field.after}</>
                )}
              </>
            )}
            {isApplication && isApplicationOnRegister && !isNull(row.application) && (
              <>
                {appStatus === appStatuses.appSet && <>Created registration request</>}
                {appStatus === appStatuses.bankDetailsSet && <>Set Store contract</>}
                {appStatus === appStatuses.contractSet && <>Set Signed contract</>} for{' '}
                <Link
                  to={pathWithState(`/add/${(row.application as ILogApplication).id}`, '/history')}
                  className="text-violet"
                >
                  @{(row.application as ILogApplication).name as string}
                </Link>
              </>
            )}
            {isApplication && !isApplicationOnRegister && (
              <>
                {isFieldEmpty(field.before) && !isFieldEmpty(field.after) && (
                  <>
                    Set {field.field === 'project' ? 'project' : 'RC'}{' '}
                    <>
                      {!isNull(row.application) && (
                        <>
                          in{' '}
                          <Link
                            to={pathWithState(
                              `/applications/${(row.application as ILogApplication).id}`,
                              '/history'
                            )}
                            className="text-violet"
                          >
                            @{(row.application as ILogApplication).name as string}
                          </Link>
                        </>
                      )}
                    </>{' '}
                    → {field.after}
                  </>
                )}
                {!isFieldEmpty(field.before) && (
                  <>
                    Edited {field.field === 'project' ? 'project' : 'RC'}{' '}
                    <>
                      {!isNull(row.application) && (
                        <>
                          in{' '}
                          <Link
                            to={pathWithState(
                              `/applications/${(row.application as ILogApplication).id}`,
                              '/history'
                            )}
                            className="text-violet"
                          >
                            @{(row.application as ILogApplication).name as string}
                          </Link>
                        </>
                      )}
                    </>{' '}
                    {field.before} → {isFieldEmpty(field.after) ? 'Empty' : field.after}
                  </>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ActionPhrase;
