import React from 'react';
import cn from 'classnames';
import DefaultPlaceholder from './DefaultPlaceholder';
import { ReactComponent as Attach } from 'assets/redesign/attach.svg';

type NoFilesBlockProps = {
  placeholder?: string | JSX.Element;
  disabled?: boolean;
};

const NofilesBlock: React.FC<NoFilesBlockProps> = ({ placeholder, disabled }) => (
  <div className="flex items-center justify-between w-full my-4 py-8">
    {placeholder && <p>{placeholder}</p>}
    {!placeholder && <DefaultPlaceholder />}
    <span className={cn('block ml-20', { 'cursor-pointer': !disabled })}>
      <Attach />
    </span>
  </div>
);

export default NofilesBlock;
