import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { IUserResponse, IUserAppsResponse } from 'interfaces/user';
import { useAuth } from 'hooks/useAuth';
import { isValidArray } from 'utils/checkers';
import { getUserPermissions, updateUserPermissions } from 'api/getUser';

function usePermissionsForm() {
  const history = useHistory();
  const { id: userId } = useParams() as { id: string };
  const { users, isOwner, authLoading } = useAuth();

  const user = users?.find((usr: IUserResponse) => usr?.id === userId);

  const { data: userPermissions, isLoading: usePermissionsLoading } = useQuery(
    'getUserPermissions',
    () => getUserPermissions(userId),
    {
      enabled: !!userId && isOwner
    }
  );

  const { mutate: changePermissions, isLoading: updatePermissionsLoading } = useMutation(
    ({ apps }: { apps: IUserAppsResponse[] }) => updateUserPermissions(userId, apps),
    {
      onSuccess: () => history.push('/permissions')
    }
  );

  const showPermissions = isValidArray(userPermissions);

  const isLoading: boolean = authLoading || usePermissionsLoading;

  return {
    userId,
    showPermissions,
    userPermissions,
    user,
    isOwner,
    isLoading,
    changePermissions,
    authLoading,
    updatePermissionsLoading
  };
}

export default usePermissionsForm;
