import { IWithdrawalDTO } from 'interfaces/withdrawal';
import axios from 'utils/axios';

export const getPaginatedWithdrawals = async (params: any) => {
  const response = await axios.get('/api/withdrawals/', { params });
  return response?.data;
};

export const getWithdrawal = async (id: number | string) => {
  const response = await axios.get(`/api/withdrawals/${id}`);
  return response.data as IWithdrawalDTO;
};

export const updateWithdrawal = async (id: number | string, data: any) => {
  const response = await axios.patch(`/api/withdrawals/${id}/`, { ...data });
  return response.data as IWithdrawalDTO;
};

export const createWithdrawal = async (data: any) => {
  const response = await axios.post('/api/withdrawals/', data);
  return response.data as IWithdrawalDTO;
};
