import React from 'react';
import Heading from 'components/Heading';

const ReportHeading: React.FC = () => {
  return (
    <Heading level={1} className="my-64">
      Client report
    </Heading>
  );
};

export default ReportHeading;
