import React from 'react';
import 'react-base-table/styles.css';
import BaseTable, { AutoResizer } from 'react-base-table';
import useTableReport from './hooks';
import { IReportDTO } from '../../interfaces/report';

type IReportTableProps = {
  data: IReportDTO[];
};

const ReportTable: React.FC<IReportTableProps> = ({ data }) => {
  const { headerHeight, minTableHeight, tableData, columns } = useTableReport(data);

  return (
    <div className="w-full px-20 mx-auto" style={{ minHeight: minTableHeight }}>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            fixed
            data={tableData}
            columns={columns}
            width={width}
            height={height}
            headerHeight={headerHeight}
          />
        )}
      </AutoResizer>
    </div>
  );
};

export default ReportTable;
