import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import { useQuery } from 'react-query';
import { createWithdrawal } from 'api/withdrawals';
import { useFileUpload } from 'hooks/useFileUpload';
import useAccessLevel from 'hooks/useAccessLevel';
import { IApplicationDTO } from 'interfaces/application';
import { getPaymentMethods } from 'api/selectors';
import { IWithdrawalChildDTO, IWithdrawalRequestDTO } from 'interfaces/withdrawal';
import { IPaymentMethodsResponseDTO } from 'interfaces/paymentMethods';
import { getApplications } from 'api/applications';
import { getPublishers } from 'api/selectors';
import { getClientBalance } from 'api/client';

function useRequestWithdrawal() {
  const { uploadFile } = useFileUpload();
  const history = useHistory();

  const requestWithdrawal = useCallback(async (data: IWithdrawalRequestDTO) => {
    try {
      const total = await getClientBalance();
      const totalRequest = data.children.reduce(
        (a: number, b: IWithdrawalChildDTO) => a + (parseFloat(b?.amount as string) || 0),
        0
      );

      if (total < totalRequest) {
        return { [FORM_ERROR]: 'Request amount could be less than your total balance' };
      }
      const fileUUID = await uploadFile(data.file);

      data.file = fileUUID;

      data.children = data.children
        .map((item: IWithdrawalChildDTO) => ({
          ...item,
          amount: item?.amount ? parseFloat(item.amount as string) : 0
        }))
        .filter(({ amount }) => amount > 0);

      const response = await createWithdrawal(data);

      if (response) {
        history.push('/withdrawals');
      }
    } catch (e) {
      return { [FORM_ERROR]: 'Request error' };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: paymentMethods,
    isLoading: paymentMethodsLoading
  } = useQuery<IPaymentMethodsResponseDTO>(['getPaymentMethods'], getPaymentMethods);

  const { data: publishers, isLoading: publishersLoading } = useQuery(
    ['getPublishers'],
    getPublishers
  );

  const { withdrawalAccess, isOwner } = useAccessLevel();

  const filterAppsByAccess = (apps?: IApplicationDTO[]) =>
    apps?.filter((app) => app.accessLevel === 'submit_withdrawal');

  const mapAppsToValues = (apps?: IApplicationDTO[]) =>
    apps?.map((app: IApplicationDTO) => ({
      applicationId: app.id,
      name: app.name,
      amount: '0.00',
      balance: app.balance,
      currencyName: app.currency
    }));

  const getAppsByPublisher = async (publisher: number) => {
    if (isOwner || withdrawalAccess) {
      let apps = await getApplications({ publisher });
      if (withdrawalAccess) apps = filterAppsByAccess(apps) as IApplicationDTO[];
      apps = mapAppsToValues(apps) as any;
      return apps;
    }
    return [];
  };

  return {
    getAppsByPublisher,
    requestWithdrawal,
    isLoading: paymentMethodsLoading || publishersLoading,
    publishers,
    paymentMethods,
    withdrawalAccess,
    isOwner
  };
}

export default useRequestWithdrawal;
