import React from 'react';
import Heading from 'components/Heading';

interface ICustomTitleProps {
  title: string;
}

const CustomTitle: React.FC<ICustomTitleProps> = ({ title }) => (
  <Heading level={3} className="mb-20 text-left" color="text-gray-500">
    {title}
  </Heading>
);

export default CustomTitle;
