import { useState, useMemo, useCallback } from 'react';
import { useInfiniteQuery, InfiniteData } from 'react-query';
import axios from 'utils/axios';
import { isValidArray } from 'utils/checkers';
import { ILogDTO, IQueryParams } from 'interfaces/logs';

const formatData = (result: InfiniteData<any> | undefined): ILogDTO[] => {
  if (!isValidArray(result?.pages)) return [];

  return result?.pages?.reduce((accumulator, value: any) => {
    return accumulator.concat(value?.results);
  }, []);
};

function useHistoryPage() {
  const infinitepageParam: number = 50;

  const [query, setQuery] = useState<null | IQueryParams>(null);

  const getInfiniteLog = async ({ pageParam = { limit: infinitepageParam, offset: 0 } }) => {
    const params = {
      ...pageParam,
      ...(query?.model && { model: query.model }),
      ...(query?.search && { search: query.search }),
      ...(query?.start && { start: query.start }),
      ...(query?.end && { end: query.end })
    };
    const response = await axios.get('/api/history/', { params });
    return response.data;
  };

  const {
    data: infiniteDataResults,
    isLoading,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useInfiniteQuery(['getInfiniteLog', query], getInfiniteLog, {
    getNextPageParam: (lastPage: any, allPages: any) => {
      const allCount = lastPage?.count || 0;
      const loadedPages = allPages?.length || 0;
      const calLoadMore = allCount > loadedPages * infinitepageParam;
      if (!calLoadMore) return false;

      const params = { limit: infinitepageParam, offset: loadedPages * infinitepageParam };
      return params;
    },
    enabled: !!query
  });

  const infiniteData = useMemo(() => formatData(infiniteDataResults), [infiniteDataResults]);

  const showInfiniteLogs: boolean = isValidArray(infiniteData);

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const disabledShowMore: boolean = isFetching || isFetchingNextPage;

  const showMoreButton: boolean = Boolean(showInfiniteLogs && hasNextPage);

  return {
    setQuery,
    loadMore,
    disabledShowMore,
    showMoreButton,
    infiniteData,
    showInfiniteLogs,
    isLoading
  };
}

export default useHistoryPage;
