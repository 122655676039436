import React from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import en from 'date-fns/locale/en-GB';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { Field } from 'react-final-form';
import { ReactComponent as Calendar } from 'assets/images/calendar.svg';
import ErrorFieldString from 'components/ErrorFieldString';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.css';
import './styles.css';

export interface DatePickerFieldProps extends Partial<ReactDatePickerProps> {
  name: string;
  label: string;
  formatOutput?: string;
  validate?: any;
  initialValue?: Date | string;
  disabled?: boolean;
}

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input: 'focus:gray-500 block w-full h-48 border rounded px-20 text-gray-500 focus:outline-none',
  inputOk: 'border-gray-300 focus:border-green disabled:border-transparent',
  inputError: 'border-red'
};

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  name,
  label,
  validate,
  initialValue,
  popperPlacement = 'top-end',
  ...props
}) => {
  registerLocale('en', en);

  const { dateFormat = 'dd.MM.yyyy', formatOutput = 'YYYY-MM-DD' } = props;

  const labelClasses = (isError: boolean) =>
    classes.label + ' ' + (isError ? classes.labelError : classes.labelOk);
  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : classes.inputOk);

  const getSelectedValue = (value?: Date | string) => (value ? new Date(value) : undefined);

  const formatDate = (date: Date | string | undefined) =>
    date ? dayjs(date).format(formatOutput) : undefined;

  return (
    <Field
      name={name}
      validate={validate}
      initialValue={initialValue}
      parse={(data) => data || null}
      format={(value) => value || null}
    >
      {({ input, meta }) => {
        const isError = meta.error && (meta.dirty || meta.touched);

        return (
          <>
            {label && (
              <label className={labelClasses(isError)} htmlFor={name}>
                {label}
              </label>
            )}
            <label
              className={cn('flex items-center justify-between', inputClasses(isError), {
                'bg-gray-100 text-gray-400': props.disabled
              })}
            >
              <DatePicker
                {...props}
                locale="en"
                disabledKeyboardNavigation
                popperPlacement={popperPlacement}
                selected={getSelectedValue(input.value)}
                onChange={(date: Date, e) => {
                  const res = formatDate(date);
                  input.onChange(formatDate(res));
                  if (props.onChange) props.onChange(res as any, e);
                }}
                wrapperClassName={styles.datePickerRoot}
                dateFormat={dateFormat}
                calendarClassName="customPickerField"
              />
              <Calendar />
            </label>
            {isError && <ErrorFieldString error={meta.error} />}
          </>
        );
      }}
    </Field>
  );
};

export default DatePickerField;
