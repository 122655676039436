import React from 'react';
import HistoryTableRow from './HistoryTableRow';
import { ILogDTO } from 'interfaces/logs';

interface IHistoryTableProps {
  logs: ILogDTO[];
}

const HistoryTable: React.FC<IHistoryTableProps> = ({ logs }) => (
  <>
    {logs.map((item: ILogDTO, i: number) => (
      <HistoryTableRow key={i} row={item} />
    ))}
  </>
);

export default HistoryTable;
