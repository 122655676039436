import React from 'react';
import Heading from 'components/Heading';

interface IWithdrawalsHeadingProps {
  title: string;
}

const WithdrawalsHeading: React.FC<IWithdrawalsHeadingProps> = ({ title }) => {
  return (
    <Heading level={1} className="my-64">
      {title}
    </Heading>
  );
};

export default WithdrawalsHeading;
