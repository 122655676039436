import { isString, isEmpty } from 'utils/checkers';
import { useFileUpload } from 'hooks/useFileUpload';

function useValidateFileInput() {
  const { getMultiFileInfo } = useFileUpload();

  const validateMultiFileInput = async (value: string | File[]) => {
    if (isString(value)) {
      const filesInfo = await getMultiFileInfo(value);
      return !isEmpty(filesInfo) ? undefined : 'Field is required!';
    } else {
      return !isEmpty(value) ? undefined : 'Field is required!';
    }
  };

  const validateMultiFileInputByCount = async (value: string | File[]) => {
    if (isString(value)) {
      const filesInfo = await getMultiFileInfo(value);
      return !isEmpty(filesInfo) && filesInfo?.length > 1 ? undefined : 'Field is required!';
    } else {
      return !isEmpty(value) && value?.length > 1 ? undefined : 'Field is required!';
    }
  };

  return { validateMultiFileInput, validateMultiFileInputByCount };
}

export default useValidateFileInput;
