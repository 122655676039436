import React from 'react';
import cn from 'classnames';
import Modal from 'components/Modal';
import AmountField from 'components/AmountField';
import { formatDate, FORMATS } from 'utils/formatters';
import { IWithdrawalChildDTO } from 'interfaces/withdrawal';

interface IWithdrawalChildModal {
  onClose: () => void;
  childWithdrawal: IWithdrawalChildDTO;
  onParentLinkClick: () => void;
}

const WithdrawalChildModal: React.FC<IWithdrawalChildModal> = ({
  onClose,
  childWithdrawal,
  onParentLinkClick
}) => {
  const fields = [
    {
      name: 'Parent request',
      value: (
        <span
          className="text-green underline font-normal cursor-pointer"
          onClick={onParentLinkClick}
        >
          Link to parent
        </span>
      )
    },
    {
      name: 'Request date',
      value: formatDate(childWithdrawal.datetime, FORMATS.detail, { toCyprus: true }),
      show: !!childWithdrawal.datetime
    },
    {
      name: 'Fulfilment date',
      value: formatDate(childWithdrawal.confirmDatetime, FORMATS.global, { toCyprus: true }),
      show: !!childWithdrawal.confirmDatetime
    },
    {
      name: 'Payment method',
      value: childWithdrawal.paymentMethodName,
      show: !!childWithdrawal.paymentMethodName
    }
  ];

  const ModalItemList = ({
    index,
    name,
    value
  }: {
    index: number;
    name: string;
    value: undefined | null | string | number | JSX.Element;
  }) => {
    return (
      <div
        className={cn('flex flex-row justify-between items-center pb-28 border-b border-gray-200', {
          'pt-28': index > 0
        })}
      >
        <span className="text-18 text-gray-400">{name}</span>
        <span className="text-18 text-gray-500 font-medium">{value}</span>
      </div>
    );
  };

  return (
    <Modal title="Withdrawal" onClose={onClose} detailView>
      <div className="text-center my-16">
        <span className="block mb-56 text-48 text-gray-500 font-light">
          <AmountField
            amount={parseFloat(`-${childWithdrawal.amount}`) as number}
            currencyName={childWithdrawal.currencyName}
          />
        </span>

        <div className="flex flex-col w-full">
          <>
            {fields.map(({ show = true, ...items }, i: number) => (
              <React.Fragment key={i}>
                {show && <ModalItemList {...items} index={i} />}
              </React.Fragment>
            ))}
          </>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawalChildModal;
