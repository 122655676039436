import React from 'react';
import cn from 'classnames';

interface IModalItemListProps {
  index: number;
  name: string;
  value: string | number | undefined | React.ReactNode;
  withStyles?: boolean;
}

const ModalItemList: React.FC<IModalItemListProps> = ({
  index,
  name,
  value,
  withStyles = true
}) => {
  const valueColumnStyles = withStyles ? 'text-18 text-gray-500 font-medium' : '';

  return (
    <div
      className={cn('flex flex-row justify-between items-center pb-28 border-b border-gray-200', {
        'pt-28': index > 0
      })}
    >
      <span className="text-18 text-gray-400">{name}</span>
      <span className={valueColumnStyles}>{value}</span>
    </div>
  );
};

export default ModalItemList;
