import React, { FC } from 'react';
import cn from 'classnames';
import { Field } from 'react-final-form';
import Dropzone from 'react-dropzone';
import { isValidArray } from 'utils/checkers';
import useMultiFileInput from './hooks/useMultiFileInput';

export interface IMultiFileInput {
  name: string;
  label?: string;
  placeholder?: string | JSX.Element;
  accept?: string;
  validate?: any;
  initialValue?: string | File | File[] | string[];
  disabled?: boolean;
}

const Plus = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z" fill="#4D4D4D" />
  </svg>
);

const File = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4ZM4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H6C7.10457 14 8 13.1046 8 12V11C8 9.34315 9.34315 8 11 8H12C13.1046 8 14 7.10457 14 6V4C14 2.89543 13.1046 2 12 2H4Z"
      fill="#4D4D4D"
    />
  </svg>
);

const Remove = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM10.8 9.4C11.1866 9.7866 11.1866 10.4134 10.8 10.8C10.4134 11.1866 9.7866 11.1866 9.4 10.8L8 9.4L6.6 10.8C6.2134 11.1866 5.5866 11.1866 5.2 10.8C4.8134 10.4134 4.8134 9.7866 5.2 9.4L6.6 8L5.2 6.6C4.8134 6.2134 4.8134 5.5866 5.2 5.2C5.5866 4.8134 6.2134 4.8134 6.6 5.2L8 6.6L9.4 5.2C9.7866 4.8134 10.4134 4.8134 10.8 5.2C11.1866 5.5866 11.1866 6.2134 10.8 6.6L9.4 8L10.8 9.4Z"
      fill="#C9D4DD"
    />
  </svg>
);

const MultiFileInput: FC<IMultiFileInput> = ({
  accept,
  name,
  validate,
  initialValue,
  label,
  ...props
}) => {
  const {
    preparedFiles,
    uploadedFiles,
    removeFile,
    eraseFile,
    handleChangedFiles
  } = useMultiFileInput(name);

  return (
    <Field name={name} validate={validate} initialValue={initialValue}>
      {() => {
        return (
          <Dropzone {...props} multiple noClick noDrag onDrop={handleChangedFiles}>
            {({ getRootProps, getInputProps, open }) => (
              <section className="flex flex-col w-full">
                {label && (
                  <p className="text-16 leading-24 text-black font-medium mt-0 mb-16">{label}</p>
                )}
                {isValidArray(preparedFiles) && (
                  <div className="flex flex-col w-full">
                    {preparedFiles.map((file: File | any, i: number) => (
                      <div
                        key={'uploaded-' + i}
                        className={cn(
                          'flex items-center justify-between py-10 px-16 mb-2 bg-white',
                          {
                            'rounded-t-8': i === 0,
                            'rounded-b-8': i === preparedFiles?.length - 1 && !uploadedFiles?.length
                          }
                        )}
                      >
                        <div className="w-11/12 flex items-center pr-10 flex-shrink-0">
                          <span className="w-16 h-16">
                            <File />
                          </span>
                          <span className="ml-10 text-16 font-normal truncate">
                            {file?.name || file?.filename}
                          </span>
                        </div>
                        <span
                          className="remove cursor-pointer w-16 h-16"
                          onClick={() => removeFile(i)}
                        >
                          <Remove />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {isValidArray(uploadedFiles) && (
                  <div className="flex flex-col w-full">
                    {uploadedFiles.map((file: File | any, i: number) => (
                      <div
                        key={'uploaded-files-' + i}
                        className={cn(
                          'flex items-center justify-between py-10 px-16 mb-2 bg-white',
                          {
                            'rounded-t-8': i === 0 && !preparedFiles?.length,
                            'rounded-b-8': i === uploadedFiles?.length - 1
                          }
                        )}
                      >
                        <div className="w-11/12 flex items-center pr-10 flex-shrink-0">
                          <span className="w-16 h-16">
                            <File />
                          </span>
                          <span className="ml-10 text-16 font-normal truncate">
                            {file?.name || file?.filename}
                          </span>
                        </div>
                        <span
                          className="remove cursor-pointer w-16 h-16"
                          onClick={() => eraseFile(file?.id)}
                        >
                          <Remove />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                <div {...getRootProps()} className="mt-16">
                  <input {...getInputProps()} />
                  <div className="flex items-center justify-between py-10 px-16 rounded-8 bg-white">
                    <span className="text-16 font-normal">Select files from disk</span>
                    <span className="cursor-pointer" onClick={open}>
                      <Plus />
                    </span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        );
      }}
    </Field>
  );
};

export default MultiFileInput;
