import { useHistory } from 'react-router-dom';
import { usePostClientSetMutation } from 'api/postClientSet';

import ClientSelectModal from 'components/ClientSelectModal';
import ClientSelectModalView from 'components/ClientSelectModal/components/ClientSelectModalView';
import Container from 'components/Container';
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { IGetUserResponse, IGetUserClientsResponse } from 'interfaces/user';

import logo from '../../assets/logo.svg';
import { ReactComponent as ArrowDown } from '../../assets/arrow-down.svg';
import { ReactComponent as Avatar } from '../../assets/avatar.svg';
import { ReactComponent as Gear } from '../../assets/gear.svg';
import HeaderMenu from '../HeaderMenu';
import HeaderMenuView from '../HeaderMenu/components/HeaderMenuView';
import MainMenu from '../MainMenu';

export interface IHeaderView {
  asStorybookComponent?: boolean;
  user?: IGetUserResponse;
  clients?: IGetUserClientsResponse[];
  client?: IGetUserResponse['currentClient'];
  authLoading: boolean;
}

const HeaderView: React.FC<IHeaderView> = ({
  asStorybookComponent,
  user,
  clients,
  client,
  authLoading
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isMenuVisible, setMenuVisibility] = useState(false);

  const { mutate } = usePostClientSetMutation();
  const history = useHistory();

  const onClientChange = useCallback(
    async (clientId: number) => {
      await mutate({ clientId });
      history.push('/');
    },
    [history, mutate]
  );

  const noClientSelected: boolean = Boolean(!client?.id && clients?.length && !authLoading);
  const isSingleClient: boolean = Boolean(clients && clients?.length && clients?.length === 1);

  // Если не выбрана организация — выводим модалку
  useEffect(() => {
    if (noClientSelected) {
      if (isSingleClient) {
        const clientId: number = (clients as IGetUserClientsResponse[])[0]?.id;
        onClientChange(clientId);
        return;
      }
      setModalVisibility(true);
    }
  }, [noClientSelected, isSingleClient, clients, onClientChange]);

  return (
    <>
      <header className="border-b border-gray-200">
        <Container>
          <div className="flex justify-between items-center h-64 relative right-0">
            <div className="flex items-center">
              <Link to="/">
                <img src={logo} alt="Unicsafe" />
              </Link>
              {user && <MainMenu />}
            </div>

            {user && (
              <div className="flex items-center h-64">
                <Link to="/projects" className="mr-16">
                  <Gear />
                </Link>
                <div className="flex items-center" onClick={() => setMenuVisibility(true)}>
                  <Avatar className="text-gray-500 hidden sm:block" />
                  <div className="ml-10 text-gray-600 hidden sm:block">{user.fullName}</div>
                  {client?.name && (
                    <div className="ml-10 text-gray-400 text-14 border border-gray-300 rounded px-12 py-4 hover:border-gray-400 hover:text-gray-500">
                      {client.name}
                    </div>
                  )}
                  <div className="p-10 -mr-10 text-gray-400 hover:text-gray-500">
                    <ArrowDown />
                  </div>
                  {isMenuVisible && (
                    <>
                      {asStorybookComponent && (
                        <HeaderMenuView
                          onClientChange={() => setModalVisibility(false)}
                          clients={clients}
                          client={client}
                          onClose={() => setMenuVisibility(false)}
                          logout={() => {}}
                        />
                      )}
                      {!asStorybookComponent && (
                        <HeaderMenu onClose={() => setMenuVisibility(false)} />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </header>
      {isModalVisible && (
        <>
          {asStorybookComponent && (
            <ClientSelectModalView
              onClientChange={() => setModalVisibility(false)}
              clients={clients}
              onClose={() => setModalVisibility(false)}
            />
          )}
          {!asStorybookComponent && <ClientSelectModal onClose={() => setModalVisibility(false)} />}
        </>
      )}
    </>
  );
};

export default HeaderView;
