import React, { FC } from 'react';
import cn from 'classnames';
import useFileUploadField from './hooks/useFileUploadField';
import PreviewWithControls from './components/PreviewWithControls';
import NofilesBlock from './components/NofilesBlock';
import ErrorFieldString from 'components/ErrorFieldString';
import { Field } from 'react-final-form';

export interface IFileInputProps {
  name: string;
  label?: string;
  placeholder?: string | JSX.Element;
  accept?: string;
  validate?: any;
  initialValue?: string | File;
}

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input:
    'focus:gray-500 block w-full h-160 border border-dashed rounded text-gray-400 focus:outline-none disabled:bg-gray-100 disabled:text-gray-400',
  inputOk: 'border-gray-300 focus:border-green disabled:border-transparent',
  inputError: 'border-red'
};

const FileInput: FC<IFileInputProps> = ({
  name,
  validate,
  initialValue,
  placeholder = undefined,
  ...props
}) => {
  const {
    label = name,
    accept = 'application/pdf, image/png, image/jpg, image/jpeg, image/gif'
  } = props;

  const {
    file,
    previewFile,
    getRootProps,
    getInputProps,
    clearFileField,
    innerZIndex
  } = useFileUploadField({
    name,
    accept
  });

  const labelClasses = (isError: boolean) =>
    classes.label + ' ' + (isError ? classes.labelError : classes.labelOk);
  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : classes.inputOk);

  return (
    <>
      <Field name={name} validate={validate} initialValue={initialValue}>
        {({ meta }) => {
          const isError = meta.error && (meta.dirty || meta.touched);

          return (
            <>
              {label && (
                <label className={labelClasses(isError)} htmlFor={name}>
                  {label}
                </label>
              )}
              <div {...getRootProps()} className={cn(inputClasses(isError), 'bg-transparent')}>
                <input {...props} id={name} {...getInputProps()} />
                <div
                  className="w-full h-full flex flex-col items-center justify-center "
                  style={{ zIndex: innerZIndex }}
                >
                  {!file && <NofilesBlock placeholder={placeholder} />}
                  {!!previewFile && (
                    <PreviewWithControls file={previewFile} clearFileField={clearFileField} />
                  )}
                </div>
              </div>
              {isError && <ErrorFieldString error={meta.error} />}
            </>
          );
        }}
      </Field>
    </>
  );
};

export default FileInput;
