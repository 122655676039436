const Arrow = ({ fill }: { fill?: string }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.9999C12 5.6999 11.9 5.4999 11.7 5.2999L7 0.599903L5.6 1.9999L8.6 4.9999L-4.36085e-07 4.9999L-2.99809e-07 6.9999L8.6 6.9999L5.6 9.9999L7 11.3999L11.7 6.6999C11.9 6.4999 12 6.2999 12 5.9999Z"
      fill={fill ? fill : '#FFFFFF'}
    />
  </svg>
);

export default Arrow;
