import { isValidArray } from './checkers';

export type Prop = keyof any;

export type BaseArray<T, R> = (value: T, index: number, arr: ArrayLike<T>) => R;

export type PredicateArray<T = any> = BaseArray<T, boolean>;

/**
 * getByKey
 * @param  {any} object
 * @param  {K} key
 * @returns string
 */

export const getByKey = <K extends Prop>(
  object: any,
  key: K
): string | number | object | any[] | boolean | undefined =>
  object !== undefined && object !== null ? object[key] : undefined;

/**
 * findInArray
 * @param  {any[]} arr
 * @param  {PredicateArray<T>} fn
 * @returns T
 */

export const findInArray = <T>(arr: any, fn: PredicateArray<T>): T | undefined => {
  if (!isValidArray(arr) || !arr) return;

  for (let i = 0; i < arr.length; i++) {
    if (fn(arr[i], i, arr)) {
      return arr[i];
    }
  }
  return;
};
