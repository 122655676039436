import React from 'react';
import cn from 'classnames';

type ButtonProps = {
  className?: string;
  label: string | JSX.Element;
  disabled?: boolean;
  icon?: JSX.Element;
  outlined?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

const Button: React.FC<ButtonProps> = ({
  className,
  disabled,
  icon,
  outlined,
  type,
  onClick,
  label
}) => {
  return (
    <button
      className={cn(
        'flex items-center text-16 h-60 px-16 transition-all disabled:text-gray-500 disabled:bg-gray-light disabled:cursor-default',
        className,
        {
          'justify-between': !!icon,
          'bg-violet text-white rounded-8': outlined,
          'text-gray-500': !outlined
        }
      )}
      onClick={onClick || undefined}
      type={type}
      disabled={disabled}
    >
      {label}
      {icon && <>{icon}</>}
    </button>
  );
};

export default Button;
