import React from 'react';

interface IModalInnerWrapperProps {
  AmountField: JSX.Element;
  type?: string;
}

const ModalInnerWrapper: React.FC<IModalInnerWrapperProps> = ({ AmountField, children, type }) => {
  return (
    <div className="text-center my-16">
      <span className="block mb-56 text-48 text-gray-500 font-light">
        {type && <span className="mr-8">{type === 'incoming' ? '+' : '-'}</span>}
        {AmountField}
      </span>
      <div className="flex flex-col w-full">{children}</div>
    </div>
  );
};

export default ModalInnerWrapper;
