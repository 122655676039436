import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import axios from 'utils/axios';
import { downloadBlobFile } from 'utils/api';
import { useAuth } from 'hooks/useAuth';
import { isValidArray } from 'utils/checkers';
import { FORMATS } from 'utils/formatters';

import { IReportDTO } from '../interfaces/report';
import { filtersQueryObject } from '../interfaces/filters';

function useReport() {
  const { client } = useAuth();
  const hasReportAccess = client?.hasReportAccess || false;
  const defaultStart: string = useMemo(() => dayjs().startOf('month').format(FORMATS.query), []);
  const defaultEnd: string = useMemo(
    () => dayjs().startOf('month').add(1, 'month').format(FORMATS.query),
    []
  );

  const [query, setQuery] = useState<filtersQueryObject>({
    start: defaultStart,
    end: defaultEnd,
    reportingMonth: false
  });

  const requestURL = useMemo(
    () =>
      query.reportingMonth
        ? `/api/client/revenue/report/?start=${query.start}&end=${query.end}&reporting_month=${query.reportingMonth}`
        : `/api/client/revenue/report/?start=${query.start}&end=${query.end}`,
    [query]
  );

  const downloadReport = async () => {
    const response = await axios.get(requestURL, {
      headers: {
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob',
      data: {}
    });

    if (response.status === 200)
      downloadBlobFile(response.data as any, `financial report ${client?.name}.xlsx`);
  };

  const getReport = async (): Promise<IReportDTO[]> => {
    const response = await axios.get(requestURL, {
      headers: {
        contentType: 'application/json'
      },
      data: {}
    });
    return response.data?.results;
  };

  const { data: report, isLoading } = useQuery(['getReport', query], getReport, {
    enabled: hasReportAccess
  });

  const showTable: boolean = isValidArray(report);

  return {
    showTable,
    report,
    isLoading,
    downloadReport,
    setQuery,
    hasReportAccess
  };
}

export default useReport;
