import Keycloak from 'keycloak-js';

const kcConfig =
  process.env.NODE_ENV === 'development'
    ? {
        url: 'https://auth.k8s.idkfa.team/auth',
        realm: 'unicsafe',
        clientId: 'unicsafe-web-client'
      }
    : '/manage/api/keycloak.json';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak(kcConfig);

export default keycloak;
