/**
 * isString
 * @param  {any} value?
 * @returns value is string
 */

export const isString = (value?: any): value is string =>
  typeof value === 'string' || value instanceof String;

/**
 * isNumber
 * @param  {any} value?
 * @returns value is number
 */

export const isNumber = (value?: any): value is number =>
  (!!value || value === 0) && (typeof value === 'number' || value instanceof Number);

/**
 * isObject
 * @param  {any} value?
 * @returns value is object
 */

export const isObject = (value?: any): value is object =>
  value !== null && !isArray(value) && typeof value === 'object';

/**
 * isFunction
 * @param  {any} value?
 * @returns value is Function
 */

export const isFunction = (value?: any): value is Function =>
  !!value && typeof value === 'function';

/**
 * isArray
 * @param  {any} value?
 * @returns value is Array
 */

export const isArray = (value?: any): value is Array<any> => !!value && Array.isArray(value);

/**
 * isNull
 * @param  {any} value?
 * @returns value is Null
 */

export const isNull = (value?: any): value is null => value === null;

/**
 * isUndefined
 * @param  {any} value?
 * @returns value is Undefined
 */

export const isUndefined = (value?: any): value is undefined => typeof value === 'undefined';

/**
 * isValidArray
 * @param  {any} value?
 * @returns value is valid (map, forEach...) Array
 */

export const isValidArray = (value?: any): boolean => isArray(value) && value?.length > 0;

/**
 * isEmpty
 * @param  {any} value?
 * @returns value is empty
 */

export const isEmpty = (value?: any): boolean => {
  if (isString(value)) return value === '';
  if (isArray(value)) return value.length === 0;
  if (isObject(value)) return Object.keys(value).length === 0;
  if (isNumber(value)) return false;
  if (isNull(value) || isNaN(value) || !value) return true;

  return false;
};

/**
 * isFileLink
 * @param  {string} nameStr
 * @returns {fileName: string, fileExtension: string}
 */

export const isFileLink = (nameStr: string) => {
  if (!isString(nameStr) || !nameStr) return {};
  const extension = nameStr.includes('.') ? nameStr.split('.').pop() : '';

  return { fileName: nameStr, fileExtension: extension };
};
