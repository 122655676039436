import dayjs from 'dayjs';
import { isString } from './checkers';
interface formDataProps {
  [key: string]: string | Blob;
}

interface formatDateOptions {
  toCyprus?: boolean;
  toUTC?: boolean;
  returnNull?: boolean;
}

const DEFAULT_PARAMS = { toCyprus: false, toUTC: false, returnNull: false };
const cyprusOffsetMs = 120 * 60 * 1000;

export const FORMATS = {
  global: 'D MMM YYYY',
  detail: 'D MMM YYYY HH:mm',
  logs: 'HH:mm DD.MM.YYYY',
  filter: 'DD.MM.YYYY',
  query: 'YYYY-MM-DD',
  queryWithTime: 'YYYY-MM-DDTHH:mm'
};

/**
 * @param  {Date|string|null|undefined} dateStr
 * @returns string
 */

export const convertTimeToNull = (dateStr: Date | string | null | undefined): string | null =>
  dateStr ? `${dayjs(dateStr).format(FORMATS.query)}T00:00` : null;

/**
 * @param  {Date|string|null|undefined} dateStr
 * @param  {string} format
 * @param  {formatDateOptions=DEFAULT_PARAMS} options
 * @returns string
 */

export const formatDate = (
  dateStr: Date | string | null | undefined,
  format: string,
  options: formatDateOptions = DEFAULT_PARAMS
): string | null => {
  const { toCyprus, toUTC, returnNull } = options;
  if (!dateStr) return returnNull ? null : '-';

  if (toCyprus || toUTC) {
    const timeMs = dayjs(dateStr).toDate().getTime();
    let result: number = timeMs;
    if (toCyprus) result += cyprusOffsetMs;
    if (toUTC) result -= cyprusOffsetMs;
    return dayjs(result).format(format);
  }

  return dayjs(dateStr).format(format);
};
/**
 * formatIBAN
 * @param  {string} ibanStr
 * @returns string
 */

export const formatIBAN = (ibanStr?: string): string => {
  if (!ibanStr || !isString(ibanStr)) return '-';
  return ibanStr?.length >= 4 ? `* ${ibanStr.substr(ibanStr.length - 4)}` : `* ${ibanStr}`;
};

/**
 * formatFormData
 * @param  {formDataProps} fields
 * @returns FormData
 */

export const formatFormData = (fields: formDataProps): FormData => {
  const formData = new FormData();
  for (const key in fields) {
    let value = fields[key];
    formData.append(key, value);
  }
  return formData;
};

/**
 * formatArrayFormData
 * @param  {formDataProps} fields
 * @returns FormData
 */

export const formatArrayFormData = (fields: any[], key: string): FormData => {
  const formData = new FormData();
  for (let i = 0; i < fields?.length; i++) {
    formData.append(`${key}${i + 1}`, fields[i]);
  }
  return formData;
};

/**
 * @param  {string} val?
 */
export const capitalize = (val?: string): string | undefined => {
  if (typeof val !== 'string' || !val) return val;
  return val.substring(0, 1).toUpperCase() + val.substring(1);
};

/**
 * @param  {string} val?
 */
 export const fromSnakeCase = (val?: string): string | undefined => {
  if (typeof val !== 'string' || !val) return val;
  return val?.split('_').join(' ')
};
