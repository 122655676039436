import React from 'react';
import { Field } from 'react-final-form';
import cn from 'classnames';
import ErrorFieldString from 'components/ErrorFieldString';

export type TextAreaProps = {
  name: string;
  placeholder?: string;
  className?: string;
  validate?: any;
  initialValue?: string | number;
  resizable?: boolean;
  rows?: number;
};

const classes = {
  input:
    'bg-gray-light rounded-8 py-10 px-14 text-gray-dark text-16 leading-20 w-full focus:outline-none disabled:bg-gray-100 disabled:text-gray-400'
};

const TextArea: React.FC<TextAreaProps> = ({
  name,
  placeholder,
  initialValue,
  validate,
  className = '',
  resizable = false,
  rows = 3,
  ...props
}) => {
  return (
    <Field
      {...props}
      name={name}
      validate={validate}
      initialValue={initialValue}
      parse={(data) => data || ''}
      format={(value) => value || ''}
    >
      {({ input, meta }) => {
        const isError = meta.error && (meta.dirty || meta.touched);

        return (
          <>
            <textarea
              className={cn(classes.input, className, { 'resize-none': !resizable })}
              placeholder={placeholder}
              value={input.value}
              onChange={input.onChange}
              rows={rows}
            />
            {isError && <ErrorFieldString error={meta.error} />}
          </>
        );
      }}
    </Field>
  );
};

export default TextArea;
