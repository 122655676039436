import { useMemo } from 'react';
import { IReportDTO } from '../../../interfaces/report';

const getTotal = (data: IReportDTO[], key: keyof IReportDTO): number => {
  let total = 0;
  data.forEach((item: IReportDTO) => {
    total += (item[key] || 0) as number;
  });
  return total;
};

function useTableReport(data: IReportDTO[]) {
  const headerHeight: number = 100;
  const minTableHeight: string = `${50 * (data.length + 1) + headerHeight}px`;

  const tableData = data.concat([
    {
      appName: '',
      client: '',
      clientRevenue: getTotal(data, 'clientRevenue'),
      datetime: '',
      project: 'Total',
      publisher: '',
      rc: '',
      serviceFee: '',
      planRevenueUsd: getTotal(data, 'planRevenueUsd'),
      serviceRevenue: getTotal(data, 'serviceRevenue'),
      amount: getTotal(data, 'amount'),
      amountUsd: getTotal(data, 'amountUsd'),
      serviceRevenueUsd: getTotal(data, 'serviceRevenueUsd'),
      clientRevenueUsd: getTotal(data, 'clientRevenueUsd')
    }
  ]);

  const customHeaderGroup = (
    title: string,
    subtitle: string,
    regular: string,
    className?: string
  ) => (
    <div className={`w-full flex flex-col ${className}`}>
      <span className="block mb-6">{title}</span>
      <span className="block mb-6">{subtitle}</span>
      <span className="block mb-6">{regular}</span>
    </div>
  );

  const isLastRow = (rowIndex: number) => rowIndex === tableData.length - 1;

  const emptySymbol = (isMoneyField: boolean | undefined = false) => (!isMoneyField ? '' : '-');

  const customRowCell = (
    rowIndex: number,
    value?: string | number | null,
    isMoneyField: boolean | undefined = false
  ) => (isLastRow(rowIndex) ? <b>{value}</b> : value || emptySymbol(isMoneyField));

  const formatNumber = (num: number): string | number => {
    const formatted = num ? num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num;
    return formatted;
  };

  const columns = useMemo(
    () => [
      {
        title: 'Проект',
        key: 'project',
        dataKey: 'project',
        width: 100,
        resizable: true,
        dataGetter: ({ rowData, rowIndex }: any) => customRowCell(rowIndex, rowData?.project)
      },
      {
        title: 'ЦФО1',
        key: 'rc',
        dataKey: 'rc',
        width: 100,
        resizable: true,
        dataGetter: ({ rowData }: any) => rowData?.rc || emptySymbol()
      },
      {
        title: 'ЦФО1',
        key: 'publisher',
        dataKey: 'publisher',
        width: 200,
        resizable: true,
        dataGetter: ({ rowData }: any) => rowData?.publisher || emptySymbol()
      },
      {
        title: 'ФЛ',
        key: 'client',
        dataKey: 'client',
        width: 200,
        resizable: true,
        dataGetter: ({ rowData }: any) => rowData?.client || emptySymbol()
      },
      {
        title: 'Комиссия',
        key: 'serviceFee',
        dataKey: 'serviceFee',
        width: 100,
        resizable: true,
        dataGetter: ({ rowData }: any) => rowData?.serviceFee || emptySymbol()
      },
      {
        title: 'Плановая дата',
        key: 'planDate',
        dataKey: 'planDate',
        width: 170,
        resizable: true,
        dataGetter: ({ rowData }: any) => rowData?.planDate || emptySymbol(),
        headerClassName: () => 'bg-orange-extralight'
      },
      {
        title: 'Приложение',
        key: 'appName',
        dataKey: 'appName',
        width: 200,
        resizable: true,
        dataGetter: ({ rowData }: any) => rowData?.appName || emptySymbol(),
        headerClassName: () => 'bg-orange-extralight'
      },
      {
        title: 'Revenue',
        key: 'planRevenueEur',
        dataKey: 'planRevenueEur',
        width: 150,
        resizable: true,
        dataGetter: ({ rowData }: any) =>
          formatNumber(rowData?.planRevenueEur) || emptySymbol(true),
        headerClassName: (): string => 'bg-orange-extralight text-left ',
        headerRenderer: () => customHeaderGroup('План', 'Выручка', 'EUR'),
        align: 'right' as any
      },
      {
        title: 'Revenue',
        key: 'planRevenueUsd',
        dataKey: 'planRevenueUsd',
        width: 150,
        resizable: true,
        dataGetter: ({ rowData }: any) =>
          formatNumber(rowData?.planRevenueUsd) || emptySymbol(true),
        headerClassName: (): string => 'bg-orange-extralight text-left ',
        headerRenderer: () => customHeaderGroup('План', 'Выручка', '$'),
        align: 'right' as any
      },
      {
        title: 'Фактическая дата',
        key: 'datetime',
        dataKey: 'datetime',
        width: 170,
        resizable: true,
        dataGetter: ({ rowData }: any) => rowData?.datetime || emptySymbol(),
        headerClassName: () => 'bg-green-extralight'
      },
      {
        title: 'Amount',
        key: 'amount',
        dataKey: 'amount',
        width: 125,
        resizable: true,
        headerClassName: (): string => 'bg-green-extralight text-left',
        dataGetter: ({ rowData, rowIndex }: any) =>
          customRowCell(rowIndex, formatNumber(rowData?.amount), true),
        headerRenderer: () => customHeaderGroup('Факт', 'Выручка', 'EUR'),
        align: 'right' as any
      },
      {
        title: 'Amount',
        key: 'amountUsd',
        dataKey: 'amountUsd',
        width: 125,
        resizable: true,
        headerClassName: (): string => 'bg-green-extralight text-left ',
        dataGetter: ({ rowData, rowIndex }: any) =>
          customRowCell(rowIndex, formatNumber(rowData?.amountUsd), true),
        headerRenderer: () => customHeaderGroup('Факт', 'Выручка', '$'),
        align: 'right' as any
      },
      {
        title: 'Service Revenue',
        key: 'serviceRevenue',
        dataKey: 'serviceRevenue',
        width: 125,
        resizable: true,
        headerClassName: (): string => 'bg-green-extralight text-left ',
        dataGetter: ({ rowData, rowIndex }: any) =>
          customRowCell(rowIndex, formatNumber(rowData?.serviceRevenue), true),
        headerRenderer: () => customHeaderGroup('Факт', 'Комиссия', 'EUR'),
        align: 'right' as any
      },
      {
        title: 'Service Revenue',
        key: 'serviceRevenueUsd',
        dataKey: 'serviceRevenueUsd',
        width: 125,
        resizable: true,
        headerClassName: (): string => 'bg-green-extralight text-left ',
        dataGetter: ({ rowData, rowIndex }: any) =>
          customRowCell(rowIndex, formatNumber(rowData?.serviceRevenueUsd), true),
        headerRenderer: () => customHeaderGroup('Факт', 'Комиссия', '$'),
        align: 'right' as any
      },
      {
        title: 'Client Revenue',
        key: 'clientRevenue',
        dataKey: 'clientRevenue',
        width: 125,
        resizable: true,
        headerClassName: (): string => 'bg-green-extralight text-left ',
        dataGetter: ({ rowData, rowIndex }: any) =>
          customRowCell(rowIndex, formatNumber(rowData?.clientRevenue), true),
        headerRenderer: () => customHeaderGroup('Факт', 'Выручка минус комиссия', 'EUR'),
        align: 'right' as any
      },
      {
        title: 'Client Revenue',
        key: 'clientRevenueUsd',
        dataKey: 'clientRevenueUsd',
        width: 125,
        resizable: true,
        headerClassName: (): string => 'bg-green-extralight text-left ',
        dataGetter: ({ rowData, rowIndex }: any) =>
          customRowCell(rowIndex, formatNumber(rowData?.clientRevenueUsd), true),
        headerRenderer: () => customHeaderGroup('Факт', 'Выручка минус комиссия', '$'),
        align: 'right' as any
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { headerHeight, minTableHeight, tableData, columns, isLastRow };
}

export default useTableReport;
