import { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import MultiFileInput from 'components/redesign/MultiFileInput/MultiFileInput';
import DatePickerField from 'components/DatePickerField';
import YearSwitcher from 'components/DatePicker/components/YearSwitcher';
import MonthSwitcher from 'components/DatePicker/components/MonthSwitcher';
import { generateYears } from 'components/DatePicker/utils/generate';
import { fieldRequiredRule } from 'validation/rules';
import { ReactComponent as Copy } from 'assets/images/copy.svg';
import { ReactComponent as Approved } from 'assets/images/approved.svg';

type InfoRowProps = {
  label: string;
  value: string;
  canCopy?: boolean;
};

const InfoRow = ({ label, value, canCopy = false }: InfoRowProps) => {
  const [clicked, setClicked] = useState(false);

  const handleCopyClick = () => {
    setClicked(true);
    copy(value);
  };

  useEffect(() => {
    let timerId: any;
    if (clicked) {
      timerId = setTimeout(() => setClicked(false), 3000);
    }
    return () => clearTimeout(timerId);
  }, [clicked]);

  return (
    <div className="flex flex-col mb-36">
      <label className="text-14 text-gray-400 mb-12">{label}</label>
      <div className="flex items-center">
        <span className="text-18 text-gray-500">{value}</span>
        {canCopy && (
          <span className="ml-10 cursor-pointer" onClick={handleCopyClick}>
            {clicked ? <Approved height={20} /> : <Copy height={20} />}
          </span>
        )}
      </div>
    </div>
  );
};

type AppObject = {
  iban?: string;
  publisherName?: string;
  publisherAddress?: string;
  clientName?: string;
  currency?: string;
};

type AccountToStoreProps = {
  app: AppObject;
};

const getAccountNumber = (iban?: string) => {
  if (!iban) return;
  if (iban.length > 16) return iban?.substr(iban.length - 16);
  return iban;
};

const AccountToStore: React.FC<AccountToStoreProps> = ({ app }) => {
  return (
    <>
      <div className="flex flex-row justify-between py-56 px-56 mb-40 -mx-256 rounded-12 bg-gray-100">
        <div className="flex flex-col w-1/2">
          <InfoRow label="Name" value="UNLIMINT EU LTD" canCopy />
          <InfoRow label="Bank Country or Region" value="Cyprus" canCopy />
          <InfoRow label="Bank Account Currency" value="EUR - Euro" canCopy />
          <InfoRow
            label="Bank Address"
            value={app?.publisherAddress || '-'}
            canCopy
          />
          <InfoRow label="Cyprus Bank Identification Code" value="90200001" canCopy />
        </div>
        <div className="w-44"></div>
        <div className="flex flex-col w-1/2">
          <InfoRow label="IBAN" value={app?.iban || '-'} canCopy />
          <InfoRow label="Account Number" value={getAccountNumber(app?.iban) || '-'} canCopy />
          <InfoRow label="Account Holder Name" value={app?.publisherName || '-'} canCopy />
          <InfoRow label="Account Holder Type" value="Corporation" />
        </div>
      </div>
      <div className="mb-28 -mx-32 px-32 py-20 rounded-8 bg-gray-100">
        <MultiFileInput
          accept="image/*"
          name="appstoreScreenshotFile"
          label="App Store screenshot"
          validate={fieldRequiredRule}
        />
      </div>
      <div className="mb-28">
        <DatePickerField
          name="appstoreDate"
          label="Select date"
          validate={fieldRequiredRule}
          popperPlacement="top-end"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '40px, 0'
            }
          }}
          renderCustomHeader={({
            changeYear,
            date,
            decreaseMonth,
            increaseMonth
          }: {
            changeYear: (year: number) => void;
            date: Date;
            decreaseMonth: () => void;
            increaseMonth: () => void;
          }) => (
            <>
              <YearSwitcher years={generateYears(2000)} changeYear={changeYear} date={date} />
              <MonthSwitcher
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                date={date}
              />
            </>
          )}
        />
      </div>
    </>
  );
};

export default AccountToStore;
