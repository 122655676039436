import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IApplicationDTO } from 'interfaces/application';
import Header from 'components/Header';
import Container from 'components/Container';
import NavPanel from 'components/NavPanel';
import AppSwitcher from './components/AppSwitcher';
import AppInfo from './components/AppInfo';
import Tabs from './components/Tabs';
import useApplication from './hooks/useApplication';

const Application: React.FC = () => {
  const { app, refetch, isLoading } = useApplication();

  return (
    <>
      <Header />
      <NavPanel />
      <Container>
        {isLoading && <Skeleton count={5} />}
        {!isLoading && (
          <>
            <AppSwitcher {...(app as IApplicationDTO)} refetch={refetch} />
            <AppInfo {...(app as IApplicationDTO)} refetch={refetch} />
            <Tabs {...(app as IApplicationDTO)} refetch={refetch} />
          </>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default Application;
