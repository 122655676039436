import React, { useMemo } from 'react';
import cn from 'classnames';
import { Form } from 'react-final-form';
import { useQuery, useMutation } from 'react-query';
import Modal from 'components/redesign/Modal';
import ErrorLabel from 'components/ErrorLabel';
import Select from 'components/redesign/Select';
import CurrencyInput from 'components/redesign/CurrencyInput';
import FileInput from 'components/redesign/FileInput';
import Button from 'components/redesign/Button';
import AmountField from 'components/AmountField';
import { useFileUpload } from 'hooks/useFileUpload';
import { IWithdrawalDTO } from 'interfaces/withdrawal';
import { fieldRequiredRule } from 'validation/rules';
import { getPaymentMethods } from 'api/selectors';
import { getApplication } from 'api/applications';
import { getWithdrawal, updateWithdrawal } from 'api/withdrawals';

import { ReactComponent as Reply } from 'assets/redesign/reply.svg';

import { isString } from 'utils/checkers';

import Arrow from 'components/redesign/Icons';

type WithdrawalEditProps = {
  withdrawalId: string;
  onClose: () => void;
};

const WithdrawalEdit: React.FC<WithdrawalEditProps> = ({ withdrawalId, onClose }) => {
  const { uploadFile } = useFileUpload();

  const { data: paymentMethods, isLoading: pmLoading } = useQuery(
    'getPaymentMethods',
    getPaymentMethods
  );
  const { data: withdrawal, isLoading: withdrawalLoading, refetch } = useQuery(
    'getWithdrawal',
    () => getWithdrawal(withdrawalId)
  );
  const { data: application, isLoading: applicationLoading } = useQuery(
    'getApplication',
    () => getApplication(withdrawal?.applicationId as string),
    { enabled: !!withdrawal?.applicationId }
  );

  const submitWithdrawal = async (fields: Partial<IWithdrawalDTO>) => {
    const { file, ...items } = fields;
    let formData = { ...items } as IWithdrawalDTO;

    if (!isString(file)) {
      const fileUUID: string | null = await uploadFile(file);
      formData = { file: fileUUID as any, ...formData };
    }

    const response = await updateWithdrawal(fields?.id as number, formData);

    if (response?.status === 'deleted') onClose();

    return response;
  };

  const { mutate: editWithdrawal, isLoading: editLoading, error } = useMutation(submitWithdrawal, {
    onSuccess: refetch as any
  });

  const submitForm = (formData: IWithdrawalDTO) => editWithdrawal(formData);

  const isLoading = pmLoading || withdrawalLoading || editLoading || applicationLoading;

  const withdrawalStatus = useMemo(
    () =>
      withdrawal?.status ? withdrawal?.status[0].toUpperCase() + withdrawal?.status.slice(1) : '',
    [withdrawal?.status]
  );

  const isCompleted = withdrawal?.status === 'completed';
  const isPending = withdrawal?.status === 'pending';
  const isRejected = withdrawal?.status === 'rejected';

  return (
    <Modal
      skeleton={isLoading || !paymentMethods}
      customHeader={
        <div className="flex items-center justify-between w-full mb-24">
          <div
            className={cn('text-16 text-black', {
              'text-green': isCompleted,
              'text-red': isRejected,
              'text-violet': isPending
            })}
          >
            {withdrawalStatus}
          </div>
          <div
            className="text-16 text-black hover:text-black-total transition-all cursor-pointer"
            onClick={onClose}
          >
            Close
          </div>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        <Form
          onSubmit={submitForm}
          initialValues={{ ...withdrawal }}
          render={({ handleSubmit, valid, submitting, values, initialValues, dirty }) => {
            return (
              <form onSubmit={handleSubmit}>
                {values?.comment && (
                  <div className="flex justify-between w-full rounded-8 mb-24 py-12 px-14 bg-red-extraLight">
                    <Reply />
                    <div className="w-full ml-10 text-14 leading-20 text-black">
                      {values?.comment}
                    </div>
                  </div>
                )}

                <div className="w-full my-16">
                  <CurrencyInput
                    name="amount"
                    label="Amount"
                    placeholder={application?.currency}
                    prefix={application?.currency}
                    disabled={isPending || isCompleted}
                    maxValue={application?.balance}
                    validate={fieldRequiredRule}
                  />
                </div>
                <div className="text-16 mb-32 text-black text-center">
                  Available —{' '}
                  <AmountField amount={application?.balance} currencyName={application?.currency} />
                </div>
                {!withdrawal?.parentId && (
                  <div className="w-full mb-16">
                    <FileInput
                      accept="application/pdf, image/*"
                      name="file"
                      label="File"
                      disabled={isPending || isCompleted}
                      validate={fieldRequiredRule}
                    />
                  </div>
                )}
                {paymentMethods && (
                  <div className="w-full mb-16 ">
                    <Select
                      name="paymentMethod"
                      label="Payment method"
                      options={paymentMethods}
                      isDisabled={isPending || isCompleted}
                      validate={fieldRequiredRule}
                    />
                  </div>
                )}
                {!isPending && !isCompleted && (
                  <div className="flex items-center justify-between w-full mb-32">
                    <Button
                      className="w-208"
                      type="submit"
                      disabled={!valid || submitting || !dirty}
                      icon={<Arrow fill={!valid || submitting || !dirty ? '#9AA5AD' : '#FFFFFF'} />}
                      label="Update request"
                      outlined
                    />
                    <Button
                      className="w-208 hover:text-red"
                      disabled={submitting}
                      onClick={() => editWithdrawal({ ...initialValues, status: 'deleted' })}
                      label="Cancel request"
                    />
                  </div>
                )}

                {error && <ErrorLabel error={error as any} />}
              </form>
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default WithdrawalEdit;
