import dayjs from 'dayjs';
import { isNumber } from 'utils/checkers';

const defaultMax = Number(dayjs().format('YYYY'));

export const generateYears = (from: number = defaultMax - 2, to: number = defaultMax) => {
  if (!isNumber(from) || !isNumber(to)) return [defaultMax - 2, defaultMax - 1, defaultMax];

  if (from > to) return [from - 2, from - 1, from];

  let years = [];
  for (let i = from; i <= to; i++) {
    years.push(i);
  }
  return years;
};
