import React, { useState, useMemo } from 'react';
import { formatDate, FORMATS } from 'utils/formatters';
import AmountField from 'components/AmountField';
import { ReactComponent as Withdrawal } from 'assets/images/withdrawal.svg';

import { ReactComponent as Pending } from 'pages/Application/images/pending.svg';
import { ReactComponent as Declined } from 'pages/Application/images/declined.svg';
import { ReactComponent as Completed } from 'pages/Application/images/completed.svg';

import WithdrawalModalSwitcher from '../Modals/WithdrawalModalSwitcher';

import { IWithdrawalDTO } from 'interfaces/withdrawal';

export interface IWithdrawalsListRow extends IWithdrawalDTO {}

export const WithdrawalsListRow: React.FC<IWithdrawalsListRow> = ({ ...props }) => {
  const {
    date,
    datetime,
    amount,
    paymentMethodName,
    currencyName,
    status,
    confirmDatetime,
    withdrawalFee
  } = props;
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const operationName = paymentMethodName ? `Withdrawal (${paymentMethodName})` : 'Withdrawal';

  const withdarwalStatusIcon = useMemo(() => {
    if (status === 'completed') return <Completed />;
    if (status === 'pending') return <Pending />;
    if (status === 'rejected') return <Declined />;
  }, [status]);

  const operationDate = useMemo(() => {
    if (status === 'completed') return confirmDatetime;
    if (datetime) return datetime;
    if (date) return date;
    return null;
  }, [status, confirmDatetime, datetime, date]);

  const isCompleted = status === 'completed';

  const withdrawalAmountMinusFee = useMemo(() => amount - withdrawalFee, [amount, withdrawalFee]);

  return (
    <>
      {modal && <WithdrawalModalSwitcher withdrawal={{ ...props }} onClose={closeModal} />}
      <div
        onClick={openModal}
        className="flex flex-nowrap justify-between w-full p-16 border-b border-gray-200 hover:bg-gray-50 cursor-pointer"
      >
        <div className="w-full flex flex-nowrap items-center">
          <Withdrawal />
          <div className="flex flex-col ml-16">
            <div className="flex items-center text-gray-700 text-16">
              {operationName}
              <span className="ml-10">{withdarwalStatusIcon}</span>
            </div>
            <div className="text-gray-400 text-12 mt-6">
              {formatDate(operationDate, FORMATS.global)}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-240">
          <div className="flex items-center justify-end text-gray-700 text-16 font-medium">
            <span className="block mr-4">-</span>
            <AmountField amount={amount} currencyName={currencyName} />
          </div>
          {isCompleted && (
            <div className="flex items-center justify-end flex-nowrap mt-6 text-14 text-gray-400 font-normal">
              amount after fee: -
              <AmountField amount={withdrawalAmountMinusFee} currencyName={currencyName} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
