import axios from 'utils/axios';
import { IProjectDTO } from 'interfaces/project';

export const getProjects = async () => {
  const response = await axios.get(`/api/projects/`);
  return response.data as IProjectDTO[];
};

export const createProject = async (params: { name: string; client: number }) => {
  const response = await axios.post(`/api/projects/`, params);
  return response.data as IProjectDTO;
};

export const updateProject = async (id: number, params: { name: string }) => {
  const response = await axios.patch(`/api/projects/${id}/`, params);
  return response.data as IProjectDTO;
};

export const deleteProject = async (id: number) => {
  const response = await axios.delete(`/api/projects/${id}/`);
  return response.data;
};
