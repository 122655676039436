const primaryColor = '#5FCA77';

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    boxShadow: 'unset'
  }),
  control: (provided: any, { isFocused }: any) => ({
    ...provided,
    height: '100%',
    outline: 'none',
    border: `1px solid ${isFocused ? primaryColor : 'transparent'}`,
    boxShadow: 'unset',

    '&:hover': {
      borderColor: `1px solid ${primaryColor}`
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'unset'
  }),
  option: (provided: any, { isSelected }: any) => ({
    ...provided,
    textAlign: 'left',
    background: `${isSelected ? primaryColor : 'transparent'}`,
    '&:hover': {
      background: `${isSelected ? primaryColor : 'rgba(95, 202, 119, 0.1)'}`
    }
  })
};
