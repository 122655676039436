import React from 'react';
import Header from 'components/Header';
import Container from 'components/Container';
import NoResults from 'components/NoResults';
import useReport from './hooks';
import FiltersPanel from './components/FiltersPanel';
import ReportHeading from './components/ReportHeading';
import ReportTable from './components/Table';
import { IReportDTO } from './interfaces/report';
import { filtersQueryObject } from './interfaces/filters';

const Report: React.FC = () => {
  const { showTable, report, isLoading, downloadReport, setQuery, hasReportAccess } = useReport();

  return (
    <>
      <Header />
      <Container>
        <ReportHeading />
        <FiltersPanel
          onFiltersSubmit={(args: filtersQueryObject) => setQuery(args)}
          onExport={downloadReport}
          hasReportAccess={hasReportAccess}
        />
        {!showTable && !isLoading && <NoResults message="No report data" />}
      </Container>
      {showTable && !isLoading && <ReportTable data={report as IReportDTO[]} />}
      <div className="h-128" />
    </>
  );
};

export default Report;
