export const appStatuses = {
  appSet: 'app_details_set',
  appSetRejected: 'app_details_rejected',
  bankDetailsSet: 'bank_details_set',
  bankDetailsRejected: 'bank_details_rejected',
  contractSet: 'contract_details_set',
  contractRejected: 'contract_details_rejected',
  approved: 'approved',
  rejected: 'rejected'
};
