import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { IRequestDTO } from 'interfaces/request';
import { useFileUpload } from 'hooks/useFileUpload';
import { isString } from 'utils/checkers';
import { getRequest, createRequest, patchRequest } from 'api/request';

// http://localhost:3000/request/1a1b4298-745b-450e-849c-f81a96837b7f

const ERROR_MESSAGE = 'Request error occured. Please try again later';

function useRequestForm() {
  const { id } = useParams<{ id?: string }>();
  const { uploadFile } = useFileUpload();

  const [successScreen, setSuccessedScreen] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string>('');
  const [step, setStep] = useState<number>(!!id ? 0 : 1);

  const setStepByStatus = (status?: string) => {
    if (status === 'new') {
      setStep(2);
    }
  };

  const { isLoading, data: request } = useQuery(
    ['getRequestById', id],
    () => getRequest(id as string),
    {
      enabled: !!id,
      refetchOnWindowFocus: true,
      onSuccess: (data: Partial<IRequestDTO>) => {
        setStepByStatus(data?.status);
        setSuccessedScreen(!!data?.requestSubmitted);
      }
    }
  );

  const title = useMemo(() => {
    if (step === 1) return 'Please fill in your application profile';
    if (step === 2) return 'Document Submission';
    if (step === 3) return 'Payment Info Setup';
    return '';
  }, [step]);

  const subtitle = useMemo(() => {
    if (step === 3) return 'Please post provided bank details to your App Store account';
    return;
  }, [step]);

  const showForm = ((id && !isLoading) || !id) && step !== 0;

  const { mutate: createRequestMutation } = useMutation(createRequest, {
    onSuccess: () => setSuccessedScreen(true),
    onError: () => setRequestError(ERROR_MESSAGE)
  });

  const { mutate: updateRequestMutation } = useMutation(
    (data) => patchRequest(id as string, data as any),
    {
      onSuccess: () => setSuccessedScreen(true),
      onError: () => setRequestError(ERROR_MESSAGE)
    }
  );

  const submitForm = async (values: Partial<IRequestDTO>, form: any) => {
    setRequestError('');
    let formValues = { ...values, requestSubmitted: true };

    if (!!id) {
      let containerId;
      const initialValues = form?.getState()?.initialValues;

      if (!isString(values?.signedDocuments) && !!values?.signedDocuments) {
        containerId = isString(initialValues?.signedDocuments)
          ? initialValues?.signedDocuments
          : '';
        const signedDocuments = await uploadFile(values?.signedDocuments, containerId);
        formValues = { ...formValues, signedDocuments };
      }

      if (!isString(values?.screenshots) && !!values?.screenshots) {
        containerId = isString(initialValues?.screenshots) ? initialValues?.screenshots : '';
        const screenshots = await uploadFile(values?.screenshots, containerId);
        formValues = { ...formValues, screenshots };
      }
      await updateRequestMutation(formValues as any);
      return;
    }

    await createRequestMutation(formValues);
  };

  return {
    request,
    title,
    subtitle,
    step,
    showForm,
    submitForm,
    successScreen,
    requestError
  };
}

export default useRequestForm;
