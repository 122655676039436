import React, { useState } from 'react';
import cn from 'classnames';
import Modal from 'components/Modal';
import { downloadBlobFile } from 'utils/api';
import { ReactComponent as Show } from 'assets/images/show.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { ReactComponent as Download } from 'assets/images/download.svg';

type ImageComponentProps = {
  modal?: boolean;
};

type PreviewWithControlsProps = {
  file: any;
  clearFileField: () => void;
};

const PreviewWithControls: React.FC<PreviewWithControlsProps> = ({ file, clearFileField }) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const { fileURL, fileName, isPDF } = file;

  const download = () => downloadBlobFile(fileURL, fileName);

  const ImageComponent = ({ modal }: ImageComponentProps) => (
    <img
      src={fileURL}
      alt={fileName}
      className={cn('mx-auto', {
        'h-full': !modal,
        'w-full': modal
      })}
    />
  );

  const Controls = () => (
    <>
      {!isPDF && (
        <span
          className="px-16 py-16 cursor-pointer hover:opacity-50"
          onClick={() => setShowDetail(true)}
        >
          <Show />
        </span>
      )}
      {isPDF && fileURL && (
        <span className="px-16 py-16 cursor-pointer hover:opacity-50" onClick={download}>
          <Download />
        </span>
      )}
      <span
        className="px-16 py-16 cursor-pointer hover:opacity-50"
        onClick={() => clearFileField()}
      >
        <Delete />
      </span>
    </>
  );

  return (
    <div
      className="relative w-full h-full"
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {showDetail && (
        <Modal onClose={() => setShowDetail(false)} title="" detailView>
          <ImageComponent modal />
        </Modal>
      )}
      {hovered && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-center bg-opacity-50 bg-black">
          <Controls />
        </div>
      )}
      {!isPDF && <ImageComponent />}
      {isPDF && <div className="flex items-center justify-center w-full h-full">{fileName}</div>}
    </div>
  );
};

export default PreviewWithControls;
