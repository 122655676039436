import { useState, useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { isValidArray, isString } from 'utils/checkers';
import { useFileUpload } from 'hooks/useFileUpload';

const useMultiFileInput = (name: string) => {
  const { getMultiFileInfo, deleteFile } = useFileUpload();
  const { values, initialValues } = useFormState();
  const { change } = useForm();
  const files = values[name];
  const uploaded = initialValues[name];

  const [preparedFiles, setPreparedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const prepareUploaded = async () => {
      const filesInfo = await getMultiFileInfo(uploaded as string);

      if (isValidArray(filesInfo)) {
        setUploadedFiles(filesInfo);
        return;
      }

      if (filesInfo?.id) {
        setUploadedFiles([filesInfo as never]);
      }
    };

    if (isString(uploaded)) prepareUploaded();

    return () => setUploadedFiles([]);
    //  eslint-disable-next-line
  }, [uploaded]);

  useEffect(() => {
    if (!isValidArray(files)) return;
    setPreparedFiles(files);

    return () => setPreparedFiles([]);
  }, [files]);

  const removeFile = (i: number) => {
    const result = files?.filter((_: File, index: number) => index !== i);
    change(name, result);
  };

  const eraseFile = async (id: string) => {
    const deletedId = await deleteFile(id);
    if (deletedId) {
      const result = uploadedFiles?.filter((file) => (file as any)?.id !== deletedId);
      setUploadedFiles(result);
    }
  };

  const handleChangedFiles = (acceptedFiles: File[]) =>
    change(name, [...preparedFiles, ...acceptedFiles]);

  return { preparedFiles, uploadedFiles, files, removeFile, eraseFile, handleChangedFiles };
};

export default useMultiFileInput;
