import React from 'react';
import Container from 'components/Container';
import Header from 'components/Header';
import { Redirect } from 'react-router-dom';
import useLogin from './hooks/useLogin';

const Login: React.FC = () => {
  const { currentLocationState, isAuthenticated } = useLogin();

  if (isAuthenticated) return <Redirect to={currentLocationState?.from as string} />;

  return (
    <>
      <Header />
      <Container>
        <div className="max-w-sm mx-auto mt-240">
          <p className="text-20 text-center text-gray-400">Loading…</p>
        </div>
      </Container>
    </>
  );
};

export default Login;
