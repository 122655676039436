import React from 'react';

interface IErrorLabelProps extends React.HTMLAttributes<HTMLElement> {
  header?: string;
  message: string;
}

const Alert = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#FF7664" />
    <path
      d="M21.1494 23.3916H18.9043L18.6572 12.3594H21.4072L21.1494 23.3916ZM18.582 26.7646C18.582 26.3564 18.7145 26.0199 18.9795 25.7549C19.2445 25.4827 19.6061 25.3467 20.0645 25.3467C20.5228 25.3467 20.8844 25.4827 21.1494 25.7549C21.4144 26.0199 21.5469 26.3564 21.5469 26.7646C21.5469 27.1585 21.418 27.488 21.1602 27.7529C20.9023 28.0179 20.5371 28.1504 20.0645 28.1504C19.5918 28.1504 19.2266 28.0179 18.9688 27.7529C18.7109 27.488 18.582 27.1585 18.582 26.7646Z"
      fill="white"
    />
  </svg>
);

const ErrorLabel: React.FC<IErrorLabelProps> = ({
  header = 'Oops! Something went wrong!',
  message,
  ...props
}) => {
  return (
    <div className="flex items-center p-16 my-60 rounded-6 text-black bg-red-light" {...props}>
      <Alert />
      <div className="flex flex-col ml-16">
        <p className="text-16 leading-20 font-medium mt-0 mb-6">{header}</p>
        <p className="text-16 leading-20 font-normal my-0">{message}</p>
      </div>
    </div>
  );
};

export default ErrorLabel;
