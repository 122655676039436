import React, { useState } from 'react';
import { Field } from 'react-final-form';
import cn from 'classnames';
import ErrorFieldString from 'components/ErrorFieldString';

export type InputProps = {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  inputStyles?: string;
  validate?: any;
  validateOnBlurOnly?: boolean;
  initialValue?: string | number;
};

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input:
    'focus:gray-500 block w-full h-48 border rounded px-20 text-gray-500 focus:outline-none disabled:bg-gray-100 disabled:text-gray-400',
  inputOk: 'border-gray-300 focus:border-green disabled:border-transparent',
  inputError: 'border-red'
};

const Input: React.FC<InputProps> = ({
  name,
  label,
  type = 'text',
  placeholder,
  initialValue,
  validate,
  inputStyles = '',
  validateOnBlurOnly = false,
  ...props
}) => {
  const [blured, setBlured] = useState(false);
  const labelClasses = (isError: boolean) =>
    classes.label + ' ' + (isError ? classes.labelError : classes.labelOk);
  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : classes.inputOk);

  return (
    <Field
      name={name}
      validate={validate}
      initialValue={initialValue}
      {...props}
      parse={(data) => data || ''}
      format={(value) => value || ''}
    >
      {({ input, meta }) => {
        let isError;
        isError = meta.error && (meta.dirty || meta.touched);

        if (validateOnBlurOnly) {
          isError = blured && isError;
        }

        return (
          <>
            {label && (
              <label className={labelClasses(isError)} htmlFor={name}>
                {label}
              </label>
            )}
            <input
              className={cn(inputClasses(isError), inputStyles)}
              type={type}
              placeholder={placeholder}
              value={input.value}
              onChange={input.onChange}
              onFocus={() => setBlured(false)}
              onBlur={() => setBlured(true)}
            />
            {isError && <ErrorFieldString error={meta.error} />}
          </>
        );
      }}
    </Field>
  );
};

export default Input;
