import React, { useState } from 'react';
import WithdrawalParentModal from 'components/WithdrawalModals/WithdrawalParentModal';
import WithdrawalChildModal from 'components/WithdrawalModals/WithdrawalChildModal';

import { IWithdrawalDTO, IWithdrawalChildDTO } from 'interfaces/withdrawal';

interface IWithdrawalModalSwitcher {
  onClose: () => void;
  withdrawal: IWithdrawalDTO;
}

const WithdrawalModalSwitcher: React.FC<IWithdrawalModalSwitcher> = ({ onClose, withdrawal }) => {
  const [parentView, setParentView] = useState<boolean>(true);
  const [child, setChild] = useState<IWithdrawalChildDTO | null>(null);

  const switchToParent = () => {
    setParentView(true);
    setChild(null);
  };

  const switchToChild = (selectedChild: IWithdrawalChildDTO) => {
    setChild({
      ...selectedChild,
      paymentMethodName: withdrawal.paymentMethodName
    });
    setParentView(false);
  };

  return (
    <>
      {parentView && (
        <WithdrawalParentModal
          withdrawal={withdrawal}
          onClose={onClose}
          onChildClick={switchToChild}
        />
      )}
      {!parentView && child && (
        <WithdrawalChildModal
          onClose={onClose}
          childWithdrawal={child as IWithdrawalChildDTO}
          onParentLinkClick={switchToParent as any}
        />
      )}
    </>
  );
};

export default WithdrawalModalSwitcher;
