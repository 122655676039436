import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Container from 'components/Container';
import Header from 'components/Header';
import LoadMore from 'components/LoadMore';
import NoResults from 'components/NoResults';
import Search from './components/Search';
import HistoryHeading from './components/HistoryHeading';
import HistoryTable from './components/HistoryTable';
import { ILogDTO } from 'interfaces/logs';
import useHistoryPage from './hooks/useHistoryPage';

const History: React.FC = () => {
  const {
    setQuery,
    loadMore,
    disabledShowMore,
    showMoreButton,
    infiniteData,
    showInfiniteLogs,
    isLoading
  } = useHistoryPage();

  return (
    <>
      <Header />
      <Container>
        <HistoryHeading />
        <Search onChange={setQuery} />
        {isLoading && <Skeleton count={5} />}
        {!isLoading && (
          <>
            {showInfiniteLogs && <HistoryTable logs={infiniteData as ILogDTO[]} />}
            {!showInfiniteLogs && <NoResults message="No history" />}
            {showMoreButton && <LoadMore onClick={loadMore} disabled={disabledShowMore} />}
          </>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default History;
