import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { IRequestDTO } from 'interfaces/request';

import useRequestForm from 'pages/RequestApp/hooks/useRequestForm';

import Title from './Title';
import Subtitle from './Subtitle';
import SubmitButton from './SubmitButton';
import ErrorLabel from '../ErrorLabel';

import { Step1, Step2, Step3 } from './Steps';

import Success from './Success';

interface IRequestAppProps {
  title: string;
  subtitle?: string;
  step: number;
  initialValues?: Partial<IRequestDTO>;
}

const RequestAppForm: React.FC<IRequestAppProps> = ({
  title,
  subtitle = null,
  step,
  initialValues = {}
}) => {
  const { submitForm, successScreen, requestError } = useRequestForm();
  const requestApp = (values: Partial<IRequestDTO>, form: any) => submitForm(values, form);

  const error: string | undefined = initialValues?.reply || requestError;

  useEffect(() => {
    if (!!error) {
      document?.getElementById('RequestAppError')?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [error]);

  return (
    <div className="flex flex-col max-w-2xl m-auto">
      {successScreen ? (
        <Success step={step} />
      ) : (
        <>
          {error && <ErrorLabel id="RequestAppError" message={error} />}
          <div className="w-full max-w-xl mx-auto">
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </div>
          <div className="w-full max-w-sm mx-auto">
            <div className="w-full mt-48">
              <Form
                onSubmit={requestApp}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, valid, values }) => (
                  <form className="w-full" onSubmit={handleSubmit}>
                    {step === 1 && <Step1 />}
                    {step === 2 && <Step2 />}
                    {step === 3 && <Step3 />}
                    <SubmitButton disabled={submitting || !valid} />
                  </form>
                )}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RequestAppForm;
