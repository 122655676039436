import React, { useState, useEffect, MouseEvent } from 'react';
import { Popover, PopoverProps } from 'react-tiny-popover';
import copy from 'copy-to-clipboard';
import { ReactComponent as Checkmark } from 'assets/redesign/checkmark.svg';
import { ReactComponent as Copy } from 'assets/redesign/copy.svg';

interface ICopyToClipboard extends Partial<PopoverProps> {
  value: string | number;
}

const CopyToClipboard: React.FC<ICopyToClipboard> = ({
  value,
  positions = ['bottom'],
  padding = 20,
  ...props
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setCopied(true);
    copy(String(Number(value).toFixed(2)));
  };

  useEffect(() => {
    let timerId: any;
    if (copied) {
      timerId = setTimeout(() => setCopied(false), 3000);
    }
    return () => clearTimeout(timerId);
  }, [copied]);

  return (
    <div className="">
      <Popover
        {...props}
        isOpen={copied}
        positions={positions}
        padding={padding}
        content={
          <div className="flex items-center justify-between py-12 px-16 rounded-96 bg-gray-500 text-white transition-all">
            <Checkmark />
            <span className="ml-8">copied</span>
          </div>
        }
      >
        <span onClick={handleCopyClick}>
          <Copy />
        </span>
      </Popover>
    </div>
  );
};

export default CopyToClipboard;
