import { useMemo } from 'react';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';

type SuccessScreenProps = {
  step: number;
};

const Success = ({ step }: SuccessScreenProps) => {
  const title = useMemo(() => {
    if (step === 1) return 'Thank you for applying';
    if (step === 2) return 'Thanks for submitting documents!';
    if (step === 3) return 'Thanks for your last effort! ';
    return '';
  }, [step]);

  const subtitle = useMemo(() => {
    if (step === 1) return 'We’ll check your information and get back to you soon';
    if (step === 2)
      return (
        <>
          We’ll send them to the bank for review.
          <br />
          Please be patient with us as the bank might take up to 2 weeks to issue an IBAN
        </>
      );
    if (step === 3)
      return (
        <>
          We are one step away from your account activation.
          <br />
          We will get back to you when the bank activates your account
        </>
      );
    return '';
  }, [step]);

  return (
    <div className="flex flex-col justify-center items-center text-center w-full sm:py-112">
      <SuccessIcon />
      <span className="mb-20 mt-40 text-36 text-gray-500">{title}</span>
      <p className="text-14 leading-16 text-gray-500">{subtitle}</p>
    </div>
  );
};

export default Success;
