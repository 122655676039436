import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useAuth } from 'hooks/useAuth';

const MainMenu: React.FC = () => {
  const { client, isOwner, manyClientUsers } = useAuth();
  const history = useHistory();
  const path = history?.location?.pathname;

  const links = [
    {
      name: 'Apps',
      to: '/',
      show: true
    },
    {
      name: 'Withdrawals',
      to: '/withdrawals',
      show: client?.hasBalanceAccess || false
    },
    {
      name: 'Report',
      to: '/report',
      show: client?.hasReportAccess || false
    },
    {
      name: 'History log',
      to: '/history',
      show: true
    },
    {
      name: 'Permissions',
      to: '/permissions',
      show: isOwner && manyClientUsers
    }
  ];
  return (
    <div className="flex items-center ml-56">
      {links.map((link, i) => (
        <React.Fragment key={`menu-item-${i}`}>
          {link.show && (
            <Link
              to={link.to}
              className={cn('text-14 mr-32 ', {
                'text-green font-medium': path === link.to,
                'text-gray-500 hover:text-gray-700': path !== link.to
              })}
            >
              {link.name}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MainMenu;
