import cn from 'classnames';
import { ReactComponent as ProfileIcon } from '../../assets/profile.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';
import { IGetUserResponse, IGetUserClientsResponse } from 'interfaces/user';
import classes from '../../HeaderMenu.module.css';
import { useRef } from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside';

type HeaderMenuViewProps = {
  onClose: () => void;
  onClientChange: (id: number) => void;
  clients?: IGetUserClientsResponse[];
  client?: IGetUserResponse['currentClient'];
  logout: () => void;
};

const HeaderMenuView: React.FC<HeaderMenuViewProps> = ({
  clients,
  client,
  logout,
  onClose,
  onClientChange
}) => {
  const ref = useRef() as any;
  useOnClickOutside(ref, onClose);

  return (
    <div
      className={`${classes.root} absolute right-0 w-224 rounded-6 shadow-lg bg-white text-16 cursor-default z-10`}
      ref={ref}
    >
      <div className={classes.arrowUp} />
      <div className="h-56 px-20 border-b text-gray-500 border-gray-300 flex items-center">
        <ProfileIcon className="text-green" />
        <div className="ml-16">Profile</div>
      </div>

      {!!clients?.length && (
        <>
          <div className="text-12 text-gray-400 uppercase pt-20 pb-10 px-20 tracking-widest">
            Account
          </div>
          <div className="pb-6 border-b text-gray-500 border-gray-300">
            {clients.map((item) => (
              <div
                key={item.id}
                className="h-36 hover:bg-gray-50 px-20 flex items-center cursor-pointer"
                onClick={() => onClientChange(item.id)}
              >
                <div
                  className={cn('whitespace-nowrap overflow-hidden overflow-ellipsis', {
                    'text-green': item.id === client?.id
                  })}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
          <div
            className="h-56 px-20 flex items-center text-gray-500 hover:bg-gray-50 cursor-pointer"
            onClick={logout}
          >
            <LogoutIcon className="text-green" />
            <div className="ml-16">Log Out</div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderMenuView;
