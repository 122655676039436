import React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { generateYears } from '../utils/generate';

interface IYearSelectorProps {
  years?: number[];
  changeYear: (year: number) => void;
  date: Date;
}

const YearSelector: React.FC<IYearSelectorProps> = ({
  changeYear,
  date,
  years = generateYears()
}) => {
  const isYearSelected = (year: number): boolean => Number(dayjs(date).format('YYYY')) === year;

  return (
    <div className="flex items-center justify-center mt-16 mb-20">
      {years.map((year: number, i: number) => (
        <span
          key={`year-${i}`}
          className={cn('text-16 cursor-pointer', {
            'ml-16': i !== 0,
            'text-gray-300': !isYearSelected(year)
          })}
          onClick={() => changeYear(year)}
        >
          {year}
        </span>
      ))}
    </div>
  );
};

export default YearSelector;
