import axios from 'utils/axios';
import {
  IGetUserResponse,
  IGetUserClientsResponse,
  IUserResponse,
  IUserAppsResponse
} from 'interfaces/user';

export const getUser = async () => {
  return (await axios.get<IGetUserResponse>('/api/session/get_me')).data;
};

export const getUserClients = async () => {
  return (await axios.get<IGetUserClientsResponse[]>('/api/session/clients/')).data;
};

export const getUsers = async () => {
  const response = await axios.get<IUserResponse[]>('/api/permissions/users/');
  return response.data;
};

export const getUserPermissions = async (id: string) => {
  const response = await axios.get<IUserAppsResponse[]>(`/api/permissions/users/${id}`);
  return response.data;
};

export const updateUserPermissions = async (id: string, data: IUserAppsResponse[]) => {
  const response = await axios.put(`/api/permissions/users/${id}/`, data);
  return response.data;
};
