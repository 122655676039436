import { useState, useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useFileUpload } from 'hooks/useFileUpload';
import { getApplication } from 'api/applications';
import { getByKey, findInArray } from 'utils/getters';
import axios from 'utils/axios';
import { isValidArray } from 'utils/checkers';
import { getPaymentMethods } from 'api/selectors';
import { createWithdrawal } from 'api/withdrawals';
import { getClientBalance } from 'api/client';
import { IRequestWithdarwalProps, IRequestWithdarwalForm } from 'interfaces/withdrawal';
import { IPaymentMethodsResponseDTO } from 'interfaces/paymentMethods';

function useRequestWithdrawal({
  id,
  esr,
  esrId,
  defaultCurrency,
  onSubmit,
  onClose,
  maxAmount
}: IRequestWithdarwalProps) {
  const [error, setError] = useState<string>('');

  const { uploadFile } = useFileUpload();

  const submitForm = (data: IRequestWithdarwalForm) => sendWithdrawal(data);

  const submitWithdrawal = async (fields: IRequestWithdarwalForm) => {
    // currency,
    const { file, ...items } = fields;
    const formData = { ...items } as IRequestWithdarwalForm;

    if (esr && esrId) formData.storeRevenue = esrId;

    const fileUUID: string | null = await uploadFile(file);

    const response = await createWithdrawal({
      file: fileUUID,
      applicationId: id,
      // currency: !currency ? defaultCurrency : currency,
      ...formData
    });

    if (response?.id) {
      onSubmit();
      onClose();
    }
    return response;
  };

  const getCurrencies = async () => {
    const response = await axios.get('/api/currencies/');
    if (isValidArray(response.data)) {
      return response.data.filter(({ code }: any) => {
        // TODO: REmove to get all currencies
        return code === 'EUR' || code === 'USD';
      });
    }
    return [];
  };

  const { data: appDetails, isLoading: appLoading } = useQuery(
    ['getAppDetails'],
    () => getApplication(id),
    {
      enabled: !!id
    }
  );

  const {
    data: paymentMethods,
    isLoading: paymentMethodsLoading
  } = useQuery<IPaymentMethodsResponseDTO>(['getPaymentMethods'], getPaymentMethods);
  const { data: currencies, isLoading: currencyLoading } = useQuery(
    ['getCurrencies'],
    getCurrencies
  );

  const { mutate: sendWithdrawal, isLoading: submitLoading } = useMutation(submitWithdrawal, {
    onError: () => setError('Request error. Please try again later')
  });

  const validatePaymentMethods = (paymentMethod: number) => {
    if (!paymentMethod) return;

    if (paymentMethods) {
      const selectedPaymentMethod = findInArray(
        paymentMethods,
        (method) => (method as any).id === paymentMethod
      );

      const flagFile = getByKey(selectedPaymentMethod, 'isAttachedFileByClient') || false;
      const currencyFlag = getByKey(selectedPaymentMethod, 'isConversion') || false;

      return { fileField: flagFile, currencyField: currencyFlag };
    }
  };

  const { isLoading: balanceLoading, data: balance } = useQuery('getBalance', getClientBalance);

  const validateAmount = useMemo(() => {
    if (esr) return maxAmount;

    return balance && appDetails?.balance && balance >= appDetails?.balance
      ? appDetails?.balance
      : balance;
  }, [esr, maxAmount, appDetails, balance]);
  const isLoading =
    appLoading || paymentMethodsLoading || currencyLoading || submitLoading || balanceLoading;
  const currencyPrefix = appDetails?.currency;

  return {
    error,
    setError,
    submitForm,
    validatePaymentMethods,
    isLoading,
    currencies,
    validateAmount,
    paymentMethods,
    currencyPrefix
  };
}

export default useRequestWithdrawal;
