import React, { FC } from 'react';
import cn from 'classnames';
import useFileUploadField from './hooks/useFileUploadField';
import PreviewWithControls from './components/PreviewWithControls';
import NofilesBlock from './components/NofilesBlock';
import { Field } from 'react-final-form';

export interface IFileInputProps {
  name: string;
  label?: string;
  placeholder?: string | JSX.Element;
  accept?: string;
  validate?: any;
  initialValue?: string | File;
  disabled?: boolean;
}

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input:
    'focus:gray-500 block w-full h-60 text-gray-400 focus:outline-none disabled:bg-gray-100 disabled:text-gray-400',
  inputOk: 'border-gray-300 focus:border-green disabled:border-transparent',
  inputError: 'border-red'
};

const FileInput: FC<IFileInputProps> = ({
  name,
  validate,
  initialValue,
  placeholder = undefined,
  disabled = false,
  ...props
}) => {
  const { accept = 'application/pdf, image/png, image/jpg, image/jpeg, image/gif' } = props;

  const {
    backendFile,
    file,
    previewFile,
    getRootProps,
    getInputProps,
    clearFileField,
    innerZIndex
  } = useFileUploadField({
    name,
    accept,
    disabled
  });

  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : classes.inputOk);

  return (
    <>
      <Field name={name} validate={validate} initialValue={initialValue}>
        {({ meta }) => {
          const isError = meta.error && (meta.dirty || meta.touched);

          const { ref } = getRootProps();

          return (
            <>
              <div {...getRootProps()} className={cn(inputClasses(isError), 'bg-transparent')}>
                <input {...props} id={name} {...getInputProps()} />
                <div
                  className="w-full h-full flex flex-col items-center justify-center rounded-8 bg-gray-light px-16 py-18"
                  style={{ zIndex: innerZIndex }}
                >
                  {!file && <NofilesBlock placeholder={placeholder} disabled={disabled} />}
                  {!!previewFile && (
                    <PreviewWithControls
                      file={previewFile}
                      clearFileField={clearFileField}
                      disabled={disabled}
                      backendFile={backendFile}
                      ref={ref?.current || null}
                    />
                  )}
                </div>
              </div>
            </>
          );
        }}
      </Field>
    </>
  );
};

export default FileInput;
