import React from 'react';
import AmountField from 'components/AmountField';
import { formatDate, FORMATS } from 'utils/formatters';
import ModalInnerWrapper from './components/ModalInnerWrapper';
import ModalItemList from './components/ModalItemList';

export interface ICorrectiveModalProps {
  amount: number;
  datetime: string;
  currency: string;
  type?: string;
  reason?: string;
}

export const CorrectiveModal: React.FC<ICorrectiveModalProps> = ({
  amount,
  datetime,
  currency,
  type,
  reason
}) => {
  const vatFields = [
    {
      name: 'Date',
      value: formatDate(datetime, FORMATS.global)
    },
    {
      name: 'Reason',
      value: reason,
      show: !!reason
    }
  ];

  return (
    <ModalInnerWrapper
      AmountField={<AmountField amount={amount} currencyName={currency} />}
      type={type}
    >
      {vatFields.map(({ name, value, show = true }, index) => (
        <React.Fragment key={`corrective-${index}`}>
          {show && <ModalItemList name={name} value={value} index={index} />}
        </React.Fragment>
      ))}
    </ModalInnerWrapper>
  );
};
