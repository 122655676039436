import { ILogActorDTO } from 'interfaces/logs';
import { formatDate, FORMATS } from 'utils/formatters';

export const formatActionWord = (action: string) =>
  `${action?.charAt(0).toUpperCase()}${action?.slice(1)}d`;

export const formatUser = (userObject: ILogActorDTO) => {
  if (userObject?.firstName && userObject?.lastName)
    return `${userObject.firstName} ${userObject.lastName}`;
  if (userObject?.name) return userObject.name;
};

export const createLabelTitle = (
  startDate: string | Date | null,
  endDate: string | Date | null
) => {
  const stringStart = formatDate(startDate, FORMATS.filter);
  const stringEnd = formatDate(endDate, FORMATS.filter);

  if ((startDate && endDate && stringStart === stringEnd) || !endDate) return stringStart;
  if (startDate && endDate) {
    return `${stringStart} - ${stringEnd}`;
  }
};
