import React from 'react';
import { WithdrawalsListRow } from './WithdrawalsListRow';
import { IWithdrawalDTO } from 'interfaces/withdrawal';

interface IWithdrawalsList {
  withdrawals?: IWithdrawalDTO[];
}

const WithdrawalsList: React.FC<IWithdrawalsList> = ({ withdrawals }) => (
  <>
    {withdrawals?.map((withdrawal: IWithdrawalDTO, i: number) => (
      <WithdrawalsListRow key={`withdrawal-${withdrawal.id}-${i}`} {...withdrawal} />
    ))}
  </>
);

export default WithdrawalsList;
