export const steps = [
  {
    id: 1,
    title: 'Application Profile'
  },
  {
    id: 2,
    title: 'Document Submission'
  },
  {
    id: 3,
    title: 'Payment Info Setup'
  },
  {
    id: 4,
    title: 'Account Activation'
  }
];
