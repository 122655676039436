import { useAuth } from 'hooks/useAuth';
import HeaderView from './components/HeaderView';

const Header: React.FC = () => {
  const { user, clients, client, authLoading } = useAuth();

  return <HeaderView user={user} clients={clients} client={client} authLoading={authLoading} />;
};

export default Header;
