import React from 'react';
import dayjs from 'dayjs';
import { findInArray } from 'utils/getters';
import { ReactComponent as Decrease } from '../assets/previous.svg';
import { ReactComponent as Increase } from '../assets/next.svg';

import { DEFAULT_LOCALE_OPTIONS } from '../locale';

interface IMonthSwitcherProps {
  decreaseMonth: () => void;
  increaseMonth: () => void;
  date: Date;
}

const MonthSwitcher: React.FC<IMonthSwitcherProps> = ({ decreaseMonth, increaseMonth, date }) => {
  const months = DEFAULT_LOCALE_OPTIONS.months;

  const getMonth = (): string =>
    findInArray(months, (x) => x === dayjs(date).format('MMMM')) as string;
  return (
    <div className="flex items-center justify-center mb-20">
      <span className="cursor-pointer previous-month" onClick={decreaseMonth}>
        <Decrease />
      </span>
      <span className="text-32 mx-32">{getMonth()}</span>
      <span className="cursor-pointer next-month" onClick={increaseMonth}>
        <Increase />
      </span>
    </div>
  );
};

export default MonthSwitcher;
