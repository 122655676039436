import React from 'react';
import { Link } from 'react-router-dom';
import Heading from 'components/Heading';
import { ReactComponent as Plus } from 'assets/images/plus.svg';

const HomeHeading: React.FC = () => {
  return (
    <Heading level={1} className="my-64">
      <div className="flex items-center justify-between">
        <span>Apps</span>
        <Link
          className="flex items-center px-14 py-14 border border-gray-200 rounded-6 focus:outline-none hover:bg-gray-100 text-gray-400"
          to={'/add'}
        >
          <Plus />
          <span className="ml-16 text-18">Add new app</span>
        </Link>
      </div>
    </Heading>
  );
};

export default HomeHeading;
