import React from 'react';
import { useQuery } from 'react-query';
import AmountField from 'components/AmountField';
import { formatDate, FORMATS } from 'utils/formatters';
import { createDownloadURL } from 'utils/api';
import ModalInnerWrapper from './components/ModalInnerWrapper';
import ModalItemList from './components/ModalItemList';

interface IExpectedStoreRevenueModalProps {
  amount: number;
  currency: string;
  date: string;
  file: string | null;
}

export const ExpectedStoreRevenueModal: React.FC<IExpectedStoreRevenueModalProps> = ({
  amount,
  date,
  currency,
  file
}) => {
  const esrFields = [
    {
      name: 'Date',
      value: formatDate(date, FORMATS.detail, { toCyprus: true })
    }
  ];

  const { isLoading, data: image } = useQuery(['getImage'], () => createDownloadURL(file as any), {
    enabled: !!file
  });

  return (
    <ModalInnerWrapper AmountField={<AmountField amount={amount} currencyName={currency} />}>
      <>
        {esrFields.map(({ name, value }, index) => (
          <ModalItemList key={`ESR-${index}`} name={name} value={value} index={index} />
        ))}
        {!isLoading && !!image && !!file && (
          <div className="w-full mr-auto mt-56">
            <img className="max-w-xs mx-auto" src={image} alt={file}></img>
          </div>
        )}
      </>
    </ModalInnerWrapper>
  );
};
