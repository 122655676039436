import React from 'react';
import { Form } from 'react-final-form';
import { isValidArray } from 'utils/checkers';
import Input from 'components/Input';
import Select from 'components/Select';
import ModalItemList from '../components/ModalItemList';
import { IApplicationDTO } from 'interfaces/application';
import { IProjectDTO } from 'interfaces/project';

export interface IApplicationInfoModalViewProps extends IApplicationDTO {
  onChangeForm: () => void;
  projects: IProjectDTO[];
  submitForm: (data: IApplicationUpdateForm) => void;
  isLoading: boolean;
}

interface IApplicationUpdateForm {
  project: number | null;
  rc: string | null;
}

const ApplicationInfoModalView: React.FC<IApplicationInfoModalViewProps> = ({
  name,
  link,
  fee,
  monthly,
  iban,
  publisherName,
  project = null,
  rc = '',
  projects,
  submitForm,
  isLoading
}) => {
  const appFields = [
    {
      name: 'Application name',
      value: name
    },
    {
      name: 'Link to the application',
      value: link
    },
    {
      name: 'Service Fee',
      value: `${fee?.toFixed(2)}%`,
      show: Boolean(fee && fee !== 0)
    },
    {
      name: 'Monthly fee',
      value: `${monthly?.toFixed(2)}`,
      show: Boolean(monthly && monthly !== 0)
    },
    {
      name: 'Bank account',
      value: iban
    },
    {
      name: 'Publisher',
      value: publisherName || '-'
    },
    {
      name: 'Project',
      value: (
        <div className="w-208">
          <Select
            name="project"
            label=""
            placeholder="Select project..."
            options={projects}
            isSearchable
            isClearable
          />
        </div>
      ),
      show: isValidArray(projects),
      withStyles: false
    },
    {
      name: 'RC',
      value: (
        <div className="w-208">
          <Input name="rc" label="" inputStyles="text-right" />
        </div>
      ),
      withStyles: false
    }
  ];

  return (
    <div className="text-center my-16">
      <div className="flex flex-col w-full">
        <Form
          onSubmit={submitForm}
          initialValues={{
            rc,
            project
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <>
                {appFields.map(({ name, value, withStyles, show = true }, index) => (
                  <React.Fragment key={`app-modal-${index}`}>
                    {show && (
                      <ModalItemList
                        name={name}
                        value={value}
                        index={index}
                        withStyles={withStyles}
                      />
                    )}
                  </React.Fragment>
                ))}
                <button
                  className="w-240 mt-36 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:cursor-default disabled:opacity-50"
                  type="submit"
                  disabled={isLoading || submitting}
                >
                  Save
                </button>
              </>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default ApplicationInfoModalView;
