import { setAxiosToken } from 'utils/axios';

export const lsKeys = {
  token: 'kc-token',
  refreshToken: 'kc-token-refresh'
};

export const decodeJWT = (token: string): any => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.stringify(JSON.parse(window.atob(base64)), null, 4);
};

export const tokenLogger = (tokens: any) => {
  setAxiosToken(tokens?.token);
  localStorage.setItem(lsKeys.token, tokens?.token);
  localStorage.setItem(lsKeys.refreshToken, tokens?.refreshToken);
  // const tkn = JSON.parse(decodeJWT(tokens?.token));
  // const roles = tkn ? tkn['resource_access']['unicsafe-service'].roles : [];
};
