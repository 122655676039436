import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, Redirect } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { IUserResponse } from 'interfaces/user';
import Header from 'components/Header';
import Container from 'components/Container';
import Heading from 'components/Heading';
import { isValidArray } from 'utils/checkers';
import NavPanel from 'components/NavPanel';
import NoResults from 'components/NoResults';
import { getUserName } from './utils/user';

const Permissions: React.FC = () => {
  const { users, isOwner, authLoading } = useAuth();

  const showUsers: boolean = isValidArray(users);

  if (!authLoading && !isOwner) return <Redirect to="/" />;

  return (
    <>
      <Header />
      <NavPanel />
      <Container>
        <Heading level={1} className="my-64">
          Permissions
        </Heading>
        {authLoading && <Skeleton count={5} />}
        {!authLoading && (
          <>
            {showUsers && (
              <div className="flex flex-col">
                {users?.map((item: IUserResponse) => (
                  <Link
                    key={`user-link-${item.id}`}
                    to={`/permissions/${item.id}`}
                    className="ml-14 mb-24 text-18 text-gray-400 cursor-pointer hover:text-gray-500"
                  >
                    {getUserName(item)}
                  </Link>
                ))}
              </div>
            )}
            {!showUsers && <NoResults message="No users for permissions set" />}
          </>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default Permissions;
