import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getApplications } from 'api/applications';
import { appStatuses } from 'const/appStatuses';
import { IApplicationDTO } from 'interfaces/application';
import { isValidArray } from 'utils/checkers';

interface IUseAppSwitcher extends Partial<IApplicationDTO> {
  refetch: () => void;
}

function useAppSwitcher({ refetch, id }: IUseAppSwitcher) {
  const history = useHistory();

  const [popup, setPopup] = useState<boolean>(false);

  const { data: apps } = useQuery(
    ['getApps'],
    () => getApplications({ status: appStatuses.approved, selector: true }),
    { refetchInterval: 120000 }
  );

  const appsIds: string[] = !!apps && isValidArray(apps) ? apps?.map((app) => app.id) : [];

  const replaceHistory = (id: string) => history.replace(id);

  const appsLoaded: boolean = isValidArray(appsIds);
  const manyApps: boolean = appsLoaded && appsIds.length > 1;
  const lastIndex: number = appsIds?.length - 1 || 0;
  const currentIndex: number = appsIds?.findIndex((item: string) => item === id) || 0;
  const isFirstApp: boolean = appsLoaded && currentIndex === 0;
  const isLastApp: boolean = lastIndex === currentIndex;

  const nextApp = () => replaceHistory(isLastApp ? appsIds[0] : appsIds[currentIndex + 1]);
  const prevApp = () => replaceHistory(isFirstApp ? appsIds[lastIndex] : appsIds[currentIndex - 1]);

  const showDetailsModal = () => setPopup(true);
  const closeDetailsModal = () => {
    setPopup(false);
    refetch();
  };

  return {
    manyApps,
    prevApp,
    nextApp,
    showDetailsModal,
    popup,
    closeDetailsModal
  };
}

export default useAppSwitcher;
