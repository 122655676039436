import axios from 'utils/axios';
import { useQuery } from 'react-query';
import { tabsTypes } from '../const';
import { IAppsList } from '../OperationsList';

function useOperationList({ appId, tab, balance }: Partial<IAppsList>) {
  const getTabs = async () => {
    const params = { applicationId: appId };

    if (tab === tabsTypes.incoming || tab === tabsTypes.outgoing) {
      (params as any).type = tab.toLowerCase();
    }

    // FIXME: https://idkfa.atlassian.net/browse/UNS-616
    // Uncomment Store Revenue Operations

    // if (tab === tabsTypes.revenue) {
    // (params as any).sronly = true
    // }

    const response = await axios.get('/api/transactions/', { params });
    return response.data?.filter((item: any) => item?.category !== 'SR') || [];
  };

  const { data: operations, isLoading, refetch } = useQuery(
    ['getOperationsListInTab', tab, appId, balance],
    getTabs
  );

  const showList = Boolean(operations?.length > 0);
  const emptyData = !showList && !isLoading;

  return {
    refetch,
    showList,
    emptyData,
    operations
  };
}

export default useOperationList;
