import { fieldRequiredRule, maxLength, composeValidators } from 'validation/rules';
import Input from 'components/Input';
import TextArea from 'components/TextArea';

type AppDetailsProps = {};

const AppDetails: React.FC<AppDetailsProps> = () => (
  <>
    <div className="mb-28">
      <Input name="name" label="Application name" validate={fieldRequiredRule} />
    </div>
    <div className="mb-28">
      <Input
        name="link"
        label="Link to application"
        validate={composeValidators([fieldRequiredRule, maxLength(240)])}
      />
    </div>
    <div className="mb-28">
      <TextArea name="comment" label="Comment" validate={maxLength(140)} />
    </div>
  </>
);

export default AppDetails;
