import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import dayjs from 'dayjs';
import { FORMATS } from 'utils/formatters';
import DatePickerField from 'components/DatePickerField';
import YearSwitcher from 'components/DatePicker/components/YearSwitcher';
import { filtersQueryObject } from '../../interfaces/filters';

type IFiltersPanelProps = {
  onFiltersSubmit: (args: filtersQueryObject) => void;
  onExport: () => void;
  hasReportAccess: boolean;
};

const FiltersPanel: React.FC<IFiltersPanelProps> = ({
  onFiltersSubmit,
  onExport,
  hasReportAccess
}) => {
  const defaultDate = useMemo(() => new Date(), []);

  const submitForm = ({ reportMonth, operationMonth }: any) => {
    const field = reportMonth || operationMonth;
    const filters = {
      start: dayjs(field).startOf('month').format(FORMATS.query),
      end: dayjs(field).startOf('month').add(1, 'month').format(FORMATS.query),
      reportingMonth: !!reportMonth
    };

    onFiltersSubmit(filters);
  };

  return (
    <Form
      onSubmit={submitForm}
      render={({ handleSubmit, values, form, ...items }) => {
        const isEmptyForm = !values.reportMonth && !values?.operationMonth;

        return (
          <form onSubmit={handleSubmit}>
            <div className="flex  mb-48 text-16">
              <div className="mr-16">
                <DatePickerField
                  name="operationMonth"
                  placeholderText="Operation month"
                  label=""
                  showMonthYearPicker
                  dateFormat="MMMM yyyy"
                  initialValue={defaultDate}
                  onChange={(value) => !!value && form.change('reportMonth', undefined)}
                  renderCustomHeader={({
                    changeYear,
                    date
                  }: {
                    changeYear: (year: number) => void;
                    date: Date;
                  }) => <YearSwitcher changeYear={changeYear} date={date} />}
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '-20px, 0'
                    }
                  }}
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                  disabled={!hasReportAccess}
                />
              </div>
              <div className="mr-16">
                <DatePickerField
                  name="reportMonth"
                  placeholderText="Reporting month"
                  label=""
                  showMonthYearPicker
                  dateFormat="MMMM yyyy"
                  onChange={(value) => !!value && form.change('operationMonth', undefined)}
                  renderCustomHeader={({
                    changeYear,
                    date
                  }: {
                    changeYear: (year: number) => void;
                    date: Date;
                  }) => <YearSwitcher changeYear={changeYear} date={date} />}
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '-20px, 0'
                    }
                  }}
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                  disabled={!hasReportAccess}
                />
              </div>

              <button
                className="text-16 w-96 h-44 mr-16 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:opacity-50"
                type="submit"
                disabled={!hasReportAccess || isEmptyForm}
              >
                Create
              </button>
              <button
                className="text-16 w-96 h-44 py-14 text-center bg-gray-200 text-gray-500 rounded-6 focus:outline-none disabled:opacity-50"
                onClick={onExport}
                disabled={!hasReportAccess || isEmptyForm}
              >
                Export
              </button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default FiltersPanel;
