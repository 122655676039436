import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { tokenLogger, lsKeys } from 'utils/keycloak';

import keycloak from './keycloak';
import './styles/main.css';

keycloak.onTokenExpired = () => {
  console.log('token expired', keycloak.token);
};

const eventLogger = (event: unknown) => {
  console.log('onKeycloakEvent', event);
  if (event === 'onInitError') {
    keycloak.clearToken();
    keycloak.login();
  }
};

// React-keycloak solution
// https://github.com/react-keycloak/react-keycloak/issues/121
ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        token: localStorage.getItem(lsKeys.token),
        refreshToken: localStorage.getItem(lsKeys.refreshToken)
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
