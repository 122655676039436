import React from 'react';
import AmountField from 'components/AmountField';
import Modal from 'components/redesign/Modal';
import useAccessLevel from 'hooks/useAccessLevel';
import { IApplicationDTO } from 'interfaces/application';
import useAppInfo from './hooks/useAppInfo';
import { RequestWithdrawal } from '../Modals/RequestWithdrawal';

interface IAppInfoProps extends IApplicationDTO {
  refetch: Function;
}

const AppInfo: React.FC<IAppInfoProps> = ({
  id,
  balance,
  currency,
  currencyId,
  accessLevel,
  refetch
}) => {
  const { disableWithdrawal, switchModal, closeModal, modal } = useAppInfo({ balance });
  const { upViewAccess } = useAccessLevel({ accessLevel, fetchApps: false });

  return (
    <>
      {modal && (
        <Modal onClose={closeModal} withCloseIcon={false}>
          <RequestWithdrawal
            id={id}
            defaultCurrency={currencyId}
            onSubmit={refetch}
            onClose={closeModal}
          />
        </Modal>
      )}
      <div className="flex flex-col sm:flex-row justify-between items-start w-full my-48 p-32 border border-gray-300 rounded-12">
        <div className="flex flex-col">
          <span className="text-18 text-gray-500">Total balance</span>
          <span className="text-48 text-gray-700 mt-28">
            <AmountField amount={balance} currencyName={currency} />
          </span>
        </div>

        {upViewAccess && (
          <button
            className="bg-green hover:bg-green-active focus:bg-green-active disabled:bg-green disabled:cursor-default text-center text-16 text-white px-20 py-12 rounded focus:outline-none disabled:opacity-50 mt-28 sm:mt-0"
            disabled={disableWithdrawal}
            onClick={switchModal}
          >
            Request withdrawal
          </button>
        )}
      </div>
    </>
  );
};
export default AppInfo;
