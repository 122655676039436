import { useQuery } from 'react-query';
import { isValidArray } from 'utils/checkers';
import { getProjects } from 'api/projects';

function useProjectsPage() {
  const { data: projects, isLoading, refetch } = useQuery(['getProjects'], getProjects);

  const showProjectsList: boolean = isValidArray(projects);

  return {
    projects,
    isLoading,
    refetch,
    showProjectsList
  };
}

export default useProjectsPage;
