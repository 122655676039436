import React from 'react';
import AmountField from 'components/AmountField';
import { formatDate, FORMATS } from 'utils/formatters';
import { useFileUpload } from 'hooks/useFileUpload';
import ModalInnerWrapper from './components/ModalInnerWrapper';
import ModalItemList from './components/ModalItemList';
import ModalButtonDownload from './components/ModalButtonDownload';

export interface IStoreNetRevenueModalProps {
  amount: number;
  datetime: string;
  paymentMethodName?: string;
  currency: string;
  fee: number;
  feePercent: number;
  file: string | null;
  type?: string;
}

export const StoreNetRevenueModal: React.FC<IStoreNetRevenueModalProps> = ({
  amount,
  datetime,
  currency,
  fee,
  feePercent,
  file,
  type
}) => {
  const { downloadFile } = useFileUpload();

  const storeRevenueFields = [
    {
      name: 'Date',
      value: formatDate(datetime, FORMATS.global)
    },
    {
      name: 'Service Fee (%)',
      value: feePercent || 0
    },
    {
      name: 'Service Fee',
      value: <AmountField amount={fee || 0} currencyName={currency} />
    }
  ];

  return (
    <ModalInnerWrapper
      AmountField={<AmountField amount={amount} currencyName={currency} />}
      type={type}
    >
      {!!file && (
        <div className="flex items-center justify-center mb-56 -mt-28">
          <ModalButtonDownload
            onClick={() => downloadFile(file as string)}
            text="Payment confirmation"
          />
        </div>
      )}
      {storeRevenueFields.map(({ name, value }, index) => (
        <ModalItemList key={`store-net-${index}`} name={name} value={value} index={index} />
      ))}
    </ModalInnerWrapper>
  );
};
