import axios from 'utils/axios';
import { IRequestDTO } from 'interfaces/request';

export const getRequest = async (id: string): Promise<IRequestDTO> => {
  const response = await axios.get(`/api/application-requests/${id}/`);
  return response.data;
};

export const createRequest = async (data: Partial<IRequestDTO>): Promise<IRequestDTO> => {
  const response = await axios.post('/api/application-requests/', data);
  return response.data;
};

export const patchRequest = async (
  id: string | number,
  data: Partial<IRequestDTO>
): Promise<IRequestDTO> => {
  const response = await axios.patch(`/api/application-requests/${id}/`, data);
  return response.data;
};
