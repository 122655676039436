import React from 'react';
import AmountField from 'components/AmountField';
import { formatDate, FORMATS } from 'utils/formatters';
import ModalInnerWrapper from './components/ModalInnerWrapper';
import ModalItemList from './components/ModalItemList';

export interface IFixedModalProps {
  amount: number;
  datetime: string;
  currency: string;
  type?: string;
}

export const FixedModal: React.FC<IFixedModalProps> = ({ amount, datetime, currency, type }) => {
  const fixedFields = [
    {
      name: 'Date',
      value: formatDate(datetime, FORMATS.global)
    }
  ];

  return (
    <ModalInnerWrapper
      AmountField={<AmountField amount={amount} currencyName={currency} />}
      type={type}
    >
      {fixedFields.map(({ name, value }, index) => (
        <ModalItemList key={`fixed-${index}`} name={name} value={value} index={index} />
      ))}
    </ModalInnerWrapper>
  );
};
