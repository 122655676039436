import Container from 'components/Container';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg';

export interface INavPanelProps {
  path?: string;
  title?: string;
}

// By default link redirects to Home page

const NavPanel: React.FC<INavPanelProps> = ({ path, title = 'Back' }) => {
  const history = useHistory();

  const createPathLink = (): string => {
    let link = '';
    if (!path) link = '/';
    if ((history as any)?.location?.state?.from) link = (history as any).location.state.from;
    if (path) link = path;
    return link;
  };

  return (
    <div className="w-full bg-gray-100">
      <Container>
        <Link className="flex items-center h-48  text-gray-500" to={createPathLink()}>
          <ArrowLeft />
          <span className="ml-12 text-14">{title}</span>
        </Link>
      </Container>
    </div>
  );
};

export default NavPanel;
