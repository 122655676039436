import { BrowserRouter as Router, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useKeycloak } from '@react-keycloak/web';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Application from 'pages/Application';
import AddAccount from 'pages/AddAccount';
import RequestApp from 'pages/RequestApp';
import Projects from 'pages/Projects';
import Report from 'pages/Report';
import Withdrawals from 'pages/Withdrawals';
import WithdrawalRequest from 'pages/Withdrawals/Request';
import Permissions from 'pages/Permissions';
import PermissionsSet from 'pages/Permissions/PermissionsSet';
import History from 'pages/History';
import { AuthProvider } from 'hooks/useAuth';
import { LocaleProvider } from 'hooks/useLocale';
import ProtectedRoute from 'components/ProtectedRoute';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1
    }
  }
});

function App() {
  const { initialized } = useKeycloak();

  if (!initialized) return <></>;

  return (
    <>
      <div id="modal" className="fixed top-0 w-full z-20 d-none"></div>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <AuthProvider>
          <LocaleProvider>
            <IntlProvider locale="en" defaultLocale="en">
              <Router>
                <ProtectedRoute path="/" exact>
                  <Home />
                </ProtectedRoute>
                <Route path="/login">
                  <Login />
                </Route>
                <ProtectedRoute path="/projects">
                  <Projects />
                </ProtectedRoute>
                <ProtectedRoute path="/report">
                  <Report />
                </ProtectedRoute>
                <ProtectedRoute path="/withdrawals" exact>
                  <Withdrawals />
                </ProtectedRoute>
                <ProtectedRoute path="/history" exact>
                  <History />
                </ProtectedRoute>
                <ProtectedRoute path="/withdrawals/request" exact>
                  <WithdrawalRequest />
                </ProtectedRoute>
                <ProtectedRoute path="/applications/:id">
                  <Application />
                </ProtectedRoute>
                <ProtectedRoute path="/add/:id?">
                  <AddAccount />
                </ProtectedRoute>
                <ProtectedRoute path="/request/:id?">
                  <RequestApp />
                </ProtectedRoute>
                <ProtectedRoute path="/permissions" exact>
                  <Permissions />
                </ProtectedRoute>
                <ProtectedRoute path="/permissions/:id" exact>
                  <PermissionsSet />
                </ProtectedRoute>
              </Router>
            </IntlProvider>
          </LocaleProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
