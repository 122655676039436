import React from 'react';
import Header from 'components/Header';
import Container from 'components/Container';
import NavPanel from 'components/NavPanel';
import WithdrawalsHeading from './components/WithdrawalsHeading';
import RequestWithdrawalForm from './components/RequestWithdrawalForm';

const WithdrawalRequest: React.FC = () => (
  <>
    <Header />
    <NavPanel title="Withdrawals" path="/withdrawals" />
    <Container>
      <WithdrawalsHeading title="Withdrawal request" />
      <RequestWithdrawalForm />
      <div className="h-128" />
    </Container>
  </>
);

export default WithdrawalRequest;
