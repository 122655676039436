/**
 * fieldRequiredRule
 * @param  {any} value?
 * @returns string
 */

export const fieldRequiredRule = (value?: any): string | undefined =>
  value ? undefined : 'Field is required!';

/**
 * fieldIsEmail
 * @param  {any} value?
 * @returns string
 */

export const fieldIsEmail = (value?: any): string | undefined => {
  const regexp = /^.+@.+\..+$/;
  return regexp.test(value) ? undefined : 'Field must be an email';
};

/**
 * maxLength
 * @param  {number} max
 * @returns string
 */

export const maxLength = (max: number) => (value?: any): string | undefined =>
  !!value && value?.length > max ? `Maximum value length could be ${max} charts` : undefined;

/**
 * minLength
 * @param  {number} min
 * @returns string
 */

export const minLength = (min: number) => (value?: any): string | undefined =>
  !!value && value?.length < min ? `Please provide all required items` : undefined;

/**
 * isUrl
 * @param  {any} value?
 * @returns string
 */

export const isUrl = (value?: any): string | undefined => {
  const regexp = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  return regexp.test(value) ? undefined : 'Field must be a valid url';
};

export const composeValidators = (validators: Function[]) => (value: any) =>
  validators.reduce(
    (error: string | undefined, validator: Function) => error || validator(value),
    undefined
  );
