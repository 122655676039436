import axios from 'utils/axios';
import { isString, isArray, isValidArray } from 'utils/checkers';
import { formatFormData, formatArrayFormData } from 'utils/formatters';
import { downloadBlobFile } from 'utils/api';

export function useFileUpload() {
  const uploadFile = async (file?: null | string | File | File[], containerId?: string) => {
    if (isString(file)) return file;
    if (!file) return null;

    const formDataObj = isArray(file)
      ? formatArrayFormData(file, 'file')
      : formatFormData({ file });
    try {
      const url = `/manage/api/file/${containerId ? '?id=' + containerId : ''}`;
      const response = await axios.post(url, formDataObj, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data?.id;
    } catch (e) {
      return null;
    }
  };

  const getFile = async (id: string) => {
    try {
      const response = await axios.get('/manage/api/file/', {
        params: { id },
        responseType: 'blob'
      });
      return response.data;
    } catch (e) {
      return null;
    }
  };

  const getFileInfo = async (id: string) => {
    try {
      const response = await axios.get('/manage/api/file/info', {
        params: { id }
      });
      return isValidArray(response?.data) ? response.data[0] : response.data;
    } catch (e) {
      return null;
    }
  };

  const getMultiFileInfo = async (id: string) => {
    try {
      const response = await axios.get('/manage/api/file/info', {
        params: { id }
      });
      return response.data;
    } catch (e) {
      return null;
    }
  };

  const downloadFile = async (id: string) => {
    const info = await getFileInfo(id);
    const blob = await getFile(id);

    if (info?.filename && blob) downloadBlobFile(blob, info.filename);
  };

  const deleteFile = async (id: string) => {
    const response = await axios.delete('/manage/api/file', { params: { id } });
    if (response.status === 204) return id;
    return null;
  };

  return { uploadFile, getFile, getFileInfo, deleteFile, downloadFile, getMultiFileInfo };
}
