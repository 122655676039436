import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import axios from 'utils/axios';

const ProtectedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated, logout, updateCredentials } = useAuth();

  const successStatuses = [200, 201];

  axios.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 401) {
      logout();
      return;
    }
    if (error?.response?.status === 403) {
      updateCredentials();
      return;
    }

    if (!successStatuses.includes(error?.response?.status))
      throw new Error(error?.response?.message);
    return error;
  });

  const { pathname } = useLocation();

  if (!isAuthenticated)
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: pathname || '/' }
        }}
      />
    );

  return <Route {...rest} render={() => children} />;
};

export default ProtectedRoute;
