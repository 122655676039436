import React, { useState } from 'react';
import axios from 'utils/axios';
import { useMutation } from 'react-query';
import { Form } from 'react-final-form';
import { isFunction } from 'utils/checkers';
import { useFileUpload } from 'hooks/useFileUpload';
import CurrencyField from 'components/CurrencyField';
import FileInput from 'components/FileInput';
import ErrorLabel from 'components/ErrorLabel';
import { fieldRequiredRule } from 'validation';
import ModalInnerWithdrawal from './components/ModalInnerWithdrawal';

interface AddESRProps {
  id: string;
  esrId: number;
  onClose?: () => void;
  onSubmit?: Function;
}

export const AddESR: React.FC<AddESRProps> = ({ id, esrId, onClose, onSubmit }) => {
  const [error, setError] = useState<string>('');
  const { uploadFile } = useFileUpload();

  const submitForm = (data: any) => addESR(data);

  const submitESR = async (fields: any) => {
    const { file, ...items } = fields;

    const fileUUID = await uploadFile(file);

    const response = await axios.patch(`/api/store_revenues/${esrId}/`, {
      file: fileUUID,
      applicationId: id,
      ...items
    });
    return response;
  };

  const { mutate: addESR } = useMutation(submitESR, {
    onSuccess: () => {
      if (onSubmit && isFunction(onSubmit)) onSubmit();
      if (onClose && isFunction(onClose)) onClose();
    },
    onError: () => setError('Request error. Please try again later')
  });

  return (
    <ModalInnerWithdrawal>
      <Form
        onSubmit={submitForm}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="w-full mb-16 ">
              <CurrencyField
                name="amount"
                label="Amount"
                placeholder="€"
                prefix="€"
                validate={fieldRequiredRule}
              />
            </div>
            <div className="w-full mb-16 ">
              <FileInput
                accept="application/pdf, image/*"
                name="file"
                label="File"
                validate={fieldRequiredRule}
              />
            </div>
            <button
              type="submit"
              className="w-full mt-24 py-14 bg-green text-white text-18 focus:outline-none disabled:opacity-50"
              disabled={submitting}
            >
              Submit amount
            </button>
            {error && <ErrorLabel error={error} />}
          </form>
        )}
      />
    </ModalInnerWithdrawal>
  );
};
