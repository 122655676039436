import { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormState, useForm } from 'react-final-form';
import { useFileUpload } from 'hooks/useFileUpload';
import { previewBlob } from 'utils/api';
import { isString, isFileLink, isValidArray } from 'utils/checkers';

type useFileUploadProps = {
  name: string;
  accept: string;
};

function useFileUploadField({ name, accept }: useFileUploadProps) {
  const { values } = useFormState();
  const { change } = useForm();
  const { getFile, getFileInfo } = useFileUpload();

  const [previewFile, setPreviewFile] = useState<any>(undefined);
  const file: File | string = values[name];

  const onDrop = useCallback(
    (droppedFiles) => {
      if (droppedFiles?.length > 0) {
        change(name, droppedFiles[0]);
      }
    },
    [change, name]
  );

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: accept,
    multiple: false,
    disabled: !!file
  });

  const clearFileField = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
    change(name, null);
  };

  const innerZIndex = !!file ? undefined : -1;

  const prepareFileForView = useCallback(async () => {
    const backendFile = isString(file);
    let fileInfo: string;
    let fileURL: null | string | undefined = null;

    if (backendFile) {
      const info = await getFileInfo(file as string);
      fileInfo = isValidArray(info) ? info[0]?.filename : info?.filename || '';
    } else {
      fileInfo = (file as File).name as string;
    }

    const { fileName, fileExtension } = isFileLink(fileInfo as string) as any;
    const isPDF = fileExtension === 'pdf';

    if (backendFile) {
      const blob = await getFile(file as string);
      fileURL = await previewBlob(blob);
    } else {
      if (!isPDF) {
        fileURL = URL.createObjectURL(file);
      }
    }

    setPreviewFile({
      file,
      fileURL,
      fileExtension,
      fileName,
      isPDF
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (!file) setPreviewFile(undefined);
    if (!!file) prepareFileForView();
  }, [file, prepareFileForView]);

  return {
    file,
    previewFile,
    onDrop,
    innerZIndex,
    clearFileField,
    getRootProps,
    getInputProps
  };
}

export default useFileUploadField;
