import React from 'react';
import Skeleton from 'react-loading-skeleton';

import Container from 'components/Container';
import Header from 'components/Header';
import NavPanel from 'components/NavPanel';

import useRequestForm from 'pages/RequestApp/hooks/useRequestForm';

import { steps } from './const/steps';

import WizardLine from './components/WizardLine';
import Form from './components/Form';

const RequestApp: React.FC = () => {
  const { request, title, subtitle, step, showForm } = useRequestForm();

  return (
    <>
      <Header />
      <NavPanel />
      <Container>
        <WizardLine active={step} steps={steps} />
        {showForm ? (
          <Form title={title} subtitle={subtitle} step={step} initialValues={request || {}} />
        ) : (
          <div className="my-24">
            <Skeleton count={5} />
          </div>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default RequestApp;
