import React from 'react';

export type ErrorLabelProps = {
  error: string | JSX.Element;
};

const ErrorLabel: React.FC<ErrorLabelProps> = ({ error }) => {
  return (
    <div className="flex flex-col w-full max-w-xs mx-auto mt-20 py-10 px-10 rounded-6 bg-red text-white">
      <p className="text-12 text-center leading-16">{error}</p>
    </div>
  );
};

export default ErrorLabel;
