import React from 'react';
import Heading from 'components/Heading';

const HistoryHeading: React.FC = () => {
  return (
    <Heading level={1} className="my-64">
      <div className="flex items-center justify-center">History log</div>
    </Heading>
  );
};

export default HistoryHeading;
