import ClientSelectModalView from './components/ClientSelectModalView';
import useClientSelectModal from './hooks/useClientSelectModal';

const ClientSelectModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { clients, onClientChange } = useClientSelectModal({ onClose });

  if (!clients) {
    onClose();
    return null;
  }

  return (
    <ClientSelectModalView clients={clients} onClose={onClose} onClientChange={onClientChange} />
  );
};

export default ClientSelectModal;
