import cn from 'classnames';

export type LoadMoreProps = {
  onClick: () => void;
  disabled?: boolean;
  blueTone?: boolean;
};
const LoadMore: React.FC<LoadMoreProps> = ({ onClick, disabled = false, blueTone = false }) => {
  return (
    <button
      className={cn(
        'w-full mt-80 py-14 text-center text-18 rounded-2 hover:bg-gray-200 disabled:opacity-50 disabled:cursor-default focus:outline-none',
        {
          'text-blue-active bg-blue-extralight': blueTone,
          'text-gray-500 bg-gray-100': !blueTone
        }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      Load more
    </button>
  );
};

export default LoadMore;
