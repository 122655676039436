// import cn from 'classnames';
// import { downloadFile } from 'utils/api';
import { fieldRequiredRule } from 'validation/rules';
import FileInput from 'components/FileInput';
// import { ReactComponent as Download } from 'assets/images/download.svg';

type SignContractProps = {};

const SignContract: React.FC<SignContractProps> = () => {
  // const rows = [
  //   {
  //     id: 1,
  //     value: 'Download contract',
  //     icon: <Download />,
  //     action: () => downloadFile('http://www.orimi.com/pdf-test.pdf', 'contract.pdf')
  //   },
  //   {
  //     id: 2,
  //     value: 'Read your contract and sign it'
  //   },
  //   {
  //     id: 3,
  //     value: 'Upload signed contract in PDF'
  //   }
  // ];

  // const InfoRows = () => {
  //   return (
  //     <>
  //       {rows.map(({ id, value, action, icon }) => {
  //         return (
  //           <div
  //             key={id}
  //             className={cn('flex items-center text-18', {
  //               'mb-32': id !== rows.length
  //             })}
  //           >
  //             <span className="text-gray-300 mr-16">{id}.</span>
  //             <span className="flex items-center" onClick={action}>
  //               <span
  //                 className={cn('text-gray-500', {
  //                   'font-medium cursor-pointer border-b-2 border-dashed border-gray-500': action
  //                 })}
  //               >
  //                 {value}
  //               </span>
  //               {icon && <span className="ml-12 cursor-pointer">{icon}</span>}
  //             </span>
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // };

  return (
    <>
      {/* <div className="flex flex-col py-56 px-56 mb-40 -mx-56 rounded-12 bg-gray-100">
        {InfoRows()}
      </div> */}
      <div className="mb-28">
        <FileInput
          accept="application/pdf"
          name="signedContractFile"
          label="Signed contract file"
          validate={fieldRequiredRule}
        />
      </div>
    </>
  );
};

export default SignContract;
