import React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { findInArray } from 'utils/getters';
import { generateYears } from '../utils/generate';
import { ReactComponent as Decrease } from '../assets/previous.svg';
import { ReactComponent as Increase } from '../assets/next.svg';

interface IYearSwitcherProps {
  years?: number[];
  changeYear: (year: number) => void;
  date: Date;
  small?: boolean;
}

const YearSwitcher: React.FC<IYearSwitcherProps> = ({
  changeYear,
  date,
  years = generateYears(),
  small = true
}) => {
  const year = dayjs(date).format('YYYY');

  const checkYear = (y: number): boolean => years.includes(y);

  const decreaseYear = () => changeYear(Number(year) - 1);
  const increaseYear = () => changeYear(Number(year) + 1);

  const getYear = (): number | undefined => findInArray(years, (x) => x === Number(year));

  return (
    <div className="flex items-center justify-center mb-20">
      <button
        className="previous-year cursor-pointer disabled:opacity-50 disabled:cursor-default"
        onClick={decreaseYear}
        disabled={!checkYear(Number(year) - 1)}
      >
        <Decrease />
      </button>
      <span className={cn('mx-32', { 'text-36': !small, 'text-24': small })}>{getYear()}</span>
      <button
        className="next-year cursor-pointer disabled:opacity-50 disabled:cursor-default"
        onClick={increaseYear}
        disabled={!checkYear(Number(year) + 1)}
      >
        <Increase />
      </button>
    </div>
  );
};

export default YearSwitcher;
