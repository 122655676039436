import React from 'react';
import ErrorFieldString from 'components/ErrorFieldString';
import { Field } from 'react-final-form';
import SelectComponent from 'react-select';
import { customStyles } from './external';

export type SelectProps = {
  name: string;
  label: string;
  validate?: any;
  options: any[];
  initialValue?: number | string;
  isSearchable?: boolean;
  isClearable?: boolean;
  placeholder?: string | React.ReactNode;
};

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input:
    'focus:gray-500 w-full h-48 border rounded text-gray-500 focus:outline-none disabled:bg-gray-100 disabled:text-gray-400',
  inputOk: 'border-gray-300 focus:border-green disabled:border-transparent',
  inputError: 'border-red'
};

const Select: React.FC<SelectProps> = ({
  name,
  label,
  validate,
  options,
  initialValue = '',
  isSearchable,
  isClearable,
  ...props
}) => {
  const labelClasses = (isError: boolean) =>
    classes.label + ' ' + (isError ? classes.labelError : classes.labelOk);
  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : classes.inputOk);

  const selectOptions = options?.map(({ id, name }) => {
    return { value: id, label: name };
  });

  const ReactSelectAdapter = ({ input, meta, ...rest }: any) => {
    const isError = meta.error && (meta.dirty || meta.touched);

    return (
      <>
        {label && <label className={labelClasses(isError)}>{label}</label>}
        <SelectComponent
          {...input}
          {...rest}
          isSearchable={isSearchable}
          isClearable={isClearable}
          maxMenuHeight={145}
          className={inputClasses(isError)}
          styles={customStyles}
          classNamePrefix="rootSelector"
        />
        {isError && <ErrorFieldString error={meta.error} />}
      </>
    );
  };

  return (
    <Field
      name={name}
      validate={validate}
      initialValue={initialValue}
      component={ReactSelectAdapter}
      options={selectOptions}
      parse={(data) => (data ? data.value : null)}
      format={(value) => (value ? selectOptions?.find((opt) => opt?.value === value) : null)}
      allowNull
      {...props}
    />
  );
};

export default Select;
