import { saveAs } from 'file-saver';
import axios from './axios';
import { API_MEDIA_URL } from 'const/url';

/**
 * createDownloadURL
 * @param  {string} file
 * @returns Promise
 */

export const createDownloadURL = async (file: string): Promise<string | undefined> => {
  if (!file) return;
  const response = await axios.get(`${API_MEDIA_URL}${file}`, { responseType: 'blob' });
  return URL.createObjectURL(response?.data);
};

/**
 * downloadFile
 * @param  {string} url
 * @param  {string} name
 */

export const downloadFile = (url: string, name: string) => {
  let a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', name);
  a.click();
};

/**
 * previewBlob
 * @param  {Blob} blob
 * @returns Promise
 */

export const previewBlob = async (blob: Blob): Promise<string | undefined> => {
  if (!blob) return;
  return URL.createObjectURL(blob);
};

/**
 * downloadBlobFile
 * @param  {Blob} blob
 * @param  {string} filename
 */

export const downloadBlobFile = async (blob: Blob, filename: string) => saveAs(blob, filename);
