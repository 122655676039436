import React from 'react';
import { Link } from 'react-router-dom';
import useAccessLevel from 'hooks/useAccessLevel';
import AmountField from 'components/AmountField';

interface IWithdrawalsInfoProps {
  balance: number;
}

const WithdrawalsInfo: React.FC<IWithdrawalsInfoProps> = ({ balance }) => {
  const currencyName = 'EUR';

  const { withdrawalAccess, isOwner } = useAccessLevel();
  const hasAccess: boolean = isOwner || withdrawalAccess;

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start w-full my-48 p-32 border border-gray-300 rounded-12">
      <div className="flex flex-col">
        <span className="text-18 text-gray-500">Total balance</span>
        <span className="text-48 text-gray-700 mt-28">
          <AmountField amount={balance} currencyName={currencyName} />
        </span>
      </div>
      {hasAccess && (
        <Link
          to="/withdrawals/request"
          className="bg-green hover:bg-green-active focus:bg-green-active text-center text-16 text-white px-20 py-12 rounded focus:outline-none disabled:opacity-50 mt-28 sm:mt-0"
        >
          Request withdrawal
        </Link>
      )}
    </div>
  );
};
export default WithdrawalsInfo;
