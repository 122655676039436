import { useAuth } from './useAuth';
import { IApplicationDTO } from 'interfaces/application';
import { useQuery } from 'react-query';
import { isValidArray } from 'utils/checkers';
import { getApplications } from 'api/applications';

interface IUseAccessLevelProps {
  accessLevel?: string;
  fetchApps?: boolean;
}

function useAccessLevel(
  { accessLevel, fetchApps }: IUseAccessLevelProps = { fetchApps: true }
): {
  upViewAccess: boolean;
  withdrawalAccess: boolean;
  isOwner: boolean;
} {
  const { isOwner } = useAuth();

  const { data: applications } = useQuery<IApplicationDTO[]>(
    ['getApplications'],
    () => getApplications({ selector: true }),
    { enabled: fetchApps }
  );

  const upViewAccess: boolean =
    Boolean(accessLevel && accessLevel === 'submit_withdrawal') || isOwner;

  const withdrawalAccess: boolean = isValidArray(applications)
    ? Boolean(applications?.map((app) => app.accessLevel).includes('submit_withdrawal'))
    : false;

  return { upViewAccess, withdrawalAccess, isOwner };
}

export default useAccessLevel;
