import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Container from 'components/Container';
import Header from 'components/Header';
import NoResults from 'components/NoResults';
import ProjectHeading from './components/ProjectHeading';
import ProjectsList from './components/ProjectsList';
import useProjectsPage from './hooks/useProjectsPage';

const Projects: React.FC = () => {
  const { projects, isLoading, refetch, showProjectsList } = useProjectsPage();

  return (
    <>
      <Header />
      <Container>
        <ProjectHeading refetch={refetch} />
        {isLoading && <Skeleton count={5} />}
        {!isLoading && (
          <>
            {showProjectsList && <ProjectsList projects={projects} refetch={refetch} />}
            {!showProjectsList && <NoResults message="No projects" />}
          </>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default Projects;
