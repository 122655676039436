import React from 'react';
import NoResults from 'components/NoResults';
import { OperationsListRow, IOperationsListRow } from './OperationsListRow';
import useOperationList from './hooks/useOperationsList';

export interface IAppsList {
  tab: string;
  appId: string;
  currency: string;
  balance: number;
  refetch: Function;
}

const OperationsList: React.FC<IAppsList> = ({ currency, tab, appId, balance, refetch }) => {
  const { refetch: refetchList, showList, emptyData, operations } = useOperationList({
    tab,
    appId,
    balance
  });

  const OperationslistComponent = () =>
    operations.map((operation: IOperationsListRow, index: number) => (
      <OperationsListRow
        key={`operation-${operation.id}-${index}`}
        {...operation}
        currency={currency}
        refetchList={refetchList}
        refetch={refetch}
      />
    ));

  return (
    <>
      {showList && <OperationslistComponent />}
      {emptyData && <NoResults message="No operations was found" />}
    </>
  );
};

export default OperationsList;
