import { findInArray } from 'utils/getters';
import { isEmpty } from 'utils/checkers';
import { IChangedField } from 'interfaces/logs';
import { models } from '../const/models';

const emptyOptions = ['', 'None', null, undefined, [], {}];

export const filterChangedFields = (fields: IChangedField[]): IChangedField[] => {
  return fields?.filter((field: IChangedField) => {
    const wasEmpty = emptyOptions.includes(field.before);
    const nowEmpty = emptyOptions.includes(field.after);
    const nonEqual = field.after !== field.before;

    return (
      (wasEmpty && !nowEmpty) || (!wasEmpty && nowEmpty) || (!wasEmpty && !nowEmpty && nonEqual)
    );
  });
};

export const isFieldEmpty = (field: IChangedField): boolean =>
  isEmpty(field) || emptyOptions.includes(field);

export const getFieldByKey = (changes: IChangedField[], field: string) =>
  findInArray(changes as any[], (x: any) => x?.field === field);

export const filterFieldsByModel = (
  fields: IChangedField[],
  model: string
): IChangedField[] | [] => {
  let allowedFields: string[] = [];

  if (model === models.application) {
    allowedFields = ['rc', 'project', 'request_submitted'];
  }
  if (model === models.withdrawal) {
    allowedFields = ['amount'];
  }
  if (model === models.project) {
    allowedFields = ['name'];
  }

  return fields?.filter((obj: IChangedField) => allowedFields.includes(obj.field));
};
