import { useQueryClient, useMutation } from 'react-query';
import axios from 'utils/axios';

export interface IPostClientSetRequest {
  clientId: number;
}

export const postClientSet = async (request: IPostClientSetRequest) => {
  return (await axios.put('/api/session/clients/', request)).data;
};

export const usePostClientSetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(postClientSet, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });
};
