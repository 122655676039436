import { useState } from 'react';

function useAppInfo({ balance }: { balance: number }) {
  const [modal, showModal] = useState<boolean>(false);
  const disableWithdrawal: boolean = !(balance > 0);
  const switchModal = () => (!disableWithdrawal ? showModal(true) : undefined);
  const closeModal = () => showModal(false);

  return {
    disableWithdrawal,
    switchModal,
    closeModal,
    modal
  };
}

export default useAppInfo;
