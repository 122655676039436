import React from 'react';
import { formatDate, FORMATS } from 'utils/formatters';
import ActionPhrase from './ActionPhrase';
import { formatUser } from '../../utils/format';
import { ILogDTO } from 'interfaces/logs';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as LoginIcon } from '../../assets/login.svg';
import { ReactComponent as WithdrawalIcon } from '../../assets/withdrawal.svg';

interface IHistoryTableRowProps {
  row: ILogDTO;
}

const HistoryTableRow: React.FC<IHistoryTableRowProps> = ({ row }) => {
  const RowIcon = () => (
    <>
      {(row.action === 'update' || row.action === 'delete') && <EditIcon />}
      {row.action === 'create' && <WithdrawalIcon />}
      {row.action === 'login' && <LoginIcon />}
    </>
  );

  return (
    <div className="flex items-center justify-between py-20 border-b">
      <div className="flex items-center">
        <div className="w-44 h-44 mr-20">
          <RowIcon />
        </div>
        <div className="flex flex-col">
          <div className="mb-6 text-12 text-gray-400">{formatUser(row.actor)}</div>
          <div className="text-16 text-gray-700">
            <ActionPhrase row={row} />
          </div>
        </div>
      </div>
      <div className="text-12 text-gray-400">
        {formatDate(row.timestamp, FORMATS.logs, { toCyprus: true })}
      </div>
    </div>
  );
};

export default HistoryTableRow;
