// FIXME: Comment draft contact download button (https://idkfa.atlassian.net/browse/UNS-534)

// import { downloadFile } from 'utils/api';
import { ReactComponent as Success } from 'assets/images/success.svg';
// import { ReactComponent as Info } from 'assets/images/info.svg';

type SuccessScreenProps = {
  step: number;
};

const SuccessScreen = ({ step }: SuccessScreenProps) => {
  const getTitle = () => {
    if (step === 1) return 'Thank you for applying';
    if (step === 2) return 'Thank you for filling out the form';
    if (step === 3) return 'Thank you for signing the contract';
    return '';
  };

  const getSubtitle = () => {
    if (step === 1) return 'We’ll verify you info and notify when we’re done.';
    if (step === 2)
      return 'You will receive an email when we are ready to provide you a contract and proceed to the next step.';
    if (step === 3) return 'You will receive an email with results.';
    return '';
  };

  // const DraftContractButton = () => {
  //   const download = () => downloadFile('http://www.orimi.com/pdf-test.pdf', 'contract.pdf');
  //   return (
  //     <button
  //       className="flex justify-center items-center bg-gray-100 text-16 px-14 py-10 mt-80 text-gray-500 rounded-2 focus:outline-none"
  //       onClick={download}
  //     >
  //       <Info />
  //       <span className="pl-10">See the Draft Contract</span>
  //     </button>
  //   );
  // };

  return (
    <div className="flex flex-col justify-center items-center text-center w-full sm:py-112">
      <Success />
      <span className="mb-20 mt-40 text-36 text-gray-500">{getTitle()}</span>
      <p className="text-14 text-gray-500">{getSubtitle()}</p>
      {/* {step === 1 && <DraftContractButton />} */}
    </div>
  );
};

export default SuccessScreen;
