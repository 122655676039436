import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Header from 'components/Header';
import Container from 'components/Container';
import NavPanel from 'components/NavPanel';
import NoResults from 'components/NoResults';
import LoadMore from 'components/LoadMore';
import WithdrawalsInfo from './components/WithdrawalsInfo';
import WithdrawalsHeading from './components/WithdrawalsHeading';
import WithdrawalsList from './components/WithdrawalsList';
import useWithdrawalPage from './hooks/useWithdrawalPage';

const Withdrawals: React.FC = () => {
  const {
    balance,
    infiniteData,
    showInfiniteWithdrawals,
    showMoreButton,
    disableLoadMore,
    isLoading,
    fetchNextPage
  } = useWithdrawalPage();

  return (
    <>
      <Header />
      <NavPanel title="Apps" />
      <Container>
        <WithdrawalsHeading title="Withdrawals" />
        {isLoading && <Skeleton count={5} />}
        {!isLoading && (
          <>
            <WithdrawalsInfo balance={balance!} />
            {showInfiniteWithdrawals && <WithdrawalsList withdrawals={infiniteData} />}
            {!showInfiniteWithdrawals && <NoResults message="No withdrawals" />}
            {showMoreButton && <LoadMore onClick={fetchNextPage} disabled={disableLoadMore} />}
          </>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default Withdrawals;
