import React from 'react';
import { Field } from 'react-final-form';
import cn from 'classnames';
import ErrorFieldString from 'components/ErrorFieldString';

export type TextAreaProps = {
  name: string;
  label: string;
  placeholder?: string;
  className?: string;
  validate?: any;
  initialValue?: string | number;
  resizable?: boolean;
};

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input:
    'focus:gray-500 block w-full h-112 border rounded py-4 px-20 text-gray-500 focus:outline-none disabled:bg-gray-100 disabled:text-gray-400',
  inputOk: 'border-gray-300 focus:border-green disabled:border-transparent',
  inputError: 'border-red'
};

const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  placeholder,
  initialValue,
  validate,
  className = '',
  resizable = false,
  ...props
}) => {
  const labelClasses = (isError: boolean) =>
    classes.label + ' ' + (isError ? classes.labelError : classes.labelOk);
  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : classes.inputOk);

  return (
    <Field
      {...props}
      name={name}
      validate={validate}
      initialValue={initialValue}
      parse={(data) => data || ''}
      format={(value) => value || ''}
    >
      {({ input, meta }) => {
        const isError = meta.error && (meta.dirty || meta.touched);

        return (
          <>
            {label && (
              <label className={labelClasses(isError)} htmlFor={name}>
                {label}
              </label>
            )}
            <textarea
              className={cn(inputClasses(isError), className, { 'resize-none': !resizable })}
              placeholder={placeholder}
              value={input.value}
              onChange={input.onChange}
            />
            {isError && <ErrorFieldString error={meta.error} />}
          </>
        );
      }}
    </Field>
  );
};

export default TextArea;
