import React from 'react';
import { Form } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';
import ErrorLabel from 'components/ErrorLabel';
import Select from 'components/redesign/Select';
import CurrencyInput from 'components/redesign/CurrencyInput';
import FileInput from 'components/redesign/FileInput';
import TextArea from 'components/redesign/TextArea';
import Button from 'components/redesign/Button';
import AmountField from 'components/AmountField';
import { IRequestWithdarwalProps } from 'interfaces/withdrawal';
import { fieldRequiredRule } from 'validation/rules';
import useRequestWithdrawal from './hooks/useRequestWithdrawal';

import Arrow from 'components/redesign/Icons';

export const RequestWithdrawal: React.FC<IRequestWithdarwalProps> = ({ ...props }) => {
  const {
    error,
    submitForm,
    isLoading,
    validateAmount,
    paymentMethods,
    currencyPrefix
  } = useRequestWithdrawal({
    ...props
  });

  return (
    <div className="flex flex-col w-full">
      {isLoading && (
        <div className="my-24">
          <Skeleton count={3} />
        </div>
      )}
      {!isLoading && (
        <>
          {!!paymentMethods?.length ? (
            <Form
              onSubmit={submitForm}
              initialValues={{
                status: 'new',
                paymentMethod: !!paymentMethods?.length ? paymentMethods[0].id : undefined
              }}
              render={({ handleSubmit, valid, submitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="w-full my-16">
                      <CurrencyInput
                        name="amount"
                        label="Amount"
                        prefix={currencyPrefix}
                        maxValue={validateAmount}
                        validate={fieldRequiredRule}
                        autofocus
                      />
                    </div>
                    <div className="text-16 mb-32 text-black text-center">
                      Available —{' '}
                      <AmountField amount={validateAmount} currencyName={currencyPrefix} />
                    </div>
                    <div className="w-full mb-16">
                      <FileInput
                        accept="application/pdf, image/*"
                        name="file"
                        label="File"
                        validate={fieldRequiredRule}
                      />
                    </div>
                    {paymentMethods && (
                      <div className="w-full mb-16 ">
                        <Select
                          name="paymentMethod"
                          label="Payment method"
                          options={paymentMethods}
                          validate={fieldRequiredRule}
                        />
                      </div>
                    )}
                    <div className="w-full mb-16">
                      <TextArea name="clientComment" placeholder="Comment" />
                    </div>

                    <Button
                      className="w-208 mb-48"
                      type="submit"
                      disabled={!valid || submitting}
                      icon={<Arrow fill={!valid || submitting ? '#9AA5AD' : '#FFFFFF'} />}
                      label="Request"
                      outlined
                    />
                    {error && <ErrorLabel error={error} />}
                  </form>
                );
              }}
            />
          ) : (
            <ErrorLabel error="Payment methods not found" />
          )}
        </>
      )}
    </div>
  );
};
