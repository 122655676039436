import { forwardRef } from 'react';
import * as DatePickerDefault from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import './styles.css';

interface IDatePickerProps extends DatePickerDefault.ReactDatePickerProps {
  wrapperClassname?: string;
  headerChild: JSX.Element | JSX.Element[];
  onDoneClick: () => void;
}

const DatePicker = forwardRef((props: IDatePickerProps, ref?: any) => {
  const { headerChild, wrapperClassname = '', onDoneClick, ...pickerProps } = props;

  const Picker = DatePickerDefault.default;

  DatePickerDefault.registerLocale('en', en);

  return (
    <div
      className={`${wrapperClassname} flex flex-col px-8 pt-16 pb-8 box-border rounded-24 bg-gray-100 border`}
      ref={ref as any}
    >
      {headerChild && <div className="mb-16">{headerChild}</div>}
      <Picker
        {...pickerProps}
        locale="en"
        disabledKeyboardNavigation
        calendarClassName="customPicker"
        children={
          onDoneClick && (
            <div className="w-full py-32 px-24">
              <button
                onClick={onDoneClick}
                className="w-full mt-20 py-16 text-center text-20 rounded-8 text-violet bg-gray-100 focus:outline-none"
              >
                Done
              </button>
            </div>
          )
        }
      />
    </div>
  );
});

export default DatePicker;
