import cn from 'classnames';
import Heading from 'components/Heading';
import { ReactComponent as Close } from './assets/close.svg';
import React, { useEffect } from 'react';

export type ModalRedesignProps = {
  customTitle?: JSX.Element | Element;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  onClose: () => void;
  detailView?: boolean;
};

const ModalRedesign: React.FC<ModalRedesignProps> = ({
  title,
  customTitle,
  subtitle,
  children,
  onClose,
  detailView = false
}) => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
      return;
    };
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-black bg-opacity-50" />
        </div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          className={cn(
            'inline-block align-bottom rounded-16 bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-32 sm:align-middle w-full',
            {
              'md:max-w-xl': !detailView,
              'md:max-w-3xl': detailView
            }
          )}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className={cn('relative px-40', {
              'py-48 md:px-56': detailView,
              'py-32': !detailView
            })}
          >
            <span
              className="absolute cursor-pointer top-20 right-20 text-gray-300 hover:text-gray-400"
              onClick={onClose}
            >
              <Close />
            </span>
            {title && (
              <Heading level={4} className="mb-20 text-center" color="text-gray-500">
                {title}
              </Heading>
            )}
            {customTitle && <>{customTitle}</>}
            {!!subtitle && <>{subtitle}</>}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRedesign;
