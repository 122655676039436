import React from 'react';
import DefaultPlaceholder from './DefaultPlaceholder';
import { ReactComponent as Upload } from 'assets/images/upload.svg';

type NoFilesBlockProps = {
  placeholder?: string | JSX.Element;
};

const NofilesBlock: React.FC<NoFilesBlockProps> = ({ placeholder }) => (
  <div className="flex flex-col items-center justify-center text-center my-4 py-8">
    <span className="block mb-20">
      <Upload />
    </span>
    {placeholder && <p>{placeholder}</p>}
    {!placeholder && <DefaultPlaceholder />}
  </div>
);

export default NofilesBlock;
