import React from 'react';
import { Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { IUserResponse } from 'interfaces/user';
import Header from 'components/Header';
import Heading from 'components/Heading';
import Container from 'components/Container';
import NavPanel from 'components/NavPanel';
import NoResults from 'components/NoResults';
import PermissionsForm from './components/PermissionsForm';
import { getUserName } from './utils/user';
import usePermissionsForm from './hooks/usePermissionsForm';

const PermissionsSet: React.FC = () => {
  const { user, isOwner, isLoading, showPermissions } = usePermissionsForm();

  if (!isLoading && !isOwner) return <Redirect to="/" />;

  return (
    <>
      <Header />
      <NavPanel title="Permissions" path="/permissions" />
      <Container>
        <Heading level={1} className="my-64">
          <>Permissions{!!user ? ` for ${getUserName(user as IUserResponse)}` : ''}</>
        </Heading>
        {isLoading && <Skeleton count={5} />}
        {!isLoading && (
          <>
            {showPermissions && <PermissionsForm />}
            {!showPermissions && <NoResults message="No apps for permissions set" />}
          </>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default PermissionsSet;
