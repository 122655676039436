import constate from 'constate';
import { useQuery, useQueryClient } from 'react-query';
import { getUser, getUserClients, getUsers } from 'api/getUser';
import { IPostLoginRequest, postLogin } from 'api/postLogin';

import { useKeycloak } from '@react-keycloak/web';
import { useState, useEffect } from 'react';
import { setAxiosToken } from 'utils/axios';
import { isArray } from 'utils/checkers';

const LOCALSTORAGE_TOKEN_KEY = 'apiToken';

const initialToken = getLocalStorageToken();

const [AuthProvider, useAuth] = constate(() => {
  const queryClient = useQueryClient();
  const [, setToken] = useState<any | undefined>(initialToken);
  const { keycloak } = useKeycloak();

  const isAuthenticated = Boolean(keycloak?.authenticated);

  useEffect(() => setAxiosToken(keycloak?.token), [keycloak?.token]);

  const { data: user, isLoading: userLoading } = useQuery('getUser', getUser, {
    enabled: isAuthenticated
  });

  const client = user?.currentClient;

  const { data: clients, isLoading: clientsLoading } = useQuery('getUserClients', getUserClients, {
    enabled: isAuthenticated
  });

  const { data: users, isLoading: usersLoading } = useQuery('getUsers', getUsers, {
    enabled: isAuthenticated && !!client
  });

  const isOwner: boolean = user?.currentClient?.role === 'owner';

  const manyClientUsers: boolean = isArray(users) && users.length > 1;

  async function login(credentials: IPostLoginRequest) {
    queryClient.clear();
    const { token: newToken } = await postLogin(credentials);
    setToken(newToken);
  }

  function logout() {
    setAxiosToken(undefined);
    keycloak.logout();
    setToken(undefined);
    queryClient.clear();
  }

  function updateCredentials() {
    keycloak.updateToken(50);
    queryClient.clear();
  }

  const authLoading: boolean = userLoading || clientsLoading || usersLoading;

  return {
    isAuthenticated,
    authLoading,
    user,
    client,
    clients,
    users,
    login,
    logout,
    updateCredentials,
    isOwner,
    manyClientUsers
  };
});

function getLocalStorageToken() {
  return window.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY) || undefined;
}

export { AuthProvider, useAuth };
