import React from 'react';

interface IModalItemListProps {
  onClick: () => void;
  text: string;
}

const ModalButtonDownload: React.FC<IModalItemListProps> = ({ onClick, text }) => {
  return (
    <button
      className="font-normal text-18 text-green underline focus:outline-none"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default ModalButtonDownload;
