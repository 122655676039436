import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { changeApplication, createApplication } from 'api/applications';
import { useFileUpload } from 'hooks/useFileUpload';
import ErrorLabel from 'components/ErrorLabel';
import { appStatuses } from 'const/appStatuses';
import { IApplicationDTO } from 'interfaces/application';
import AppDetails from './steps/AppDetails';
import AccountToStore from './steps/AccountToStore';
import SignContract from './steps/SignContract';
import SuccessScreen from './steps/SuccessScreen';

interface IAccountFormProps {
  title: string;
  subtitle?: string;
  step: number;
  initialValues?: Partial<IApplicationDTO>;
  successScreen: boolean;
  isRejected: boolean;
  app?: Partial<IApplicationDTO>;
}

const AccountForm: React.FC<IAccountFormProps> = ({
  title,
  subtitle = null,
  step,
  initialValues = {},
  successScreen,
  isRejected,
  app = {}
}) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { uploadFile } = useFileUpload();

  const addApplication = async (data: Partial<IApplicationDTO>) => {
    const fields = { ...data, requestSubmitted: true };
    const updateApp = Boolean(app?.id);
    const apiMethod = updateApp
      ? changeApplication(app.id as string, fields)
      : createApplication(fields);

    return await apiMethod;
  };

  const status = useMemo((): string | undefined => {
    if (step === 1) return appStatuses.appSet;
    if (step === 2) return appStatuses.bankDetailsSet;
    if (step === 3) return appStatuses.contractSet;
  }, [step]);

  const patchSteps = async (data: Partial<IApplicationDTO>) => {
    const { appstoreScreenshotFile, signedContractFile, appstoreDate } = data as any;

    let fields: object = {};
    if (isRejected) fields = { status };

    if (appstoreScreenshotFile) {
      const containerId = initialValues?.appstoreScreenshotFile;
      const appstoreScreenshotFileUUID = await uploadFile(appstoreScreenshotFile, containerId);
      const values = { appstoreScreenshotFile: appstoreScreenshotFileUUID, appstoreDate };
      fields = { ...values, ...fields, requestSubmitted: true };
    }

    if (signedContractFile) {
      const signedContractFileUUID = await uploadFile(signedContractFile);
      const values = { signedContractFile: signedContractFileUUID, appstoreDate };
      fields = { ...values, ...fields, requestSubmitted: true };
    }

    const response = await changeApplication(app.id as string, fields);
    return response;
  };

  const { mutate: addApp, isLoading: addAppLoading } = useMutation(addApplication, {
    onSuccess: () => setSuccess(true),
    onError: () => setError('Request error. Please try again later')
  });

  const { mutate: patchReq, isLoading: patchReqLoading } = useMutation(patchSteps, {
    onSuccess: () => setSuccess(true),
    onError: () => setError('Request error. Please try again later')
  });

  const isLoading: boolean = addAppLoading || patchReqLoading;

  const formSubmit = (data: any) => {
    if (step === 1) addApp(data);
    if (step === 2 || step === 3) patchReq(data);
  };

  const getError = () => {
    if (isRejected && !error) {
      return (
        app.rejectionReason || (
          <>
            This step was rejected by administrator.
            <br />
            Please change data and send form again
          </>
        )
      );
    }
    return error;
  };

  return (
    <div className="flex flex-col max-w-2xl m-auto">
      {!success && !successScreen && (
        <div className="w-full max-w-sm mx-auto">
          <h5 className="-mx-48 text-36 text-gray-500 text-center">{title}</h5>
          {subtitle && (
            <h6 className="mt-20 -mx-48 text-14 text-gray-500 text-center">{subtitle}</h6>
          )}
          <div className="w-full mt-48">
            <Form
              onSubmit={formSubmit}
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {step === 1 && <AppDetails />}
                  {step === 2 && <AccountToStore app={app} />}
                  {step === 3 && <SignContract />}
                  {(error || isRejected) && <ErrorLabel error={getError()} />}
                  <button
                    className="w-full mt-36 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:opacity-50"
                    type="submit"
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </form>
              )}
            />
          </div>
        </div>
      )}
      {(success || successScreen) && <SuccessScreen step={step} />}
    </div>
  );
};

export default AccountForm;
