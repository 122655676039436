import React from 'react';
import AmountField from 'components/AmountField';
import { formatDate, FORMATS } from 'utils/formatters';
import ModalInnerWrapper from './components/ModalInnerWrapper';
import ModalItemList from './components/ModalItemList';

export interface IVatModalProps {
  amount: number;
  datetime: string;
  currency: string;
  type?: string;
}

export const VatModal: React.FC<IVatModalProps> = ({ amount, datetime, currency, type }) => {
  const vatFields = [
    {
      name: 'Date',
      value: formatDate(datetime, FORMATS.global)
    }
  ];

  return (
    <ModalInnerWrapper
      AmountField={<AmountField amount={amount} currencyName={currency} />}
      type={type}
    >
      {vatFields.map(({ name, value }, index) => (
        <ModalItemList key={`vat-${index}`} name={name} value={value} index={index} />
      ))}
    </ModalInnerWrapper>
  );
};
