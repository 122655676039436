import React, { useState, useMemo } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Field } from 'react-final-form';
import ErrorFieldString from 'components/ErrorFieldString';
import { useLocale } from 'hooks/useLocale';
import { formatCurrencyString } from './format';

export type CurrencyFieldProps = {
  name: string;
  label: string;
  validate?: any;
  placeholder?: string;
  initialValue?: string | number;
  prefix?: string;
};

const classes = {
  label: 'block text-12 mb-10',
  labelOk: 'text-gray-400',
  labelError: 'text-red',
  input:
    'focus:gray-500 block w-full h-48 border rounded px-20 text-gray-500 focus:outline-none disabled:bg-gray-100 disabled:text-gray-400',
  inputOk: 'border-gray-300 focus:border-green disabled:border-transparent',
  inputError: 'border-red'
};

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  name,
  label,
  initialValue = '',
  validate,
  placeholder,
  prefix,
  ...props
}) => {
  const { decimaSeparator, groupSeparator } = useLocale();

  const labelClasses = (isError: boolean) =>
    classes.label + ' ' + (isError ? classes.labelError : classes.labelOk);
  const inputClasses = (isError: boolean) =>
    classes.input + ' ' + (isError ? classes.inputError : classes.inputOk);

  const [val, setVal] = useState<string | number>(initialValue);

  const handleChange = (value?: string, onChange?: any) => {
    setVal(value as string);
    onChange(value);
  };

  const handlePaste = (event: ClipboardEvent, onChange?: any) => {
    const { clipboardData } = event;
    const pastedText = clipboardData?.getData('text');
    const newValue = formatCurrencyString(pastedText, decimaSeparator) as string;
    if (val !== newValue) handleChange(newValue, onChange);
  };

  const currencyPrefix = useMemo(() => {
    if (prefix === 'USD') return '$';
    if (prefix === 'EUR' || !prefix) return '€';
  }, [prefix]);

  return (
    <Field
      {...props}
      name={name}
      validate={validate}
      initialValue={initialValue}
      format={(value) => (!!value ? String(value).replace('.', decimaSeparator) : undefined)}
      parse={(value) => (!!value ? String(value)?.replace(decimaSeparator, '.') : '')}
    >
      {({ input, meta }) => {
        const isError = meta.error && (meta.dirty || meta.touched);
        return (
          <>
            {label && (
              <label className={labelClasses(isError)} htmlFor={name}>
                {label}
              </label>
            )}
            <CurrencyInput
              placeholder={placeholder}
              prefix={currencyPrefix}
              maxLength={11}
              decimalsLimit={2}
              decimalSeparator={decimaSeparator}
              groupSeparator={groupSeparator}
              value={input.value}
              className={inputClasses(isError)}
              onValueChange={(value) => handleChange(value, input.onChange)}
              onPaste={(e) => handlePaste(e as any, input.onChange)}
            />
            {isError && <ErrorFieldString error={meta.error} />}
          </>
        );
      }}
    </Field>
  );
};

export default CurrencyField;
