import { usePostClientSetMutation } from 'api/postClientSet';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';

function useClientSelectModal({ onClose }: { onClose: () => void }) {
  const { clients } = useAuth();
  const { mutate } = usePostClientSetMutation();
  const history = useHistory();

  const onClientChange = async (clientId: number) => {
    await mutate({ clientId });
    onClose();
    history.push('/');
  };

  return {
    clients,
    onClientChange
  };
}

export default useClientSelectModal;
