import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { isEmpty } from 'utils/checkers';
import { formatIBAN } from 'utils/formatters';
import { appStatuses } from 'const/appStatuses';
import { IApplicationDTO } from 'interfaces/application';
import AmountField from 'components/AmountField';
import CopyToClipboard from 'components/CopyToClipboard';

export const AppsListRow: React.FC<IApplicationDTO> = ({
  id,
  iban,
  balance,
  currency,
  name,
  status,
  requestSubmitted
}) => {
  const withBalance = !isEmpty(balance);
  const isApproved = status === appStatuses.approved;
  const isRejected = status === appStatuses.rejected;

  const getRoute = () => {
    if (!isApproved) return `add/${id}`;
    return `applications/${id}`;
  };

  const appStatus = useMemo(() => {
    if (isRejected) return 'Rejected';
    return requestSubmitted ? 'Pending' : 'Action needed';
  }, [isRejected, requestSubmitted]);

  return (
    <Link
      style={{
        pointerEvents: isRejected ? 'none' : undefined
      }}
      to={getRoute}
      className={cn(
        'flex justify-between items-center w-full pl-20 border-b border-gray-200 h-80',
        { relative: withBalance && isApproved, 'pr-20': !isApproved || !withBalance }
      )}
    >
      <div className="flex flex-col">
        <span className="block text-gray-500 text-18">{name}</span>
        {iban && <span className="text-14 text-gray-400 mt-8">{formatIBAN(iban)}</span>}
      </div>
      <span className="text-18 text-gray-500 font-medium">
        {isApproved && (
          <>
            {withBalance && (
              <div className="group flex items-center absolute top-50 -mt-20 -right-20">
                <AmountField amount={balance} currencyName={currency} />
                <div className="ml-20 opacity-0 group-hover:opacity-100 transition-all">
                  <CopyToClipboard value={balance} />
                </div>
              </div>
            )}
            {!withBalance && <AmountField amount={balance} currencyName={currency} />}
          </>
        )}
        {!isApproved && <>{appStatus}</>}
      </span>
    </Link>
  );
};
