import React from 'react';
import cn from 'classnames';

interface IWizardLineProps {
  active?: number;
}

const steps = [
  {
    id: 1,
    title: 'App details'
  },
  {
    id: 2,
    title: 'Add account to appstore'
  },
  {
    id: 3,
    title: 'Account activation'
  }
];

const WizardLine: React.FC<IWizardLineProps> = ({ active = 1 }) => {
  return (
    <div className="flex items-center justify-center my-56">
      {steps.map((step) => (
        <div className="flex items-center" key={`step-${step.id}`}>
          <div
            className={cn(
              'flex items-center justify-center w-28 h-28 mr-12 text-14 border-2 rounded-full',
              {
                'bg-green text-white border-green': active >= step.id,
                'bg-transparent text-gray-300 border-gray-200': active < step.id
              }
            )}
          >
            {step.id}
          </div>
          <span
            className={cn('text-14', {
              'text-gray-500': active >= step.id,
              'text-gray-300': active < step.id
            })}
          >
            {step.title}
          </span>
          {step.id !== steps.length && <div className="w-40 h-2 bg-gray-300 mx-20"></div>}
        </div>
      ))}
    </div>
  );
};

export default WizardLine;
