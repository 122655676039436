import React from 'react';
import { Form } from 'react-final-form';
import { fieldRequiredRule } from 'validation/rules';
import Input from 'components/Input';
import useProjectsForm from '../../hooks/useProjectsForm';

type IAddProjectModalProps = {
  onSuccess: () => void;
};

const AddProjectModal: React.FC<IAddProjectModalProps> = ({ onSuccess }) => {
  const { addProject, isLoading } = useProjectsForm({ onAddSuccess: onSuccess });

  return (
    <Form
      onSubmit={addProject}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Input name="name" label="" placeholder="Project name" validate={fieldRequiredRule} />
            <button
              className="w-full mt-36 py-14 text-center bg-green text-white rounded-6 focus:outline-none disabled:opacity-50"
              type="submit"
              disabled={isLoading}
            >
              Save
            </button>
          </form>
        );
      }}
    />
  );
};

export default AddProjectModal;
