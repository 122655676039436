import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getApplication } from 'api/applications';
import { appStatuses } from 'const/appStatuses';
import Container from 'components/Container';
import Header from 'components/Header';
import NavPanel from 'components/NavPanel';

import WizardLine from './components/WizardLine';
import Form from './components/AccountForm';

const AddAccount: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const {
    appSet,
    appSetRejected,
    bankDetailsSet,
    bankDetailsRejected,
    contractSet,
    contractRejected,
    approved
  } = appStatuses;

  const { isLoading, data: app } = useQuery(
    ['getAppInAccount', id],
    () => getApplication(id as string),
    {
      enabled: !!id,
      refetchOnWindowFocus: true
    }
  );

  const [step, setStep] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<object>({});

  const status = app?.status || undefined;
  const isRequestSubmitted = Boolean(app?.requestSubmitted);
  const isRejected =
    !!status &&
    (status === appSetRejected || status === bankDetailsRejected || status === contractRejected);
  const appDetailsSCreen = status === appSet || status === appSetRejected;
  const accountScreen = status === bankDetailsSet || status === bankDetailsRejected;
  const contractScreen = status === contractSet || status === contractRejected;

  const defineStepOnStatus = useCallback(() => {
    if (!id) {
      setStep(1);
      return;
    }
    if (appDetailsSCreen) setStep(1);
    if (accountScreen) setStep(2);
    if (contractScreen) setStep(3);
    if (status === approved) history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    defineStepOnStatus();
  }, [status, defineStepOnStatus]);

  useEffect(() => {
    if (app?.id) {
      const { type, ...items } = app;
      setInitialValues({
        type: String(type),
        ...items
      });
    }
  }, [app]);

  const title = useMemo(() => {
    if (step === 1) return 'Enter the app details';
    if (step === 2) return 'Add the bank account details';
    if (step === 3) return 'Sign the contract';
    return '';
  }, [step]);

  const subtitle = useMemo(() => {
    if (step === 2)
      return 'Please fill out these details in the  Bank Accounts section of Apple Developer';
    if (step === 3) return 'It`s almost done. Please, complete the last step.';
    return;
  }, [step]);

  const showForm = ((id && !isLoading) || !id) && step !== 0;

  return (
    <>
      <Header />
      <NavPanel />
      {showForm && (
        <Container>
          <WizardLine active={step} />
          <Form
            step={step}
            title={title}
            subtitle={subtitle}
            isRejected={isRejected}
            successScreen={isRequestSubmitted}
            initialValues={initialValues}
            app={app}
          />
          <div className="h-128" />
        </Container>
      )}
    </>
  );
};

export default AddAccount;
