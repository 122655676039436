import React, { useState } from 'react';
import Modal from 'components/Modal';
import CustomTitle from '../Modals/CustomTitle';
import RenameProjectModal from '../Modals/RenameProjectModal';
import DeleteProjectModal from '../Modals/DeleteProjectModal';
import { IProjectDTO } from 'interfaces/project';

interface IProjectsListRow extends IProjectDTO {
  handleChange: () => void;
}

const ProjectsListRow: React.FC<IProjectsListRow> = ({ id, name, handleChange }) => {
  const [modal, setModal] = useState({ rename: false, del: false });

  const showRenameModal = () => setModal({ rename: true, del: false });

  const showDeleteModal = () => setModal({ rename: false, del: true });

  const closeModal = () => {
    setModal({ rename: false, del: false });
    handleChange();
  };

  return (
    <>
      <div className="flex justify-between items-center w-full px-20 border-b border-gray-200 h-80 hover:bg-gray-50">
        <div className="flex flex-col">
          <span className="block text-gray-500 text-18">{name}</span>
        </div>
        <div className="flex flex-row items-center">
          <button className="text-18 mr-16 text-blue" onClick={showRenameModal}>
            Rename
          </button>
          <button className="text-18 text-red" onClick={showDeleteModal}>
            Delete
          </button>
        </div>
      </div>
      {modal.rename && (
        <Modal customTitle={<CustomTitle title="Rename project" />} onClose={closeModal}>
          <RenameProjectModal name={name} id={id} onSuccess={closeModal} />
        </Modal>
      )}
      {modal.del && (
        <Modal customTitle={<CustomTitle title="Delete project" />} onClose={closeModal}>
          <DeleteProjectModal name={name} id={id} onSuccess={closeModal} />
        </Modal>
      )}
    </>
  );
};

export default ProjectsListRow;
