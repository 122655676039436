import React, { useState } from 'react';
import cn from 'classnames';
import { tabsTypes } from './const';
import OperationsList from './OperationsList';

interface ITabs {
  id: string;
  currency: string;
  balance: number;
  refetch: Function;
}

const Tabs: React.FC<ITabs> = ({ id, currency, balance, refetch }) => {
  const [tab, setTab] = useState(tabsTypes.all);

  return (
    <>
      <div className="flex flex-nowrap justify-center mb-48">
        {Object.values(tabsTypes).map((tabName) => (
          <button
            className={cn(
              `focus:outline-none w-176 border-b-2 text-gray-500 h-48 font-medium ${tabName.toLocaleLowerCase()}`,
              {
                'border-green text-green': tabName === tab,
                'hover:border-gray-300': tabName !== tab
              }
            )}
            key={tabName}
            onClick={() => setTab(tabName)}
          >
            {tabName}
          </button>
        ))}
      </div>
      <OperationsList
        currency={currency}
        tab={tab}
        appId={id}
        balance={balance}
        refetch={refetch}
      />
    </>
  );
};

export default Tabs;
