import axios from 'utils/axios';
import { IApplicationDTO } from 'interfaces/application';

/**
 * Applications list request
 * @param  {any} params?
 */
export const getApplications = async (params?: any): Promise<IApplicationDTO[]> => {
  const { queryKey, pageParam, ...prms } = params;
  const response = await axios.get('/api/applications/', { params: prms });
  return response.data;
};

/**
 * Applications list request with pagination
 * @param  {any} params?
 */
export const getPaginatedApplications = async (params?: any): Promise<any> => {
  const response = await axios.get('/api/applications/', { params });
  return response?.data;
};

/**
 * Request single application
 * @param  {string} id
 */
export const getApplication = async (id: string): Promise<IApplicationDTO> => {
  const response = await axios.get(`/api/applications/${id}/`);
  return response.data;
};

/**
 * Create application request
 * @param  {any} params?
 */
export const createApplication = async (params?: any) =>
  await axios.post('/api/applications/', params);

/**
 * Change existed application
 * @param  {string} id
 * @param  {any} params?
 */
export const changeApplication = async (id: string, params?: any) =>
  await axios.patch(`/api/applications/${id}/`, params);
