export type HeadingProps = {
  level: 1 | 2 | 3 | 4;
  className?: string;
  color?: string;
  children: undefined | string | string[] | JSX.Element | JSX.Element[];
};

const Heading: React.FC<HeadingProps> = ({
  level,
  className = '',
  color = 'text-gray-500',
  children
}) => {
  switch (level) {
    case 1:
      return <h1 className={`text-48 font-light ${color} ${className}`}>{children}</h1>;
    case 2:
      return <h2 className={`text-36 font-light ${color} ${className}`}>{children}</h2>;
    case 3:
      return <h3 className={`text-24 font-light ${color} ${className}`}>{children}</h3>;
    case 4:
      return <h4 className={`text-16 font-light ${color} ${className}`}>{children}</h4>;
  }
};

export default Heading;
