import React from 'react';
import { useQuery } from 'react-query';
import { useFormState } from 'react-final-form';
import { fieldRequiredRule, maxLength, isUrl, composeValidators } from 'validation/rules';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Select from 'components/Select';

import { getCurrencies, getAccountOwners } from 'api/selectors';

export const Step1: React.FC = () => {
  const { data: currencies } = useQuery(['getCurrencies'], getCurrencies, {
    refetchOnWindowFocus: false
  });

  const { data: owners } = useQuery(['getOwners'], getAccountOwners, {
    refetchOnWindowFocus: false
  });

  const { values } = useFormState();

  const showComment = values?.appStoreAccountOwner !== 'similar';
  return (
    <>
      <div className="mb-28">
        <Input name="name" label="Application name" validate={fieldRequiredRule} />
      </div>
      <div className="mb-28">
        <Input
          name="website"
          label="Website"
          validateOnBlurOnly
          validate={composeValidators([fieldRequiredRule, maxLength(240), isUrl])}
        />
      </div>
      <div className="mb-28">
        <Input
          name="applicationLink"
          label="Application Link"
          validateOnBlurOnly
          validate={composeValidators([fieldRequiredRule, maxLength(240), isUrl])}
        />
      </div>
      <div className="mb-28">
        <Select
          name="preferredCurrencyId"
          label="Preferred currency"
          validate={fieldRequiredRule}
          options={currencies || []}
        />
      </div>
      <div className="mb-28">
        <Select
          name="appStoreAccountOwner"
          label="App Store Account Owner"
          validate={fieldRequiredRule}
          options={owners || []}
          initialValue="similar"
        />
      </div>
      {showComment && (
        <>
          <div className="mb-28">
            <Input
              name="appStoreAccountOwnerName"
              label="Company Name"
              validate={composeValidators([fieldRequiredRule, maxLength(240)])}
            />
          </div>
          <div className="mb-28">
            <TextArea name="comment" label="Your comment if any" validate={maxLength(140)} />
          </div>
        </>
      )}
    </>
  );
};
