import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getApplication } from 'api/applications';

function useApplication() {
  const { id } = useParams<{ id: string }>();

  const { isLoading, data: app, refetch } = useQuery(['getApp', id], () => getApplication(id));

  return {
    app,
    refetch,
    isLoading
  };
}

export default useApplication;
