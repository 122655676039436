import { isNumber } from 'utils/checkers';

export const formatCurrencyString = (
  text?: string | number,
  decimaSeparator: string = '.'
): string => {
  if (isNumber(text)) return `${text}`;

  if (!text) return '';

  if (text) {
    const length = (text as string).length;
    const separatorIndex = length > 2 ? length - 3 : undefined;
    const separator = separatorIndex ? (text as string)[separatorIndex] : undefined;
    const needFormat = separator && length && separatorIndex && !/^\d+$/.test(separator);

    let newValue: string;
    newValue = (text as string).replace(/\s/g, '');
    newValue = newValue.replace(/\D/g, '');

    if (needFormat) {
      const decimals = newValue
        .split('')
        .splice(newValue.length - 2, 2)
        .join('');

      const core = newValue
        .split('')
        .slice(0, newValue.length - 2)
        .join('');

      return `${core}${decimaSeparator}${decimals}`;
    }

    return newValue + decimaSeparator + '00';
  }

  return `${text}`;
};
