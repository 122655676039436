import React from 'react';
import useProjectsForm from '../../hooks/useProjectsForm';

type IDeleteProjectModalProps = {
  id: number;
  name: string;
  onSuccess: () => void;
};

const DeleteProjectModal: React.FC<IDeleteProjectModalProps> = ({ id, name, onSuccess }) => {
  const { deleteProjectMutation, isLoading } = useProjectsForm({ onDeleteSuccess: onSuccess });

  return (
    <div className="mt-48">
      <p className="text-18 font-light text-gray-500">
        Delete<b> {name}</b> from projects?
      </p>
      <button
        className="w-full mt-36 py-14 text-center bg-red text-white rounded-6 focus:outline-none disabled:opacity-50"
        onClick={() => deleteProjectMutation(id)}
        disabled={isLoading}
      >
        Delete
      </button>
    </div>
  );
};

export default DeleteProjectModal;
