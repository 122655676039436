import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { changeApplication } from 'api/applications';
import { getProjects } from 'api/projects';
import { IApplicationDTO } from 'interfaces/application';
import { IProjectDTO } from 'interfaces/project';
import ApplicationInfoModalView from './ApplicationInfoModalView';

interface IApplicationInfoModalProps extends IApplicationDTO {
  onChangeForm: () => void;
}

interface IApplicationUpdateForm {
  project: number | null;
  rc: string | null;
}

const ApplicationInfoModal: React.FC<IApplicationInfoModalProps> = ({ ...props }) => {
  const { id, onChangeForm } = props;

  const { data: projects } = useQuery(['getProjects'], getProjects);

  const submitForm = (data: IApplicationUpdateForm) => changeApplicationCallback(data);

  const { mutate: changeApplicationCallback, isLoading } = useMutation(
    (fields: IApplicationUpdateForm) => changeApplication(id, fields),
    {
      onSuccess: () => onChangeForm()
    }
  );

  return (
    <ApplicationInfoModalView
      {...props}
      submitForm={submitForm}
      projects={projects as IProjectDTO[]}
      isLoading={isLoading}
    />
  );
};

export default ApplicationInfoModal;
