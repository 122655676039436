import { useState, useMemo } from 'react';
import dayjs from 'dayjs';

function useOperationListRow({ ...props }) {
  const {
    outgoingType,
    type,
    date,
    datetime,
    paymentMethodName,
    status,
    confirmDatetime,
    revenueSourceName,
    isCorrective
  } = props;

  const [modal, showModal] = useState(false);
  const [esrModal, showESRModal] = useState(false);

  const isStoreRevenue = type && type === 'incoming' && !isCorrective;
  const isServiceFee = outgoingType && outgoingType === 'fee';
  const isVAT = outgoingType && outgoingType === 'vat';
  const isLicenseFee = outgoingType && outgoingType === 'license_fee';
  const isMonthly = outgoingType && (outgoingType === 'monthly' || outgoingType === 'fixed');
  const isESR =
    !isServiceFee && !isVAT && !isMonthly && !isStoreRevenue && !paymentMethodName && !isCorrective;
  const isWithdrawal =
    !isStoreRevenue &&
    !isServiceFee &&
    !isVAT &&
    !isLicenseFee &&
    !isMonthly &&
    !!paymentMethodName &&
    !isCorrective;

  const SRName = revenueSourceName ? `${revenueSourceName} Net Revenue` : 'Store Net Revenue';

  const ESRArchived = isESR && status === 'archive';
  const ESRRequest = isESR && status === 'approved';
  const ESRAdd = isESR && status === 'pending';
  const ESRUpdate = isESR && status === 'rejected';
  const ESRMonth = dayjs(date).subtract(1, 'month').format('MMMM');

  const openModal = () => showModal(true);
  const closeModal = () => showModal(false);
  const openESRModal = () => showESRModal(true);
  const closeESRModal = () => showESRModal(false);

  const operationName = useMemo(() => {
    if (isCorrective) return 'Correction';
    if (isStoreRevenue) return SRName;
    if (isServiceFee) return 'Service Fee';
    if (isVAT) return 'VAT';
    if (isLicenseFee) return 'License fee';
    if (isMonthly) return 'Monthly Fee';
    if (ESRRequest || ESRArchived) return `Expected store revenue for ${ESRMonth}`;
    if (ESRAdd) return `Add expected store revenue for ${ESRMonth}`;
    if (ESRUpdate) return `Update expected store revenue for ${ESRMonth}`;
    if (isWithdrawal) return `Withdrawal (${paymentMethodName})`;
  }, [
    ESRAdd,
    ESRArchived,
    ESRMonth,
    ESRRequest,
    ESRUpdate,
    SRName,
    isCorrective,
    isMonthly,
    isLicenseFee,
    isStoreRevenue,
    isVAT,
    isServiceFee,
    isWithdrawal,
    paymentMethodName
  ]);

  const operationDate = useMemo(() => {
    if (isWithdrawal && status === 'completed') return confirmDatetime;
    if (datetime) return datetime;
    if (date) return date;
    return null;
  }, [confirmDatetime, date, datetime, isWithdrawal, status]);

  const operationModalTitle = useMemo(() => {
    if (isCorrective) return 'Correction';
    if (isStoreRevenue) return SRName;
    if (isServiceFee) return 'Service fee';
    if (isVAT) return 'VAT';
    if (isLicenseFee) return 'License fee';
    if (isMonthly) return 'Monthly Fee';
    if (isESR) return 'Expected store revenue';
    return '';
  }, [SRName, isCorrective, isESR, isMonthly, isServiceFee, isStoreRevenue, isVAT, isLicenseFee]);

  return {
    modal,
    esrModal,
    isStoreRevenue,
    isServiceFee,
    isVAT,
    isLicenseFee,
    isMonthly,
    isESR,
    isWithdrawal,
    SRName,
    ESRArchived,
    ESRRequest,
    ESRAdd,
    ESRUpdate,
    ESRMonth,
    openModal,
    closeModal,
    openESRModal,
    closeESRModal,
    operationName,
    operationDate,
    operationModalTitle
  };
}
export default useOperationListRow;
