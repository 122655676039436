import React from 'react';
import cn from 'classnames';
import { formatDate, FORMATS, capitalize, fromSnakeCase } from 'utils/formatters';
import AmountField from 'components/AmountField';
import Modal from 'components/Modal';

import { ReactComponent as ServiceFee } from 'assets/images/service_fee.svg';
import { ReactComponent as Incoming } from 'assets/images/incoming.svg';
import { ReactComponent as Withdrawal } from 'assets/images/withdrawal.svg';
import { ReactComponent as ESR } from 'assets/images/esr.svg';
import { ReactComponent as ESRRed } from 'assets/images/esr-red.svg';

import { ReactComponent as Pending } from 'pages/Application/images/pending.svg';
import { ReactComponent as Declined } from 'pages/Application/images/declined.svg';
import { ReactComponent as Completed } from 'pages/Application/images/completed.svg';

import { IOperationDTO } from 'interfaces/operation';

// import WithdrawalModalSwitcher from '../Modals/Withdrawal/WithdrawalModalSwitcher';

import WithdrawalEdit from '../Modals/Withdrawal/WithdrawalEdit';
import { CorrectiveModal } from '../Modals/CorrectiveModal';
import { StoreNetRevenueModal } from '../Modals/StoreNetRevenueModal';
import { ServiceFeeModal } from '../Modals/ServiceFeeModal';
import { VatModal } from '../Modals/VatModal';
import { FixedModal } from '../Modals/FixedModal';
import { ExpectedStoreRevenueModal } from '../Modals/ExpectedStoreRevenue';
import { RequestWithdrawal } from '../Modals/RequestWithdrawal';
import { AddESR } from '../Modals/AddESR';

import useOperationsListRow from './hooks/useOperationsListRow';

export interface IOperationsListRow extends IOperationDTO {
  refetchList: Function;
  refetch: Function;
}

export const OperationsListRow: React.FC<IOperationsListRow> = ({
  refetchList,
  refetch,
  ...props
}) => {
  const {
    modal,
    esrModal,
    isStoreRevenue,
    isServiceFee,
    isVAT,
    isLicenseFee,
    isMonthly,
    isESR,
    isWithdrawal,
    ESRArchived,
    ESRRequest,
    ESRAdd,
    ESRUpdate,
    openModal,
    closeModal,
    openESRModal,
    closeESRModal,
    operationName,
    operationDate,
    operationModalTitle
  } = useOperationsListRow({ ...props });

  const {
    id,
    type,
    amount,
    fee,
    availableProc,
    status,
    isRequested,
    applicationId,
    currency,
    isCorrective,
    withdrawal
  } = props;

  const getOperationIcon = () => {
    if (isStoreRevenue) return <Incoming />;
    if (isLicenseFee || isServiceFee || isVAT || isMonthly) return <ServiceFee />;
    if (ESRRequest || ESRArchived) return <ESR />;
    if (ESRAdd || ESRUpdate) return <ESRRed />;

    return <Withdrawal />;
  };

  const getWithdarwalStatusIcon = () => {
    if (status === 'completed') return <Completed />;
    if (status === 'pending') return <Pending />;
    if (status === 'rejected') return <Declined />;
  };

  const OperationModal = () => {
    return (
      <>
        {isWithdrawal && (
          <WithdrawalEdit
            withdrawalId={withdrawal as string}
            onClose={() => {
              closeModal();
              refetch();
            }}
          />
        )}
        {/* {isWithdrawal && <WithdrawalModalSwitcher operation={{ ...props }} onClose={closeModal} />} */}
        {!isWithdrawal && (
          <Modal title={operationModalTitle} onClose={closeModal} detailView>
            {isCorrective && <CorrectiveModal {...props} />}
            {isStoreRevenue && <StoreNetRevenueModal {...props} />}
            {(isLicenseFee || isServiceFee) && <ServiceFeeModal {...props} />}
            {isVAT && <VatModal {...props} />}
            {isMonthly && <FixedModal {...props} />}
            {isESR && <ExpectedStoreRevenueModal {...props} />}
          </Modal>
        )}
      </>
    );
  };

  const ESRModal: React.FC = () => {
    const Subtitle = () => (
      <>
        {ESRRequest && (
          <p className="mb-36 text-center text-16 leading-24 text-gray-500">
            Balance:
            <span className="text-green">
              {' '}
              <AmountField amount={availableProc} currencyName={currency} />
            </span>
          </p>
        )}
      </>
    );

    return (
      <Modal title="Please enter expected amount" subtitle={<Subtitle />} onClose={closeESRModal}>
        <>
          {ESRRequest && (
            <RequestWithdrawal
              esr
              maxAmount={availableProc}
              onClose={closeESRModal}
              esrId={id}
              id={applicationId}
              onSubmit={refetchList}
            />
          )}
          {(ESRAdd || ESRUpdate) && (
            <AddESR onClose={closeESRModal} id={applicationId} esrId={id} onSubmit={refetchList} />
          )}
        </>
      </Modal>
    );
  };

  const AmountBlock: React.FC = () => {
    const isCompletedWithdrawal = isWithdrawal && status === 'completed';
    const isRejectedByBank = isWithdrawal && status === 'rejected_by_bank';
    const isRejected = isWithdrawal && status === 'rejected';

    const showSimpleBlock = !isStoreRevenue && !isESR && !isWithdrawal && !isCorrective;
    const getAmountMinusFee = () => amount - fee;

    return (
      <>
        {isCorrective && (
          <div className="flex flex-col w-208">
            <div
              className={cn('flex items-center justify-end text-16', {
                'text-green': type === 'incoming'
              })}
            >
              <span className="block mr-4">{type === 'incoming' ? '+' : '-'}</span>
              <AmountField amount={amount} currencyName={currency} />
            </div>
          </div>
        )}
        {isStoreRevenue && (
          <div className="flex flex-col w-208">
            <div className="flex items-center justify-end text-16 text-green">
              <span className="block mr-4">+</span>
              <AmountField amount={amount} currencyName={currency} />
            </div>
            <div className="flex items-center justify-end flex-nowrap mt-6 text-14 text-gray-400 font-normal">
              amount after fee: +
              <AmountField amount={getAmountMinusFee()} currencyName={currency} />
            </div>
          </div>
        )}
        {isWithdrawal && <div className={cn("flex flex-col w-208 text-gray-400", {
          "text-green": isCompletedWithdrawal,
          "text-red-dark": isRejectedByBank || isRejected
        })}>
            <div className="flex items-center justify-end text-16">
              <span className="block mr-4">-</span>
              <AmountField amount={amount} currencyName={currency} />
            </div>
            <div className="flex items-center justify-end flex-nowrap mt-6 text-14 font-normal">
              {capitalize(fromSnakeCase(status))}
            </div>
          </div>}
        {showSimpleBlock && (
          <div className="flex items-center justify-end text-16">
            <span className="block mr-4">-</span>
            <AmountField amount={amount} currencyName={currency} />
          </div>
        )}
        {!isStoreRevenue && isESR && (
          <>
            {!ESRAdd && !ESRUpdate && <AmountField amount={amount} currencyName={currency} />}
            {ESRRequest && !isRequested && (
              <span className="ml-16 text-green text-16 cursor-pointer" onClick={openESRModal}>
                Request
              </span>
            )}
            {(ESRAdd || ESRUpdate) && (
              <button
                className="py-10 px-28 text-center text-16 text-white bg-red rounded-2 focus:outline-none"
                onClick={openESRModal}
              >
                {ESRAdd ? 'Add' : 'Update'}
              </button>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      {modal && <OperationModal />}
      {esrModal && <ESRModal />}
      <div
        className={cn(
          'flex flex-nowrap justify-between w-full p-16 border-b border-gray-200 cursor-pointer',
          {
            'bg-green-light': ESRRequest || ESRArchived,
            'bg-red-light': ESRAdd || ESRUpdate,
            'hover:bg-gray-50': !isESR
          }
        )}
        onClick={openModal}
      >
        <div className="w-full flex flex-nowrap items-center">
          {getOperationIcon()}
          <div className="flex flex-col ml-16">
            <div
              className={cn('flex items-center text-gray-700 text-16', {
                'font-bold': isStoreRevenue
              })}
            >
              {operationName}
              {isWithdrawal && <span className="ml-10">{getWithdarwalStatusIcon()}</span>}
            </div>
            <div className={cn('text-gray-400 text-12 mt-6', { 'font-medium': isStoreRevenue })}>
              {formatDate(operationDate, FORMATS.global)}
            </div>
          </div>
        </div>
        <div className="text-gray-700 text-16 font-medium">
          <AmountBlock />
        </div>
      </div>
    </>
  );
};
