const primaryColor = '#F7F7F8';

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    boxShadow: 'unset'
  }),
  control: (provided: any, { isFocused }: any) => ({
    ...provided,
    height: '100%',
    outline: 'none',
    background: primaryColor,
    borderRadius: '8px',
    borderColor: 'transparent',
    boxShadow: 'unset',

    '&:hover': {
      borderColor: 'transparent'
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'unset'
  }),
  option: (provided: any, { isSelected }: any) => ({
    ...provided,
    color: 'unset',
    textAlign: 'left',
    padding: '18px 10px',
    background: `${isSelected ? primaryColor : 'transparent'}`,
    '&:hover': {
      background: primaryColor
    }
  })
};
