import axios from 'utils/axios';

export interface IPostLoginRequest {
  email: string;
  password: string;
}

export interface IPostLoginResponse {
  token: string;
}

export const postLogin = async (credentials: IPostLoginRequest) => {
  return (await axios.post<IPostLoginResponse>('/api/login/', credentials)).data;
};
