import React from 'react';
import MultiFileInput from 'components/redesign/MultiFileInput/MultiFileInput';

import useValidateFileInput from '../../../hooks/useValidateFileInput';

const Download = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.0001 14C8.3001 14 8.5001 13.9 8.7001 13.7L13.4001 9L12.0001 7.6L9.0001 10.6V2H7.0001V10.6L4.0001 7.6L2.6001 9L7.3001 13.7C7.5001 13.9 7.7001 14 8.0001 14Z"
      fill="#4C4C4C"
    />
  </svg>
);

const DocsRows = ({ docs }: { docs: { name: string }[] }) => (
  <div className="flex flex-col w-full">
    {docs.map((file: File | any, i: number) => (
      <div
        key={'download-files-' + i}
        className="flex items-center justify-between py-10 px-16 mb-16 rounded-8 bg-white"
      >
        <div className="w-11/12 flex items-center pr-10 flex-shrink-0">
          <span className="ml-10 text-16 font-normal truncate border-gray-400 border-b border-dotted">
            {file?.name || file?.filename}
          </span>
        </div>
        <span className="remove cursor-pointer w-16 h-16" onClick={() => console.log('Download')}>
          <Download />
        </span>
      </div>
    ))}
  </div>
);

export const Step2: React.FC = () => {
  const { validateMultiFileInputByCount } = useValidateFileInput();
  return (
    <>
      <div className="mb-28 -mx-32 px-32 py-20 rounded-8 bg-gray-100">
        <p className="text-16 leading-24 text-black font-medium mt-0 mb-16">
          1. Please download documents provided and sign them
        </p>
        <DocsRows docs={[{ name: 'Contract' }, { name: 'Letter' }]} />
      </div>
      <div className="mb-28 -mx-32 px-32 py-20 rounded-8 bg-gray-100">
        <MultiFileInput
          name="signedDocuments"
          label="2. Please submit all signed documents as we have requested"
          validate={validateMultiFileInputByCount}
        />
      </div>
    </>
  );
};
