import React from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowLeft } from 'assets/images/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/images/arrow_right.svg';
import { ReactComponent as Info } from 'assets/images/info.svg';
import Modal from 'components/Modal';
import Heading from 'components/Heading';
import ApplicationInfoModal from '../Modals/ApplicationInfoModal';
import { IApplicationDTO } from 'interfaces/application';
import useAppSwitcher from './hooks/useAppSwitcher';

interface IAppSwitcher extends IApplicationDTO {
  refetch: () => void;
}

const AppSwitcher: React.FC<IAppSwitcher> = ({ refetch, ...app }) => {
  const { id, name } = app;

  const {
    manyApps,
    prevApp,
    nextApp,
    showDetailsModal,
    popup,
    closeDetailsModal
  } = useAppSwitcher({ refetch, id });

  return (
    <>
      <div
        className={cn('flex items-center mt-64 mb-24', {
          'justify-center': !manyApps,
          'justify-between': manyApps
        })}
      >
        {manyApps && (
          <div
            className="prevApp cursor-pointer py-10 px-20 -ml-20 text-gray-400 hover:text-gray-500"
            onClick={prevApp}
          >
            <ArrowLeft />
          </div>
        )}
        <Heading level={1}>{name}</Heading>
        {manyApps && (
          <div
            className="nextApp cursor-pointer py-10 px-20 -mr-20 text-gray-400 hover:text-gray-500"
            onClick={nextApp}
          >
            <ArrowRight />
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <button
          className="flex justify-center items-center h-36 bg-gray-100 hover:bg-gray-200 text-16 px-16 text-gray-500 rounded focus:outline-none"
          onClick={showDetailsModal}
        >
          <Info />
          <span className="pl-10">Account details</span>
        </button>
      </div>
      {popup && (
        <Modal onClose={closeDetailsModal} detailView>
          <ApplicationInfoModal {...app} onChangeForm={closeDetailsModal} />
        </Modal>
      )}
    </>
  );
};

export default AppSwitcher;
