import axios from 'axios';
import { useEffect, useRef } from 'react';
import applyCaseMiddleware from 'axios-case-converter';
import { useKeycloak } from '@react-keycloak/web';
import type { AxiosInstance } from 'axios';
const client = applyCaseMiddleware(axios.create());

let interceptorId: number | undefined;

export const useAxios = (baseURL: string) => {
  const axiosInstance = useRef<AxiosInstance>();
  const { keycloak } = useKeycloak();
  const kcToken = keycloak?.token ?? '';

  useEffect(() => {
    axiosInstance.current = applyCaseMiddleware(
      axios.create({
        baseURL,
        headers: {
          Authorization: kcToken ? `Bearer ${kcToken}` : undefined
        }
      })
    );

    return () => {
      axiosInstance.current = undefined;
    };
  }, [baseURL, kcToken]);

  return axiosInstance;
};

/**
 * setAxiosToken
 * @param  {string|undefined} token
 */
export function setAxiosToken(token: string | undefined) {
  if (token) {
    // console.log(decodeJWT(token))
    interceptorId = client.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: 'Bearer ' + token
      }
    }));
  } else {
    if (interceptorId) {
      client.interceptors.request.eject(interceptorId);
      interceptorId = undefined;
    }
  }
}

export default client;
