import axios from 'utils/axios';

export const getPaymentMethods = async () => {
  const response = await axios.get('/api/payment_methods/');
  return response.data;
};

export const getPublishers = async () => {
  const response = await axios.get('/api/publishers/');
  return response.data;
};

export const getCurrencies = async () => {
  const response = await axios.get('/api/currencies/');
  return response.data?.filter(({ code }: { code: string }) => code === 'EUR' || code === 'USD');
};

export const getAccountOwners = async () => {
  const response = await axios.get('/api/application-requests/account-owner-choices');
  return response.data;
};
