import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IApplicationDTO } from 'interfaces/application';
import NoResults from 'components/NoResults';
import Container from 'components/Container';
import Header from 'components/Header';
import LoadMore from 'components/LoadMore';
import Search from './components/Search';
import AppsList from './components/AppsList';
import HomeHeading from './components/HomeHeading';
import useHome from './hooks/useHome';

const Home: React.FC = () => {
  const {
    setSearch,
    showInfiniteAppList,
    infiniteData,
    showMoreButton,
    disableLoadMore,
    isLoading,
    fetchNextPage
  } = useHome();

  return (
    <>
      <Header />
      <Container>
        <HomeHeading />
        <Search onChange={setSearch} />
        {isLoading && <Skeleton count={5} />}
        {!isLoading && (
          <>
            {showInfiniteAppList && <AppsList apps={infiniteData as IApplicationDTO[]} />}
            {showMoreButton && (
              <LoadMore blueTone onClick={fetchNextPage} disabled={disableLoadMore} />
            )}
            {!showInfiniteAppList && <NoResults message="No apps" />}
          </>
        )}
        <div className="h-128" />
      </Container>
    </>
  );
};

export default Home;
