import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import Modal from 'components/Modal';
import { downloadBlobFile } from 'utils/api';
import { ReactComponent as Expand } from 'assets/redesign/expand.svg';
import { ReactComponent as Download } from 'assets/redesign/download.svg';
import { ReactComponent as Attach } from 'assets/redesign/attach.svg';
import { ReactComponent as AttachDisabled } from 'assets/redesign/attach_disabled.svg';

type ImageComponentProps = {
  modal?: boolean;
};

type PreviewWithControlsProps = {
  file: any;
  clearFileField: () => void;
  disabled?: boolean;
  backendFile?: boolean;
};

const PreviewWithControls = React.forwardRef(
  ({ file, clearFileField, disabled, backendFile }: PreviewWithControlsProps, ref: any) => {
    const MODAL = document.querySelector('#modal') as Element;
    const [showDetail, setShowDetail] = useState<boolean>(false);

    const { fileURL, fileName, isPDF } = file;

    const download = () => downloadBlobFile(fileURL, fileName);

    const handleAttach = () => {
      if (disabled) return;
      clearFileField();

      // To top stack, waiting for ref changed
      setTimeout(() => {
        if (!!ref) ref.querySelector('input').click();
      }, 600);
    };

    const ImageComponent = useCallback(
      ({ modal }: ImageComponentProps) => (
        <img
          src={fileURL}
          alt={fileName}
          className={cn('mx-auto', {
            'h-full': !modal,
            'w-full': modal
          })}
        />
      ),
      [fileName, fileURL]
    );

    useEffect(() => {
      if (!MODAL) return;
      if (showDetail) {
        MODAL.classList.remove('d-none');
        ReactDOM.render(
          <Modal onClose={() => setShowDetail(false)} title="" detailView>
            <ImageComponent modal />
          </Modal>,
          MODAL
        );
      } else {
        MODAL.classList.add('d-none');
        ReactDOM.unmountComponentAtNode(MODAL);
      }
    }, [ImageComponent, MODAL, showDetail]);

    return (
      <div className="w-full h-full flex items-center justify-between">
        <div className="text-black text-16">Invoice {fileName}</div>
        <div className="text-gray-300 flex items-center">
          {!isPDF && (
            <span
              className="cursor-pointer hover:opacity-50 mx-4"
              onClick={() => setShowDetail(true)}
            >
              <Expand style={{ borderRadius: '50px' }} />
            </span>
          )}
          <span
            className={cn('px-16 py-16', { 'cursor-pointer': !disabled })}
            onClick={handleAttach}
          >
            {disabled ? <AttachDisabled /> : <Attach />}
          </span>
          {backendFile && fileURL && (
            <span className="cursor-pointer" onClick={download}>
              <Download />
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default PreviewWithControls;
