import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import DatePicker from 'components/DatePicker';
import YearSelector from 'components/DatePicker/components/YearSelector';
import MonthSwitcher from 'components/DatePicker/components/MonthSwitcher';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { IQueryParams } from 'interfaces/logs';
import useHistoryLogSearch from '../../hooks/useHistoryLogSearch';
import { createLabelTitle } from '../../utils/format';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as RemoveIcon } from '../../assets/remove.svg';

interface ISearch {
  onChange: (query: IQueryParams) => void;
}

const Search: React.FC<ISearch> = ({ onChange }) => {
  const {
    selectedType,
    setType,
    picker,
    showPicker,
    startDate,
    endDate,
    onChangePicker,
    periodOptions,
    filterTypes,
    clearDateFilter,
    handlePeriodFilter,
    handlePickerDoneButton,
    setSearch,
    filterQuery,
    showLabels,
    showPickerLabels
  } = useHistoryLogSearch();

  useEffect(() => {
    onChange(filterQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery.model, filterQuery.search, filterQuery.start, filterQuery.end]);

  const ref = useRef() as any;
  useOnClickOutside(ref, () => showPicker(false));

  const LabelItem = ({
    title,
    callback,
    className = ''
  }: {
    title: string;
    callback: () => void;
    className?: string;
  }) => (
    <div
      className={`flex items-center justify-between h-36 py-6 px-14 rounded-24 text-16 bg-gray-dark text-white ${className}`}
    >
      {title}
      <span className="ml-8 cursor-pointer" onClick={callback}>
        <CloseIcon />
      </span>
    </div>
  );

  return (
    <div className="flex flex-col w-full mb-64">
      <div className="flex items-center w-full h-64 mb-20 px-20 pt-16 pb-12 text-24 rounded-12 bg-gray-100">
        <div className="flex items-center w-full">
          <input
            className="w-full ml-20 bg-transparent focus:outline-none"
            type="text"
            placeholder="Search"
            value={filterQuery?.search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {filterQuery?.search && (
            <span className="cursor-pointer" onClick={() => setSearch('')}>
              <RemoveIcon />
            </span>
          )}
        </div>

        {showLabels && (
          <div className="flex flex-shrink-0 items-center justify-end ml-20">
            {selectedType && <LabelItem title={selectedType} callback={() => setType(null)} />}
            {filterQuery?.period && showPickerLabels && (
              <LabelItem
                title={filterQuery.period as string}
                callback={clearDateFilter}
                className="ml-8"
              />
            )}
            {!filterQuery?.period && showPickerLabels && (
              <LabelItem
                title={createLabelTitle(startDate, endDate) as string}
                callback={clearDateFilter}
                className="ml-8"
              />
            )}
          </div>
        )}

        <div className="ml-20 relative">
          <span className="cursor-pointer" onClick={() => showPicker(true)}>
            <CalendarIcon />
          </span>
          {picker && (
            <DatePicker
              wrapperClassname="absolute top-56 -right-20"
              headerChild={
                <div className="flex items-center justify-around text-12">
                  {periodOptions.map((period: string, i: number) => (
                    <div
                      key={`periods-list-${i}`}
                      className="cursor-pointer text-gray-500"
                      onClick={() => handlePeriodFilter(period)}
                    >
                      {period}
                    </div>
                  ))}
                </div>
              }
              renderCustomHeader={({
                changeYear,
                date,
                decreaseMonth,
                increaseMonth
              }: {
                changeYear: (year: number) => void;
                date: Date;
                decreaseMonth: () => void;
                increaseMonth: () => void;
              }) => (
                <>
                  <YearSelector changeYear={changeYear} date={date} />
                  <MonthSwitcher
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    date={date}
                  />
                </>
              )}
              onDoneClick={handlePickerDoneButton}
              ref={ref}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={onChangePicker}
              selectsRange
              maxDate={dayjs().endOf('month').toDate()}
              inline
            />
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        {filterTypes.map((type: string, i: number) => (
          <div
            key={`filter-type-${i}`}
            className="py-6 px-14 mx-4 rounded-24 bg-gray-100 text-gray-700 cursor-pointer"
            onClick={() => setType(type)}
          >
            {type}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Search;
