import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import MultiFileInput from 'components/redesign/MultiFileInput/MultiFileInput';
import { ReactComponent as Copy } from 'assets/images/copy.svg';
import { ReactComponent as Approved } from 'assets/images/approved.svg';

import useValidateFileInput from '../../../hooks/useValidateFileInput';

type InfoRowProps = {
  label: string;
  value: string;
  canCopy?: boolean;
};

const InfoRow = ({ label, value, canCopy = false }: InfoRowProps) => {
  const [clicked, setClicked] = useState(false);

  const handleCopyClick = () => {
    setClicked(true);
    copy(value);
  };

  useEffect(() => {
    let timerId: any;
    if (clicked) {
      timerId = setTimeout(() => setClicked(false), 3000);
    }
    return () => clearTimeout(timerId);
  }, [clicked]);

  return (
    <div className="flex flex-col mb-36">
      <label className="text-14 text-gray-400 mb-12">{label}</label>
      <div className="flex items-center">
        <span className="text-18 text-gray-500">{value}</span>
        {canCopy && (
          <span className="ml-10 cursor-pointer" onClick={handleCopyClick}>
            {clicked ? <Approved height={20} /> : <Copy height={20} />}
          </span>
        )}
      </div>
    </div>
  );
};

const getAccountNumber = (iban?: string) => {
  if (!iban) return;
  if (iban.length > 16) return iban?.substr(iban.length - 16);
  return iban;
};

export const Step3: React.FC = () => {
  const { validateMultiFileInput } = useValidateFileInput();
  return (
    <>
      <div className="flex flex-row justify-between py-56 px-56 mb-40 -mx-256 rounded-12 bg-gray-100">
        <div className="flex flex-col w-1/2">
          <InfoRow label="Name" value="UNLIMINT EU LTD" canCopy />
          <InfoRow label="Bank Country or Region" value="Cyprus" canCopy />
          <InfoRow label="Bank Account Currency" value="EUR - Euro" canCopy />
          <InfoRow
            label="Bank Address"
            value="Irodi Attikou, 8A Lakatamia, 2332, Nicosia, Cyprus"
            canCopy
          />
          <InfoRow label="Cyprus Bank Identification Code" value="90200001" canCopy />
        </div>
        <div className="w-44"></div>
        <div className="flex flex-col w-1/2">
          <InfoRow label="IBAN" value="1234567890987654321" canCopy />
          <InfoRow
            label="Account Number"
            value={getAccountNumber('1234567890987654321') || '-'}
            canCopy
          />
          <InfoRow label="Account Holder Name" value={'Publisher'} canCopy />
          <InfoRow label="Account Holder Type" value="Corporation" />
        </div>
      </div>
      <div className="mb-28 -mx-32 px-32 py-20 rounded-8 bg-gray-100">
        <MultiFileInput
          name="screenshots"
          label="As soon as it’s done, please attach the full page screenshot as a proof here:"
          validate={validateMultiFileInput}
        />
      </div>
    </>
  );
};
