import React from 'react';
import { IApplicationDTO } from 'interfaces/application';
import { AppsListRow } from './AppsListRow';

interface IAppsList {
  apps?: IApplicationDTO[];
}

const AppsList: React.FC<IAppsList> = ({ apps }) => (
  <>
    {apps?.map((app) => (
      <AppsListRow key={app.id} {...app} />
    ))}
  </>
);

export default AppsList;
