import Modal from 'components/Modal';
import { IGetUserClientsResponse } from 'interfaces/user';

export type ClientSelectModalViewProps = {
  clients?: IGetUserClientsResponse[];
  onClose: () => void;
  onClientChange: (id: number) => void;
};

const ClientSelectModalView: React.FC<ClientSelectModalViewProps> = ({
  clients,
  onClientChange,
  onClose
}) => {
  if (!clients) {
    onClose();
    return null;
  }

  return (
    <Modal title="Choose organization" onClose={onClose}>
      {clients?.map((client) => (
        <div
          key={client.id}
          className="client-row text-18 px-20 -mx-20 py-24 text-gray-500 hover:bg-gray-100 hover:cursor-pointer border-b border-gray-300"
          onClick={() => onClientChange(client.id)}
        >
          {client.name}
        </div>
      ))}
    </Modal>
  );
};

export default ClientSelectModalView;
