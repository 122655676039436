import React from 'react';
import cn from 'classnames';
import { useFileUpload } from 'hooks/useFileUpload';
import ModalRedesign from 'components/Modal/ModalRedesign';
import AmountField from 'components/AmountField';
import { isValidArray } from 'utils/checkers';
import { formatDate, FORMATS } from 'utils/formatters';
import { ReactComponent as Download } from 'assets/images/download_grey.svg';
import { ReactComponent as Completed } from './assets/completed.svg';
import { IWithdrawalDTO, IWithdrawalChildDTO } from 'interfaces/withdrawal';

interface IWithdrawalParentModal {
  onClose: () => void;
  withdrawal: IWithdrawalDTO;
  onChildClick: (child: IWithdrawalChildDTO) => void;
}

const WithdrawalPropertyRow = ({
  name,
  value,
  index
}: {
  name: string;
  value: JSX.Element | number | string | null;
  index: number;
}) => (
  <div
    className={cn('flex items-center justify-between w-full py-16 border-b border-gray-200', {
      'border-t': index === 0
    })}
  >
    <div className="text-16 text-gray-400">{name}</div>
    <div className="tracking-wide text-16 text-right text-gray-700">{value}</div>
  </div>
);

const WithdrawalParentModal: React.FC<IWithdrawalParentModal> = ({
  withdrawal,
  onClose,
  onChildClick
}) => {
  const { downloadFile } = useFileUpload();

  const fileName = !!withdrawal.invoiceNumber ? `#${withdrawal.invoiceNumber}` : 'Invoice';
  const isCompleted = withdrawal.status === 'completed';
  const showInvoice = !!withdrawal.file && isCompleted;

  const fields = [
    {
      name: 'Request date',
      value: formatDate(withdrawal.datetime, FORMATS.detail, { toCyprus: true })
    },
    {
      name: 'Fulfilment date',
      value: formatDate(withdrawal.confirmDatetime, FORMATS.global, { toCyprus: true }),
      show: !!withdrawal.confirmDatetime
    },
    {
      name: 'Payment method',
      value: withdrawal.paymentMethodName,
      show: !!withdrawal.paymentMethodName
    },
    {
      name: 'Withdrawal fee',
      value: <AmountField amount={withdrawal?.withdrawalFee} />,
      show: (!!withdrawal?.withdrawalFee || withdrawal?.withdrawalFee === 0) && isCompleted
    }
  ];

  const WithdrawalChildrenRow = ({ children }: { children: IWithdrawalChildDTO[] }) => (
    <div className="flex flex-col w-full">
      <div className="py-16 text-16 text-gray-400">Child Requests</div>
      <div className="flex flex-col w-full">
        {children.map((child: IWithdrawalChildDTO, i: number) => (
          <div
            className="w-full flex items-center justify-between pl-16 py-8"
            key={`withdrawal-child-${i}`}
          >
            <div
              className="text-16 underline text-purple cursor-pointer"
              onClick={() => onChildClick(child as IWithdrawalChildDTO)}
            >
              {child.name}
            </div>
            <div className="tracking-wide text-16 text-right text-gray-700">
              <AmountField amount={child.amount as number} currencyName={child.currencyName} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <ModalRedesign onClose={onClose}>
      <div className="flex flex-col mt-20">
        <div className="flex items-center justify-center">
          <span
            className={cn('tracking-widest uppercase text-14 text-gray-700', {
              'mr-10': isCompleted
            })}
          >
            Withdrawal
          </span>
          {isCompleted && <Completed />}
        </div>
        <div className="mt-36 text-center text-48 text-gray-700 font-bold">
          <AmountField amount={parseFloat(`-${withdrawal.amount}`)} />
        </div>
        {showInvoice && (
          <button
            onClick={() => downloadFile(withdrawal.file as string)}
            className="flex items-center justify-center min-w-208 mx-auto mt-40 px-20 py-10 text-center text-gray-700 rounded-8 bg-purple-extralight focus:outline-none"
          >
            <Download />
            <span className="ml-10">{fileName}</span>
          </button>
        )}
        <div className="flex flex-col w-full mt-40">
          <>
            {fields.map(({ show = true, ...items }, i: number) => (
              <React.Fragment key={i}>
                {show && <WithdrawalPropertyRow {...items} index={i} />}
              </React.Fragment>
            ))}
            {isValidArray(withdrawal.children) && (
              <WithdrawalChildrenRow children={withdrawal.children as IWithdrawalChildDTO[]} />
            )}
          </>
        </div>
      </div>
    </ModalRedesign>
  );
};

export default WithdrawalParentModal;
