import React, { useState } from 'react';
import Modal from 'components/Modal';
import Heading from 'components/Heading';
import { ReactComponent as Plus } from 'assets/images/plus.svg';
import AddProjectModal from '../Modals/AddProjectModal';
import CustomTitle from '../Modals/CustomTitle';

type IProjectsHeadingProps = {
  refetch: () => void;
};

const ProjectsHeading: React.FC<IProjectsHeadingProps> = ({ refetch }) => {
  const [modal, setModal] = useState<boolean>(false);

  const showAddProjectModal = () => setModal(true);
  const closeAddProjectModal = () => {
    setModal(false);
    refetch();
  };

  return (
    <>
      <Heading level={1} className="my-64">
        <div className="flex items-center justify-between">
          <span>Projects</span>
          <button
            className="flex items-center px-14 py-14 border border-gray-200 rounded-6 focus:outline-none hover:bg-gray-100 text-gray-400"
            onClick={showAddProjectModal}
          >
            <Plus />
            <span className="ml-16 text-18">Add project</span>
          </button>
        </div>
      </Heading>
      {modal && (
        <Modal customTitle={<CustomTitle title="Add project" />} onClose={closeAddProjectModal}>
          <AddProjectModal onSuccess={closeAddProjectModal} />
        </Modal>
      )}
    </>
  );
};

export default ProjectsHeading;
