import React from 'react';

const ModalInnerWithdrawal: React.FC = ({ children }) => {
  return (
    <div className="my-16 px-32">
      <div className="flex flex-col w-full">{children}</div>
    </div>
  );
};

export default ModalInnerWithdrawal;
